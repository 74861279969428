define('eventHelper',
    [],function () {
        'use strict';

        var eventHelper = {

            _initialized: false,

            _init: function () {
                if (!this._initialized) {

                    /*Adapted from https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
                     * This is necessary to add compatibility with IE9 and higher.
                     */
                    if (typeof (window.CustomEvent) !== 'function') {

                        var CustomEvent = function (event, params) {
                            var eventParams = params || { bubbles: false, cancelable: false, detail: undefined };
                            var evt = document.createEvent('CustomEvent');
                            evt.initCustomEvent(event, eventParams.bubbles, eventParams.cancelable, eventParams.detail);
                            return evt;
                        };

                        CustomEvent.prototype = window.Event.prototype;

                        window.CustomEvent = CustomEvent;
                    }
                    this._initialized = true;
                }
            },

            trigger: function (eventName, eventData) {
                try {
                    var event = new CustomEvent(eventName, { detail: eventData });
                    document.dispatchEvent(event);

                } catch (ex) {
                    var evt = document.createEvent('CustomEvent');
                    evt.initCustomEvent(eventName, false, false, eventData);
                    document.dispatchEvent(evt);
                }
            },

            bind: function (eventName, eventHandler) {
                document.addEventListener(eventName, eventHandler, false);
            },

            unbind: function (eventName, eventHandler) {
                document.removeEventListener(eventName, eventHandler, false);
            }

        };

        return eventHelper;
    }
);
