define('modularisBase/debug',
   ['jquery', 'kendo', 'clientCache', 'configLoader', 'logger'],
   function ($, kendo, clientCache, configLoader, logger) {
       'use strict';
       var eventHandlers = {};

       var displayObject = function (object) {

           for (var property in object) {
               if (object.hasOwnProperty(property)) {
                   if (object === eventHandlers) {
                       logger.info('Name:', property + '. Count:', object[property]);
                   } else {
                       logger.info(property + ':', object[property]);
                   }
               }
           }

       };

       var debug = {

           isEnabled: function () {
               return configLoader.appConfig && configLoader.appConfig.debug.enabled;
           },

           registerEventHandler: function (eventName) {

               if (!(eventName in eventHandlers)) {
                   eventHandlers[eventName] = 1;
               } else {
                   eventHandlers[eventName] += 1;
               }
           },

           unregisterEventHandler: function (eventName) {
               if (!(eventName in eventHandlers)) {
                   eventHandlers[eventName] = 0;
               } else {
                   if (eventHandlers[eventName] > 0) {
                       eventHandlers[eventName] -= 1;
                   } else {
                       eventHandlers[eventName] = 0;
                   }
               }
           },

           //send: function () {

           //   var that = this;
           //   var message = '<html><body>';

           //   message += '<section><h1>Browser information</h1>' + formatObject(that.getBrowserData()) + '</section>';
           //   message += '<section><h1>WebStack information</h1>' + formatObject(that.getWebStackData()) + '</section>';
           //   message += '<section><h1>Events handlers information</h1>' + formatObject(that.getEventsData()) + '</section>';

           //   message += '</body></html>';

           //   $.ajax({
           //      type: 'POST',
           //      url: 'https://mandrillapp.com/api/1.0/messages/send.json',
           //      data: {
           //         'key': 'og_aTl32BKFLoRZ66TnNEw',
           //         'message': {
           //            'from_email': 'webstack@modularis.com',
           //            'to': [
           //               {
           //                  'email': 'zapatam@modularis.com',
           //                  'type': 'to'
           //               }
           //            ],
           //            'autotext': 'true',
           //            'subject': 'Debug information from the awesome Web Stack',
           //            'html': message
           //         }
           //      }

           //   });
           //},

           displayAllData: function () {

               var that = this;

               logger.info(new Date());
               logger.info('Browser information.');
               logger.info('--------------------------------------');
               var browserData = that.getBrowserData();
               displayObject(browserData);

               logger.info('WebStack information.');
               logger.info('--------------------------------------');
               var webStackData = that.getWebStackData();
               displayObject(webStackData);

               logger.info('Events handlers information.');
               logger.info('--------------------------------------');
               var eventsData = that.getEventsData();
               displayObject(eventsData);

           },

           getEventsData: function () {

               return eventHandlers;
           },

           getBrowserData: function () {

               var name = null;
               var browser = kendo.support.browser;
               if (browser.msie) {
                   name = 'Internet Explorer';
               } else if (browser.mozilla) {
                   name = 'Mozilla Firefox';
               } else if (browser.opera) {
                   name = 'Opera';
               } else if (browser.webkit && browser.chrome) {
                   name = 'Google Chrome';
               } else if (browser.webkit && browser.safari) {
                   name = 'Safari';
               }

               var browserData = {
                   BrowserName: name,
                   CookiesEnabled: window.navigator.cookieEnabled,
                   URL: window.location.href,
                   UserAgent: window.navigator.userAgent,
                   Version: browser.version
               };

               if (kendo.support.mobileOS) {

                   browserData.MobileDevice = kendo.support.mobileOS.device;
                   browserData.IsTablet = kendo.support.mobileOS.tablet;
                   browserData.BrowserName = kendo.support.mobileOS.browser;
                   browserData.MajorBrowserVersion = kendo.support.mobileOS.majorVersion;
                   browserData.MinorBrowserVersion = kendo.support.mobileOS.minorVersion;
               }
               return browserData;
           },

           getWebStackData: function () {

               var activeServer = clientCache.getActiveServer();

               var stackData = {
                   jQueryVersion: $.fn.jquery,
                   KendoVersion: kendo.version,
                   //ModularisVersion: modularisApp.version,
                   SystemName: configLoader.appConfig.systemName,
                   ActiveServerName: activeServer.name,
                   ActiveServerPrimaryURI: activeServer.primaryURI,
                   UIDefID: clientCache.getUIDefID(),
                   DeviceName: clientCache.getDeviceName()
               };

               var sessionID = clientCache.getSessionID();
               if (sessionID) {
                   stackData.SessionID = sessionID;
               }

               if (clientCache.getSession() && clientCache.getSession().User) {
                   stackData.Username = clientCache.getSession().User.Username;
               }

               return stackData;

           }

       };

       return debug;

   }
);
