define('modularis/web/widgets/binders/buttonBinders',
   ['jquery', 'kendo'],
   function ($, kendo) {
      'use strict';

      var buttonBinderContainer = {
         
         init: function () {
             /*eslint no-param-reassign: 0*/
            //#region Binder for buttonVisible property (required for buttons that are localized)
            kendo.data.binders.widget.buttonVisible = kendo.data.Binder.extend({

               init: function (widget, bindings, options) {
                  var that = this;
                  kendo.data.Binder.fn.init.call(that, widget.element[0], bindings, options);
               },

               refresh: function () {
                  var that = this;
                  var value = that.bindings.buttonVisible.get();
                  if (value) {
                     $(that.element).css('display', '');
                  } else {
                     $(that.element).css('display', 'none');
                  }
               }

            });

            //#endregion

         }

      };

      return buttonBinderContainer;

   }
);
