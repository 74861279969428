define('modularis/web/widgets/binders/dateBinder',
   ['jquery', 'kendo'],
   function ($, kendo) {
      'use strict';

      var dateBinderContainer = {

         init: function () {
             /*eslint no-param-reassign: 0*/
            kendo.data.binders.date = kendo.data.Binder.extend({
               init: function (element, bindings, options) {
                  kendo.data.Binder.fn.init.call(this, element, bindings, options);

                  this.dateFormat = $(element).data('date-format');
               },
               refresh: function () {
                  var data = this.bindings.date.get();
                  var dateObj = data;
                  if (!(data instanceof Date)) {
                     dateObj = new Date(data);
                  }
                  $(this.element).text(kendo.toString(dateObj, this.dateFormat));
               }
            });

         }

      };

      return dateBinderContainer;

   }
);
