define('clientStorage',
    ['util'],
    function (util) {
        'use strict';

        var windowData = [];

        //#region Storage available

        var isStorageAvailable = function (storageObject) {
            var result = false;

            if (typeof (storageObject) !== 'undefined') {
                //Check that sessionStorage is available and can be used.
                try {
                    var uid = (new Date()).toString();
                    storageObject.setItem(uid, uid);
                    result = (storageObject.getItem(uid) === uid);
                    storageObject.removeItem(uid);
                } catch (exception) {
                    result = false;
                }
            }

            return result;
        };

        var isSessionStorageAvailable = function () {
            return isStorageAvailable(window.sessionStorage);
        };

        var isLocalStorageAvailable = function () {
            return isStorageAvailable(window.localStorage);
        };

        //#endregion

        //#region Main functions

        var getKeys = function () {

            var keys = [];
            var index, length;

            if (isSessionStorageAvailable()) {

                for (index = 0, length = window.sessionStorage.length; index < length; index++) {
                    keys.push(window.sessionStorage.key(index));
                }
            } else {

                var allWindowData = getWindowData();
                for (index = 0; index < allWindowData.length; index++) {
                    keys.push(allWindowData[index].key);
                }

            }

            return keys;
        };

        var getValue = function (keyName) {

            var value;
            if (!isSessionStorageAvailable()) {
                value = getWindowDataValue(keyName);
            } else {
                value = sessionStorage[keyName];
            }

            if (!value) {
                //Search on the local storage
                value = localStorage[keyName];
            }

            //Check if returned value is an object.
            var result = null;
            if (value != null) {
                try {
                    result = JSON.parse(value);
                } catch (ex) {
                    //It's not a JSON string.
                    result = value;
                }
            }

            return result;
        };

        var setValue = function (keyName, value, scope) {

            if (!scope) { scope = 'default'; }

            var convertedValue = value;

            if (value && util.isFunction(value.toPlainObject)) {
                convertedValue = value.toPlainObject();
            } else if (value && util.isFunction(value.toPlainCollection)) {
                convertedValue = value.toPlainCollection();
            }

            if (convertedValue && util.isObject(convertedValue)) {
                //Convert object to string. Available storage methods don't support objects.
                convertedValue = JSON.stringify(convertedValue);
            }

            switch (scope) {
                case 'default':
                    if (!isSessionStorageAvailable()) {
                        setWindowDataValue(keyName, convertedValue);
                    } else {
                        sessionStorage[keyName] = convertedValue;
                    }
                    break;
                case 'session':
                    if (!isSessionStorageAvailable()) { throw new Error('Session storage not available'); }
                    sessionStorage[keyName] = convertedValue;
                    break;
                case 'local':
                    if (!isLocalStorageAvailable()) { throw new Error('Local storage not available'); }
                    localStorage[keyName] = convertedValue;
                    break;
                default:
                    break;
            }
        };

        var removeValue = function (keyName) {

            var value = getValue(keyName),
               removed = false;
            if (!isSessionStorageAvailable()) {
                removed = removeWindowDataValue(keyName);
            } else {
                sessionStorage.removeItem(keyName);
                removed = Boolean(sessionStorage.getItem(keyName));
            }

            if (!removed) {
                //Maybe, the item was stored on the localStorage object
                value = localStorage.getItem(keyName);
                if (value) {
                    localStorage.removeItem(keyName);
                }
            }

            return value;

        };

        //#endregion

        //#region Window data 

        var getWindowDataValue = function (keyName) {
            var allData = getWindowData();
            for (var index = 0; index < allData.length; index++) {
                if (allData[index].key === keyName) {
                    return allData[index].value;
                }
            }
            return null;
        };

        var setWindowDataValue = function (keyName, value) {
            //1. Update array
            var existingKey = false;
            var allData = getWindowData();
            for (var index = 0; index < allData.length; index++) {
                if (allData[index].key === keyName) {
                    allData[index].value = value;
                    existingKey = true;
                }
            }

            if (!existingKey) {
                var pairObject = {};
                pairObject.key = keyName;
                pairObject.value = value;
                allData.push(pairObject);
            }

            //2. Update window.name
            updateWindowData(allData);
        };

        var removeWindowDataValue = function (keyName) {

            //1. Find item
            var keyIndex = -1;
            var allData = getWindowData();
            var value = null;
            for (var index in allData) {
                if (allData[index].key === keyName) {
                    keyIndex = index;
                    value = allData[index].value;
                }
            }

            //2. Delete item from array
            if (keyIndex !== -1) {
                allData = allData.splice(keyIndex, 1);
            }

            //3. Update window.name
            updateWindowData(allData);
            return Boolean(value);
        };

        var updateWindowData = function (allData) {
            var stringData = '';
            for (var index = 0; index < allData.length; index++) {
                stringData += allData[index].key + ':' + allData[index].value + '|';
            }
            stringData = stringData.substring(0, stringData.length - 1);
            window.name = stringData;
        };

        var getWindowData = function () {
            if (window.name && windowData.length === 0) {
                var pairs = window.name.split('|');
                for (var index = 0; index < pairs.length; index++) {
                    var keyValue = pairs[index].split(':');
                    var pairObject = {};
                    pairObject.key = keyValue[0];
                    if (keyValue[1] === 'null') {
                        pairObject.value = null;
                    } else {
                        pairObject.value = keyValue[1];
                    }
                    windowData.push(pairObject);
                }
            }
            return windowData;
        };

        //#endregion

        var clientStorage = {
            getKeys: getKeys,
            getValue: getValue,
            isLocalStorageAvailable: isLocalStorageAvailable,
            isSessionStorageAvailable: isSessionStorageAvailable,
            removeValue: removeValue,
            setValue: setValue
        };

        return clientStorage;

    }
);
