define('modularis/web/widgets/locale',
   ['jquery', 'kendo', 'localization', 'modularisBase/debug', 'modularis/i18n/localizationHelper'],
   function ($, kendo, localization, debug, localizationHelper) {
       'use strict';
       var ui = kendo.ui, Widget = ui.Widget;

       var click = 'click', suffix = '.kendoLocale';

       //#region Widget definition

       var Locale = Widget.extend({

           init: function (element, options) {
               var that = this;
               Widget.fn.init.call(that, element, options);

               localizationHelper._localize(that.element, that.options);

               //Bind click event
               that.element.on(click + suffix, $.proxy(that._click, that));

               if (debug.isEnabled()) {
                   debug.registerEventHandler(click + suffix);
               }
           },

           destroy: function () {
               var that = this;
               //detach event handler
               that.element.off(click + suffix);
               Widget.fn.destroy.call(that);
               that.element = null;

               if (debug.isEnabled()) {
                   debug.unregisterEventHandler(click + suffix);
               }
           },

           options: {
               //Name of the plugin
               name: 'Locale',
               //Key that must used to find a translation for the element. (optional)
               key: null,
               //Where the translation must be shown.
               target: 'text',
               //Name of the property that is going to be translated (optional)
               propertyName: null,
               //Allows the localization of multiple attributes at the same time (e.g. text and title)
               config: null
           },

           events: [click],

           _click: function (evt) {
               this.trigger(click, { event: evt });
           }
       });

       //#endregion

       return {
           widgetClass: Locale
       };

   }
);
