define('modularisLayoutManager',
    ['jquery', 'kendo', 'modularisTemplateLoader', 'configLoader', 'webUtil', 'util', 'enums', 'logger'],
    function ($, kendo, templateLoader, configLoader, webUtil, modularisUtil, enums, logger) {
        'use strict';
        var layoutWrapper = (function () {

            var layout = null,
                currentLayoutPath = null,
                viewMapping = [];

            // desktop, tablet or phone?
            var getDeviceType = function () {
                var result = enums.deviceTypes.desktop;
                if (kendo.support.mobileOS) {
                    result = kendo.support.mobileOS.tablet ? enums.deviceTypes.tablet : enums.deviceTypes.phone;
                }
                return result;
            };

            var displayLayout = function (layoutPath, layoutName, callback) {

                var layoutOptions = webUtil.addViewSettings(layoutPath, layoutName);
                layoutOptions.show = function () {
                    modularisUtil.notify(callback);
                };

                var kendoLayout = new kendo.Layout(layoutName, layoutOptions);

                //var html = kendoLayout.render();
                $('body').empty();
                kendoLayout.render($('body'));
                //$('body').html(html);

                layout = kendoLayout;
            };

            var loadAndDisplayLayout = function (layoutViewName, callback) {

                templateLoader.loadTemplate(layoutViewName, function (data, error) {
                    var responseObject = null, responseError = error;
                    if (modularisUtil.isSuccessfulResponse(data, error)) {

                        var notifyLayoutLoaded = function () {
                            responseObject = layout;
                            currentLayoutPath = layoutViewName;
                            modularisUtil.notify(callback, responseObject, responseError);
                        };

                        //destroy currently displayed views before the layout is changed.
                        destroyAllViews();
                        //destroy current layout before the new one is displayed.
                        destroyCurrentLayout();

                        modularisUtil.clearArray(viewMapping);

                        displayLayout(layoutViewName, data.templateId, function () {

                            //Try to execute additional code related to the layout using the object 
                            //layoutHelper, that could part of the app folder
                            var requireFunction = modularisUtil.getRequireJSFunction();
                            requireFunction(['app/viewModels/shared/layoutHelper'],
                               function (layoutHelper) {
                                   try {
                                       layoutHelper.afterLayoutIsDisplayed(layoutViewName, notifyLayoutLoaded);
                                   } catch (ex) {
                                       logger.error(ex);
                                   }
                               },
                               notifyLayoutLoaded
                            );

                        });


                    } else {
                        modularisUtil.notify(callback, responseObject, responseError);
                    }
                });

            };

            var destroyCurrentLayout = function () {
                if (layout) {
                    layout.destroy();
                    layout.element = null;
                }
            };

            var shouldUseTransition = function () {
                var result = false;
                if (configLoader.appConfig.layout && configLoader.appConfig.layout.viewTransitions) {
                    var transitionsConfig = configLoader.appConfig.layout.viewTransitions;
                    var currentDeviceType = getDeviceType();
                    if (transitionsConfig.devices === 'all' || (transitionsConfig.devices instanceof Array && transitionsConfig.devices.indexOf(currentDeviceType) >= 0)) {
                        result = true;
                    }
                }
                return result;
            };

            //#region Functions to handle existing views

            var setCurrentView = function (containerSelector, newView) {
                var foundViewMapping = null;

                //Avoids a possible memory leak.
                newView.element.prevObject = null;

                for (var index = 0; (index < viewMapping.length) && (!foundViewMapping); index++) {
                    var currentViewMapping = viewMapping[index];
                    if (currentViewMapping.selector === containerSelector) {
                        currentViewMapping.previousView = currentViewMapping.currentView;
                        currentViewMapping.currentView = newView;
                        foundViewMapping = currentViewMapping;
                    }
                }

                if (!foundViewMapping) {
                    viewMapping.push({
                        selector: containerSelector,
                        currentView: newView,
                        previousView: null
                    });
                }

            };

            var destroyAllViews = function () {
                for (var index = 0; index < viewMapping.length; index++) {
                    destroyView(viewMapping.currentView);
                    destroyView(viewMapping.previousView);
                }
            };

            var destroyView = function (view) {

                if (view) {

                    if (viewMapping) {
                        for (var index = 0; index < viewMapping.length; index++) {
                            if (viewMapping[index].currentView === view) {
                                viewMapping[index].currentView = null;
                            }
                            if (viewMapping[index].previousView === view) {
                                viewMapping[index].previousView = null;
                            }
                        }
                    }

                    view.destroy();
                    view.element = null;

                    webUtil.destroyViewModel(view.model);
                    view.model = null;

                }

            };

            //#endregion

            return {

                _handleViewHiding: function (event) {
                    destroyView(event.sender);
                },

                initializeLayout: function (layoutViewName, callback) {
                    loadAndDisplayLayout(layoutViewName, callback);
                },

                getLayout: function (callback) {

                    if (layout !== null) {
                        modularisUtil.notify(callback, layout, null);
                        return;
                    }
                    loadAndDisplayLayout(configLoader.appConfig.layout.defaultPublicLayoutPath, callback);
                },

                showInLayout: function (view, customTransitionClass, container, callback) {

                    var containerSelector = (container == null) ? configLoader.appConfig.defaultTemplateContainerSelector : container;
                    this.getLayout(function (loadedLayout, error) {
                        var responseError = error;
                        if (modularisUtil.success(loadedLayout, error)) {

                            if (!shouldUseTransition()) {
                                loadedLayout.showIn(containerSelector, view);
                            } else {
                                var transitionClass = configLoader.appConfig.layout.viewTransitions.transitionClass;
                                if (!transitionClass) { transitionClass = 'swap'; }

                                if (customTransitionClass) {
                                    loadedLayout.showIn(containerSelector, view, customTransitionClass);
                                } else {
                                    loadedLayout.showIn(containerSelector, view, transitionClass);
                                }
                            }
                            setCurrentView(containerSelector, view);
                        }
                        modularisUtil.notify(callback, null, responseError);
                    });
                },

                isLayoutDisplayed: function (layoutPath) {
                    return layoutPath === currentLayoutPath;
                }

            };
        })();

        return layoutWrapper;

    }
);
