define('entityCollectionBase',
    ['jquery', 'objectBuilder', 'configLoader', 'localization', 'util', 'modularisBase/entityMetadata'],
    function ($, objectBuilder, configLoader, localization, util, entityMetadataFactory) {
        'use strict';

        var updateSerializationMetadata = function (copyFrom, currentSerializationMetadata) {

            var newSerializationMetadata = currentSerializationMetadata;
            if (copyFrom != null && util.isDefined(copyFrom._EntityCollection)) {
                newSerializationMetadata = util.copy(copyFrom._EntityCollection);
            }

            return newSerializationMetadata;
        };

        var getEntityKey = function (entity) {

            if (!entity) { return null; }

            var key = null;
            if (util.isFunction(entity.getKey)) {
                entity.rebuildKey();
                key = entity.getKey();
            } else if (entity._Entity && entity._Entity.Key) {
                key = entity._Entity.Key;
            }
            return key;
        };

        /**
         * @classdesc Base class for all the generated entity collections.
         * @constructs modularis.EntityCollectionBase
         * @param {string} assemblyName - Assembly to which the entity collection belongs.
         * @param {string} entityCollectionName - Entity collection name.
         * @param {string} entityTypeName - Full name of the singleton entity associated to the collection.
         */
        var EntityCollectionBase = function (assemblyName, entityCollectionName, entityTypeName) {
            var that = this;

            that.Items = [];
            that._keys = [];
            that._entityTypeName = entityTypeName;

            //Initialize serialization metadata
            that._serializationMetadata = {
                AssemblyName: null,
                Culture: null,
                CollectionName: null,
                Type: 'EntityCollection'
            };

            that._serializationMetadata.AssemblyName = assemblyName;
            that._serializationMetadata.CollectionName = entityCollectionName;
            that._collectionMetadata = null;

            /**
             * If true, the collection will check for duplicate keys and will thrown an error if a entity with a duplicated key is added.
             * 
             * @type {boolean}
             * @default true
             * @name modularis.EntityCollectionBase#checkDuplicatedKeys
             */
            that.checkDuplicatedKeys = true;
            if (configLoader.appConfig && configLoader.appConfig.entity) {
                that.checkDuplicatedKeys = configLoader.appConfig.entity.checkCollectionDuplicatedKeys;
            }
        };

        EntityCollectionBase.prototype = {

            /**
             * Adds an entity to the collection.
             * @param {modularis.EntityBase} entityObject - Entity to be added.
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            add: function (entityObject) {
                var that = this;
                var newObjectKey = getEntityKey(entityObject);
                if (that.checkDuplicatedKeys && newObjectKey && that.containsKey(newObjectKey)) {
                    throw new Error('Unique key ' + newObjectKey + ' is already in use');
                }
                that.Items.push(entityObject);
                if (newObjectKey) {
                    this._keys.push(newObjectKey);
                }
            },

            addAll: function (entityArray) {
                for (var index = 0; index < entityArray.length; index++) {
                    this.add(entityArray[index]);
                }
            },

            /**
             * Removes all the entities from the collection.
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            clear: function () {
                var that = this;
                util.clearArray(that.Items);
                util.clearArray(that._keys);
            },

            containsEntity: function (entityObject) {
                var result = false;
                if (entityObject && util.isFunction(entityObject.getKey)) {
                    result = this.containsKey(entityObject.getKey());
                }
                return result;
            },

            /**
             * Returns true if the collection contains an entity with the given key.
             * @param {string} key - Key to check.
             * @returns {boolean} 
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            containsKey: function (key) {
                var that = this;
                return (that._keys.indexOf(key) >= 0);
            },

            copyFrom: function (sourceCollection, callback) {
                var that = this;

                //Update metadata
                that._serializationMetadata = updateSerializationMetadata(sourceCollection, that._serializationMetadata);

                var createEntityPromises = [];

                var sourceItems = sourceCollection.Items;
                if ((!sourceItems) && (sourceCollection instanceof Array)) {
                    sourceItems = sourceCollection;
                }

                for (var index = 0; index < sourceItems.length; index++) {
                    var collectionItem = sourceItems[index];
                    var promise = objectBuilder.createEntityObject({
                        entityTypeName: that._entityTypeName,
                        copyFrom: collectionItem
                    });
                    createEntityPromises.push(promise);
                }

                $.when.apply($, createEntityPromises)
                    .done(function () {

                        for (var index = 0; index < arguments.length; index++) {
                            that.add(arguments[index]);
                        }

                        util.notify(callback, that, null);
                    })
                    .fail(function () {
                        util.notify(callback, null, 'failed');
                    });
            },

            get: function (index) {
                return this.Items[index];
            },

            /**
             * Returns the entity identified with the given key.
             * @param {string} key - Entity key.
             * @returns {modularis.EntityBase} 
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            getByKey: function (key) {
                var item = null,
                    index = this._keys.indexOf(key);
                if (index >= 0) {
                    item = this.Items[index];
                }
                return item;
            },

            /**
             * Removes the entity at the given index.
             * @param {number} index - Index.
             *
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            remove: function (index) {
                var that = this;
                if (index >= this.Items.length) {
                    throw new Error('Index out of bounds');
                }
                that._keys.splice(index, 1);
                that.Items.splice(index, 1);
            },

            /**
             * Removes the entity identified with the given key.
             * @param {string} key - Entity key.
             *
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            removeByKey: function (key) {
                var that = this;
                if (that.containsKey(key)) {
                    var index = that._keys.indexOf(key);
                    that._keys.splice(index, 1);
                    that.Items.splice(index, 1);
                }
            },

            length: function () {
                return this.Items.length;
            },

            getInstanceWithSerializationMetadata: function () {
                var that = this;
                var collectionWithMetadata = { _EntityCollection: that._serializationMetadata, Items: [] };

                //Assign Culture
                var culture = localization.getCurrentCulture();
                collectionWithMetadata._EntityCollection.Culture = culture;

                for (var index = 0; index < that.Items.length; index++) {
                    var item = that.Items[index];
                    if (util.isDefined(item.getInstanceWithSerializationMetadata)) {
                        item = item.getInstanceWithSerializationMetadata();
                    }
                    collectionWithMetadata.Items.push(item);
                }

                return collectionWithMetadata;
            },

            /**
             * Returns an object that provides functions to access entity metadata.
             * @instance
             * @memberOf modularis.EntityCollectionBase
             * @returns {modularis.EntityCollectionMetadata} 
             */
            getCollectionMetadata: function () {
                var that = this;

                var entityCollectionMetadata = new entityMetadataFactory.EntityCollectionMetadata(that);
                return entityCollectionMetadata;

            },

            rebuildKeys: function () {
                var that = this;
                util.clearArray(that._keys);
                for (var index = 0; index < that.Items.length; index++) {
                    that._keys.push(getEntityKey(that.Items[index]));
                }
            }

        };

        EntityCollectionBase.prototype.toPlainCollection = EntityCollectionBase.prototype.getInstanceWithSerializationMetadata;

        return {
            EntityCollectionBase: EntityCollectionBase
        };
    }
);
