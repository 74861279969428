define('mediator/mainMediator',
    ['util', 'mediator/mediatorBase'],
    function (util, MediatorBase) {
        'use strict';

        /**
        * @classdesc Default mediator class that handles the communication between colleague objects.
        * 
        * @constructs modularis.web.mediator.MainMediator
        * 
        * @augments modularis.web.mediator.MediatorBase
        */
        var MainMediator = function () {

            var that = this;
            MediatorBase.call(that);

        };

        MainMediator.prototype = new MediatorBase();


        // #region Singleton

        var instance = null;

        var singleton = {

            /**
             * Gets the singleton instance of the mainMediator.
             * @instance
             * @memberOf modularis.web.mediator.MainMediator
             * @returns {modularis.web.mediator.MainMediator}
             */
            getInstance: function () {
                if (!instance) {
                    instance = new MainMediator();
                }
                return instance;
            }
        };

        return singleton;

        // #endregion

    }
);
