define('modularis/web/widgets/binders/activeEntityBinder',
   ['jquery', 'kendo', 'webUtil'],
   function ($, kendo, webUtil) {
       'use strict';

       var activeEntityBinderContainer = {

           init: function () {
               /*eslint no-param-reassign: 0*/

               kendo.data.binders.widget.activeEntity = kendo.data.Binder.extend({

                   init: function (widget, bindings, options) {
                       var that = this;
                       kendo.data.Binder.fn.init.call(that, widget.element[0], bindings, options);
                   },

                   refresh: function () {
                       var that = this,
                            value = that.bindings.activeEntity.get();

                       if (webUtil.isArray(value)) { value = value[0]; }

                       var widget = kendo.widgetInstance($(that.element));
                       if (typeof (widget.setActiveEntity) === 'function') {
                           widget.setActiveEntity(value);
                       }
                   }

               });

           }

       };

       return activeEntityBinderContainer;

   }
);
