define('entityBase',
    ['jquery', 'objectBuilder', 'entityCollectionBase', 'localization', 'enums', 'util', 'modularisBase/entityMetadata'],
    function ($, objectBuilder, entityCollectionBase, localization, enums, util, entityMetadataFactory) {
        /*eslint new-cap: 0*/
        'use strict';

        var types = enums.propertyTypes;

        var updateSerializationMetadata = function (copyFrom, currentSerializationMetadata) {

            var newSerializationMetadata = currentSerializationMetadata;
            if (copyFrom != null && util.isDefined(copyFrom._Entity)) {
                newSerializationMetadata = util.copy(copyFrom._Entity);
            }

            return newSerializationMetadata;
        };

        var createEntity = function (propertyDef, copyFrom) {
            var deferred = $.Deferred();

            var createEntityCallback = function (entityInstance, error) {
                if (util.success(entityInstance, error)) {
                    deferred.resolve(propertyDef, entityInstance);
                } else {
                    deferred.reject(propertyDef, error);
                }
            };

            var promise = deferred.promise();

            objectBuilder.createEntityObject({
                entityTypeName: propertyDef.entityTypeName,
                copyFrom: copyFrom,
                assignIdentifier: false,
                callback: createEntityCallback
            });

            return promise;
        };

        var createEntityCollection = function (propertyDef, copyFrom, assemblyName) {
            var deferred = $.Deferred();

            var createCollectionCallback = function (entityCollection, error) {
                if (util.success(entityCollection, error)) {
                    deferred.resolve(propertyDef, entityCollection);
                } else {
                    deferred.reject(propertyDef, error);
                }
            };

            var promise = deferred.promise();

            objectBuilder.createEntityCollection({
                assemblyName: assemblyName,
                entityTypeName: propertyDef.entityTypeName,
                entityCollectionName: propertyDef.entityCollectionName,
                copyFrom: copyFrom,
                callback: createCollectionCallback
            });

            return promise;
        };

        var copyPropertyValues = function (sourceEntity, targetEntity, propertyDefs, callback) {

            if (sourceEntity != null && propertyDefs != null) {

                //Collection of functions that must be invoked in order to create child entities.
                var createChildEntityFunctions = [];
                for (var index = 0; index < propertyDefs.length; index++) {
                    var currentPropertyName = propertyDefs[index].name;
                    var currentPropertyType = propertyDefs[index].type;

                    //Property defined in current instance and source entity
                    if (util.isDefined(targetEntity._propertyValues[currentPropertyName]) &&
                            (util.isDefined(sourceEntity[currentPropertyName]) /*|| util.isDefined(sourceEntity._propertyValues[currentPropertyName])*/)) {

                        var promise = null;
                        var propertyOriginalValue = sourceEntity[currentPropertyName];

                        var propertyValue = null;
                        if (propertyOriginalValue != null) {
                            switch (currentPropertyType) {
                                case enums.propertyTypes.typeEntity:
                                    promise = createEntity(propertyDefs[index], propertyOriginalValue);
                                    break;
                                case enums.propertyTypes.typeEntityCollection:
                                    promise = createEntityCollection(propertyDefs[index], propertyOriginalValue, targetEntity._serializationMetadata.AssemblyName);
                                    break;
                                default:
                                    propertyValue = propertyOriginalValue;
                            }
                        }

                        if (promise != null) {
                            createChildEntityFunctions.push(promise);
                        } else {
                            targetEntity._setPropertyValue(currentPropertyName, propertyValue, false);
                        }
                    }
                }

                /* There are some other properties (like DisplayValues -DV-) that are not defined in the propertyDefs, 
                 * but were somehow added to the sourceEntity. Those values will copied to the targetEntity without any transformation. */
                for (var customProperty in sourceEntity) {
                    if (sourceEntity.hasOwnProperty(customProperty) && !(customProperty in targetEntity)) {
                        /*eslint-disable no-param-reassign*/
                        targetEntity[customProperty] = util.copy(sourceEntity[customProperty]);
                        /*eslint-enable no-param-reassign*/
                    }
                }

                if (createChildEntityFunctions.length > 0) {
                    $.when.apply($, createChildEntityFunctions)
                        .done(function () {

                            //Each position of the arguments object contains the response data sent by each promise.
                            if (arguments.length > 0) {
                                var index;
                                if (util.isArray(arguments[0])) {
                                    //In some cases, each position of the argumens object is an array with two objects.
                                    for (index = 0; index < arguments.length; index++) {
                                        targetEntity._setPropertyValue(arguments[index][0].name, arguments[index][1], false);
                                    }
                                } else {
                                    //In other cases, each position of the arguments object contains a single entity instead of an array
                                    var nextIndex = 2;
                                    for (index = 0; index < arguments.length; index += nextIndex) {
                                        targetEntity._setPropertyValue(arguments[index].name, arguments[index + 1], false, false);
                                    }
                                }
                            }

                            util.notify(callback, targetEntity, null);
                        })
                        .fail(function () {
                            util.notify(callback, null, 'failed');
                        });
                } else {
                    util.notify(callback, targetEntity, null);
                }

            }
        };

        /**
         * @classdesc Base class for all the generated entities.
         * @constructs modularis.EntityBase
         * @param {Object} initialEntityState - Object with the default state for the entity.
         * @param {boolean} initialEntityState.AllowNulls - Allows null values to be returned for value types. Currently not supported in the web platform.
         * @param {string} initialEntityState.AssemblyName - Assembly to which the entity belongs.
         * @param {Array} initialEntityState.Attributes - Array of entity attributes.
         * @param {string} initialEntityState.Culture - Culture code.
         * @param {boolean} initialEntityState.Dirty - Dirty flag.
         * @param {modularis.enums.editModes} initialEntityState.EditMode - Initial edit mode.
         * @param {Array} initialEntityState.Errors - Array of entity errors.
         * @param {string} initialEntityState.Name - Entity name.
         * @param {string} initialEntityState.Type - Entity type. 
         * @param {boolean} initialEntityState.Valid - Valid flag.
         * @param {Object[]} entityProperties - Array with entity properties.
         * @param {string} entityProperties[].name - Property name.
         * @param {modularis.enums.propertyTypes} entityProperties[].type - Property type.
         * @param {string} keyStructure - The key structure of the entity.
         */
        var EntityBase = function (initialEntityState, entityProperties, keyStructure) {
            //EntityBase constructor
            var that = this;

            //Properties
            that._propertyValues = {};
            that._entityMetadata = null;
            that._propertyDefs = entityProperties;
            that._keyStructure = keyStructure;
            that._serializationMetadata = util.copy(initialEntityState);
            that._key = null;
        };

        //Methods available for all entities.
        EntityBase.prototype = {

            copyFrom: function (sourceEntity, callback) {
                var that = this;
                that._serializationMetadata = updateSerializationMetadata(sourceEntity, that._serializationMetadata);
                copyPropertyValues(sourceEntity, that, that._propertyDefs, callback);
            },

            /**
             * Returns an object that provides functions to access entity metadata and state.
             * @instance
             * @memberOf modularis.EntityBase
             * @returns {modularis.EntityMetadata} 
             */
            getEntityMetadata: function () {
                var that = this;

                var entityMetadata = new entityMetadataFactory.EntityMetadata(that);
                return entityMetadata;
            },

            getPropertyValue: function (propertyName) {
                return this._propertyValues[propertyName];
            },

            /**
             * Changes the value of the given property.
             * @param {string} propertyName - Property name.
             * @param {Object} propertyValue - New property value.
             * @param {boolean} [suppressDirty=false] - Set to true if the entity Dirty flag should not be changed to true.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             */
            setPropertyValue: function (propertyName, propertyValue, suppressDirty) {
                var changeFlag = true;
                if (suppressDirty) {
                    changeFlag = !suppressDirty;
                }
                this._setPropertyValue(propertyName, propertyValue, changeFlag, true);
            },

            getInstanceWithSerializationMetadata: function () {
                var that = this,
                    isEntityBase = that instanceof EntityBase;

                var instanceWithMetadata = util.copy({ _Entity: that._serializationMetadata });

                //Assign Culture
                var culture = localization.getCurrentCulture();
                instanceWithMetadata._Entity.Culture = culture;

                //Serialize entities and collections
                for (var propertyName in that._propertyValues) {
                    if (that._propertyValues.hasOwnProperty(propertyName)) {
                        var propertyDef = that.getEntityMetadata().getPropertyDef(propertyName),
                            type = propertyDef.type;

                        var isModularisObject = type === types.typeEntity || type === types.typeEntityCollection || type === types.typeObject;
                        instanceWithMetadata[propertyName] = that[propertyName];

                        if (that._propertyValues[propertyName] != null) {
                            if (isModularisObject) {

                                //If the function getInstanceWithSerializationMetadata is defined, then the object will be serialized using that function.
                                if (util.isFunction(that._propertyValues[propertyName].getInstanceWithSerializationMetadata)) {
                                    if (isEntityBase) {
                                        instanceWithMetadata[propertyName] = that._propertyValues[propertyName].getInstanceWithSerializationMetadata();
                                    } else if (that[propertyName] && util.isFunction(that[propertyName].getInstanceWithSerializationMetadata)) {
                                        instanceWithMetadata[propertyName] = that[propertyName].getInstanceWithSerializationMetadata();
                                    }
                                }
                            }
                        } else {
                            //Check special case when an entity property could have been changed to the object that represents the lookup
                            if (that[propertyName] && util.isObject(that[propertyName]) && !isModularisObject) {
                                if (propertyName in that[propertyName]) {
                                    instanceWithMetadata[propertyName] = that[propertyName][propertyName];
                                }
                            } else {
                                /* It is possible that a UI framework like Kendo UI may change the entity properties from ECMAScript5 format to plain properties.
                                *  In that case, it is possible that values stored in _properties are outdated.
                                */
                                if (that[propertyName] && !isEntityBase && util.isDefined(that[propertyName].getInstanceWithSerializationMetadata)) {
                                    instanceWithMetadata[propertyName] = that[propertyName].getInstanceWithSerializationMetadata();
                                }
                            }
                        }
                    }
                }

                if (util.isDefined(instanceWithMetadata.EntityDefID) && util.isDynamicEntity(instanceWithMetadata.EntityDefID)) {
                    for (var field in that) {
                        if (that.hasOwnProperty(field)) {
                            if (field.indexOf('_') !== 0 && field !== 'uid' && !util.isFunction(field) && !(field in instanceWithMetadata) && field[0].toUpperCase() === field[0]) {

                                instanceWithMetadata[field] = that[field];
                                //EntityViewModelBase = > Save cambiar el Dirty = true; entity.getEntityMetadata().setDirty(true);
                            }
                        }
                    }
                }

                return instanceWithMetadata;
            },

            getKey: function () {
                var that = this;
                if (!that._key) {
                    that._key = '';
                    var separator = '.',
                        keyStructure = that.getEntityMetadata().getKeyStructure(),
                        keyProperties = keyStructure.split(separator),
                        isEntityBase = that instanceof EntityBase;

                    for (var index = 0; index < keyProperties.length; index++) {
                        var propertyName = keyProperties[index];
                        that._key += isEntityBase ? that.getPropertyValue(propertyName) : that[propertyName];
                        if (index !== (keyProperties.length - 1)) { that._key += separator; }
                    }
                }
                return that._key;
            },

            rebuildKey: function () {
                var that = this;
                that._key = null;
                that.getKey();
            },

            _getCollectionValue: function (propertyName, assemblyName) {
                var that = this;
                var propertyValue = that.getPropertyValue(propertyName);
                if (propertyValue == null) {
                    var propertyDef = that.getEntityMetadata().getPropertyDef(propertyName);
                    propertyValue = new entityCollectionBase.EntityCollectionBase(assemblyName, propertyDef.entityCollectionName, propertyDef.entityTypeName);
                    //that.setPropertyValue(propertyName, propertyValue);
                    that._setPropertyValue(propertyName, propertyValue, false);
                }
                return propertyValue;
            },

            _setPropertyValue: function (propertyName, propertyValue, changeFlag, storeOriginalValue) {
                var that = this;

                var transformedPropertyValue = propertyValue;

                if (propertyValue != null) {
                    var propertyDef = that.getEntityMetadata().getPropertyDef(propertyName);
                    if (propertyDef != null) {

                        //Convert date if necessary
                        if ((propertyDef.type === enums.propertyTypes.typeDateTime) && !(propertyValue instanceof Date)) {
                            transformedPropertyValue = new Date(propertyValue);
                        }
                    }
                }

                if (that._propertyValues[propertyName] !== transformedPropertyValue) {
                    var currentValue = that._propertyValues[propertyName];

                    that._propertyValues[propertyName] = transformedPropertyValue;
                    if (that[propertyName] !== transformedPropertyValue) {
                        that[propertyName] = transformedPropertyValue;
                    }

                    if (storeOriginalValue) {
                        that.getEntityMetadata()._storeOriginalValue(propertyName, currentValue);
                    }
                    if (util.isDefined(changeFlag) && changeFlag !== null) {
                        if (changeFlag) {
                            that._serializationMetadata.Dirty = true;
                        }
                    }
                }

                //Reset key if needed
                if (that._keyStructure.indexOf(propertyName) >= 0) {
                    that._key = null;
                }
            }

        };

        EntityBase.prototype.toPlainObject = EntityBase.prototype.getInstanceWithSerializationMetadata;

        return {
            EntityBase: EntityBase
        };
    }

);
