define('modularisData/modelAdaptee',
   ['modularisData/dataAccessAdaptee', 'util'],
   function (DataAccessAdaptee, util) {
       'use strict';
       /*This class allows the dataSource to consume data that comes from a model*/

       var ModelAdaptee = function (config) {
           var that = this;
           that.modelInstance = null;
           that.functionName = null;
           that.parameters = [];
           DataAccessAdaptee.call(that, config);
       };

       ModelAdaptee.prototype = new DataAccessAdaptee();

       ModelAdaptee.prototype.init = function (config) {
           var that = this;

           if (config.instance) { that.modelInstance = config.instance; }
           if (config.method) { that.functionName = config.method; }

           //functionName or method can be defined. However, functionName will override method when both are defined.
           if (config.functionName) { that.functionName = config.functionName; }
           if (config.parameters) { that.parameters = config.parameters; }
           if (config.parameterSource) { that.parameterSource = config.parameterSource; }

       };

       ModelAdaptee.prototype.read = function (options) {
           var that = this;

           var callback = that._getDefaultRequestCallback(options);

           if (!that.modelInstance) {
               callback(null, 'Invalid model instance');
               return;
           }

           if (!util.isString(that.functionName) || !(that.functionName in that.modelInstance)) {
               callback(null, 'Invalid function');
               return;
           }

           //Invoke model method passing the parameters as an array.
           var functionParameters = [];

           if (that.parameterSource) {
               var propertyNames = that.parameters.slice();
               for (var propertyNameIndex = 0; propertyNameIndex < propertyNames.length; propertyNameIndex++) {
                   var propertyName = propertyNames[propertyNameIndex];
                   var propertyValue = that.parameterSource[propertyName];
                   functionParameters.push(propertyValue);
               }
           } else {
               functionParameters = that.parameters.slice();
           }

            //Check if paging is enabled and an options object was passed as the last parameter
           if (that.dataSourceOptions.serverPaging && functionParameters.length > 0) {
               //The assumption here is that the second last parameter of the model function will be the option. 
               //This is necessary in order to make pagination work.
               var modelOptions = functionParameters[functionParameters.length - 1];
               if (util.isObject(modelOptions)) {
                   modelOptions = that._addPaginationOptions(modelOptions, options);
                   functionParameters[functionParameters.length - 1] = modelOptions;
               }
           }

           functionParameters.push(callback);
           that.modelInstance[that.functionName].apply(that.modelInstance, functionParameters);
       };

       ModelAdaptee.prototype.updateParameters = function (newParameters) {
           if (newParameters instanceof Array) {
               this.parameters = newParameters;
           } else {
               this.parameters = [newParameters];
           }
       };

       ModelAdaptee.prototype.updateEntityParameters = function (/*entities*/) {
           throw new Error('Function not available for data sources related to a model');
       };

       return ModelAdaptee;
   }
);
