define('webUtil',
   ['jquery', 'kendo', 'localization', 'logger', 'configLoader', 'util', 'enums'],
   function ($, kendo, localization, logger, configLoader, util, enums) {
       /*eslint-env es6 */
       'use strict';

       var attributesToIgnore = ['data-role', 'data-bind', 'id', 'class', 'style'];

       var webUtil = {
           callAfterViewModelIsDisplayed: function (viewModel) {
               if (viewModel != null && (typeof (viewModel.afterViewIsDisplayed) === 'function')) {
                   viewModel.afterViewIsDisplayed();
               }
           },

           bindToLogOffEvent: function (viewModel) {

               var eventHandlerID = null;

               if (viewModel && (typeof (viewModel.onLogOff) === 'function')) {
                   eventHandlerID = viewModel.securityServiceProxy.bind(enums.securityEventName.logOff, function () {
                       viewModel.onLogOff();
                   });
               }
               return eventHandlerID;

           },

           bindToUnauthorizedEvent: function (viewModel) {

               var eventHandlerID = null;

               if (viewModel && (typeof (viewModel.onUnauthorized) === 'function')) {
                   eventHandlerID = viewModel.securityServiceProxy.bind(enums.securityEventName.unauthorized, function () {
                       viewModel.onUnauthorized();
                   });
               }
               return eventHandlerID;

           },

           unbindFromLogOffEvent: function (viewModel, eventHandlerID) {
               if (viewModel && viewModel.securityServiceProxy) {
                   viewModel.securityServiceProxy.unbind(enums.securityEventName.logOff, eventHandlerID);
               }
           },

           unbindUnauthorizedEvent: function (viewModel, eventHandlerID) {
               if (viewModel && viewModel.securityServiceProxy) {
                   viewModel.securityServiceProxy.unbind(enums.securityEventName.unauthorized, eventHandlerID);
               }
           },

           destroyViewModel: function (viewModelInstance) {
               var that = this;

               if (viewModelInstance && viewModelInstance._logOffEventHandlerID) {
                   that.unbindFromLogOffEvent(viewModelInstance, viewModelInstance._logOffEventHandlerID);
               }

               if (viewModelInstance && viewModelInstance._unauthorizedEventHandlerID) {
                   that.unbindUnauthorizedEvent(viewModelInstance, viewModelInstance._unauthorizedEventHandlerID);
               }

               if (viewModelInstance && util.isFunction(viewModelInstance.disposeViewModel)) {
                   viewModelInstance.disposeViewModel();
               }
           },

           addViewSettings: function (viewPath, templateId, existingObject) {
               //Check this link for details: http://docs.telerik.com/kendo-ui/api/javascript/view#configuration-wrap
               var result = {};
               if (existingObject != null) {
                   result = existingObject;
               }

               result.wrap = configLoader.appConfig.view.wrap ? true : false;

               result.show = function () {
                   $.event.trigger({
                       type: enums.modularisViewEventName.showed,
                       viewEventData: {
                           path: viewPath,
                           templateId: templateId
                       }
                   });
               };

               return result;
           },

           getLookupDropDownListSettings: function (propertyDef, data, selectedValue) {
               var displaySettings = this.getLookupDropDownDisplaySettings(propertyDef);

               var result = {
                   template: displaySettings.displayTemplate,
                   valueTemplate: displaySettings.displayTemplate,
                   dataValueField: propertyDef.LookupProperty,
                   dataTextField: displaySettings.firstDisplayProperty,
                   optionLabel: localization.translate('propdropdown_option_label')
               };

               if (data) {
                   result.dataSource = data;
               }

               if (selectedValue) {
                   result.value = selectedValue;
               }

               return result;
           },

           isArray: function (array) {
               return (array instanceof Array) || (array instanceof kendo.data.ObservableArray);
           },

           formatLookupQueryDefParameterValues: function (source, parameterValues) {
               var result = [];
               if (parameterValues && parameterValues.length > 0) {
                   //Find values for queryDef parameters.
                   var propertiesInParameterValues = parameterValues.split(',');
                   for (var index = 0; index < propertiesInParameterValues.length; index++) {
                       var currentProperty = propertiesInParameterValues[index];
                       var propertyValue = null;
                       if (currentProperty in source) {
                           //In some cases, the Grid will convert the property to the corresponding object.
                           if (source[currentProperty] && util.isObject(source[currentProperty])) {
                               propertyValue = source[currentProperty][currentProperty];
                           } else {
                               propertyValue = source[currentProperty];
                           }
                       }
                       result.push(propertyValue);
                   }
               }
               return result;
           },

           getLookupDropDownDisplaySettings: function (propertyDef) {
               var result = {};

               var displayPropertiesTemplate = '';
               var firstDisplayProperty = '';
               var modValue = 2;

               if (propertyDef.LookupDisplayMask.trim().length > 0) {

                   var displayMaskParts = propertyDef.LookupDisplayMask.split('@@');
                   for (var maskIndex = 0; maskIndex < displayMaskParts.length; maskIndex++) {
                       var displayMaskPart = displayMaskParts[maskIndex];
                       if (maskIndex % modValue === 0) {
                           displayPropertiesTemplate += displayMaskPart;
                       } else {
                           if (displayPropertiesTemplate.length === 0) {
                               firstDisplayProperty = displayMaskPart;
                           }
                           displayPropertiesTemplate += '#: ' + displayMaskPart + ' #';
                       }
                   }

               } else if (propertyDef.LookupDisplayProperties.trim().length > 0) {

                   var displayProperties = propertyDef.LookupDisplayProperties.split(',');
                   firstDisplayProperty = displayProperties[0];

                   for (var propertyIndex = 0; propertyIndex < displayProperties.length; propertyIndex++) {

                       if (displayPropertiesTemplate.length > 0) {
                           displayPropertiesTemplate += ', ';
                       }

                       displayPropertiesTemplate += '#: ' + displayProperties[propertyIndex] + ' #';
                   }

               }

               result.displayTemplate = displayPropertiesTemplate;
               result.firstDisplayProperty = firstDisplayProperty;

               return result;
           },

           getAssociatedViewModel: function (element) {
               //Try to get binding target from the element itself.
               var bindingTarget = element.prop('kendoBindingTarget');

               if (!bindingTarget) {
                   var ancestor = element.closest('div[class!=mod-form-group]').parent();

                   //Try to get it from the closest div
                   bindingTarget = ancestor.find('input[data-bind]').prop('kendoBindingTarget');
                   if (!bindingTarget) {
                       //Finally, try with the a parent div of the ancestor element.
                       bindingTarget = ancestor.closest('div').find('input[data-bind]').prop('kendoBindingTarget');
                   }
               }

               var source = null;
               if (bindingTarget) {
                   source = bindingTarget.source;
               }
               return source;
           },

           //Capitalize the letter after the dash.
           convertToCamelCase: function (string) {
               //taken from http://stackoverflow.com/a/6009452/1601530
               return string.replace(/-([a-z])/ig, function (all, letter) {
                   return letter.toUpperCase();
               });
           },

           getAdditionalAttributes: function (element, options) {
               if (!element || !$(element).get(0)) {
                   return {};
               }

               var allAttributes = $(element).get(0).attributes,
                   additionalAttributes = {};

               var initialPosition = 5;

               for (var index = 0; index < allAttributes.length; index++) {
                   var attribute = allAttributes[index],
                       attributeName = attribute.name.toLowerCase();
                   if (attribute.specified && attributesToIgnore.indexOf(attributeName) < 0) {
                       if (attributeName.startsWith('data-')) {
                           var dataAttributeName = attributeName.substring(initialPosition);
                           dataAttributeName = this.convertToCamelCase(dataAttributeName);
                           if (!(dataAttributeName in options)) {
                               additionalAttributes[attributeName] = attribute.value;
                           }
                       } else {
                           additionalAttributes[attributeName] = attribute.value;
                       }
                   }
               }
               return additionalAttributes;
           },

           clickHandler: function (control, clickCallback, doubleClickCallback, doubleClickTolerance, rowId) {
               var bindElement = control.tbody ? control.tbody : control;

               var bindControl = $(bindElement);
               var previousRowId = bindControl.data('rowId');

               //This code is based on http://jsfiddle.net/V8twU/44/
               if (previousRowId && previousRowId === rowId) {
                   bindControl.data('rowId', null);

                   if (bindControl.data('alreadyclickedTimeout')) {
                       clearTimeout(bindControl.data('alreadyclickedTimeout'));
                   }

                   if (doubleClickCallback) {
                       doubleClickCallback(control);
                   }
               } else {
                   bindControl.data('rowId', rowId);

                   if (bindControl.data('alreadyclickedTimeout')) {
                       clearTimeout(bindControl.data('alreadyclickedTimeout'));
                   }

                   var alreadyclickedTimeout = setTimeout(function () {
                       bindControl.data('rowId', null);

                       if (clickCallback) {
                           clickCallback(control);
                       }

                   }, doubleClickTolerance);
                   bindControl.data('alreadyclickedTimeout', alreadyclickedTimeout);
               }
           },

           //Function to establish if the given element is part of the viewport.
           //Very useful in cases where the element is considered visible but cannot be seen.
           //Adapted from http://stackoverflow.com/a/7557433/1601530
           isInViewport: function (element) {

               if (util.isFunction($) && element instanceof $) {
                   element = element[0];
               }

               var rect = element.getBoundingClientRect();

               var inViewport = (rect.top >= 0 && rect.left >= 0 &&
                                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                                rect.right <= (window.innerWidth || document.documentElement.clientWidth));
               return inViewport;
           }

       };
       return webUtil;

   }
);
