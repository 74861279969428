define('modularis/web/widgets/propDropDown',
    ['jquery', 'kendo', 'RESTActivityWebServiceProxy', 'metadataCache', 'dataSource', 'localization', 'logger', 'webUtil', 'util'],
    function ($, kendo, activityServiceProxy, metadataCache, ModularisDataSource, localization, logger, webUtil, util) {
        'use strict';
        var ui = kendo.ui;
        var DropDownList = ui.DropDownList;

        var hasSourceDatabind = function (element) {
            var databind = $(element).data('bind');
            databind = databind ? databind.replace(' ', '') : '';
            var result = databind.length > 0 && databind.indexOf('source:') > -1;
            return result;
        };

        //#region Widget definition

        /**
          * @classdesc Modularis PropDropDown widget. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/dropdownlist|kendo.ui.DropDownList}.
          * @constructs modularis.web.widgets.ModularisPropDropDown
          * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
          * 
          * @property {Object} options - Widget options.
          * @property {Object} options.additionalOptions - Additional non-standard options that will passed to the activityServiceProxy when the data is fetched.
          * @property {string|Object} options.optionLabel - If a translation resource key is provided, the translation for the resource will be used as the optionLabel. Otherwise, it will follow the standard behavior of the base class.
          */
        var ModularisPropDropDown = DropDownList.extend({

            _entityDefId: null,
            _selectedValue: null,
            _tempEnabled: null,
            _propertyDef: null,
            _lookupValues: null,
            _currentSelectedValue: null,
            _isInitialized: false,
            _initialValue: null,

            init: function (element, options) {
                var that = this;
                DropDownList.fn.init.call(that, element, options);
                that._isInitialized = false;
                that.bind('dataBound', $.proxy(that._propDropDownDataBound, that));
                that.bind('change', $.proxy(that._propDropDownChange, that));
            },

            options: {
                name: 'ModularisPropDropDown',
                propertyName: null,
                additionalOptions: {},
                optionLabel: 'propdropdown_option_label',
                valuePrimitive: true
            },

            entityDefId: function (newEntityDefId) {
                var that = this;
                if (that._entityDefId !== newEntityDefId) {
                    that._entityDefId = newEntityDefId;
                    that._populate();
                }
            },

            value: function (newValue) {
                var that = this;

                if (!that._isInitialized && newValue !== undefined) { that._initialValue = newValue; }
                if (newValue !== undefined) { that._currentSelectedValue = newValue; }

                var value = DropDownList.fn.value.call(that, newValue);

                return value;
            },


            _propDropDownDataBound: function (/*event*/) {
                var that = this;

                that._populate(function () {
                    that.value(that._currentSelectedValue);
                });
            },

            _propDropDownChange: function (/*event*/) {
                var that = this;

                that._currentSelectedValue = that.value();

            },
            selectedValue: function (newSelectedValue) {
                var that = this;
                return that.value(newSelectedValue);
            },

            _getLookupParameterValues: function (propertyDef) {
                var that = this;
                var source = webUtil.getAssociatedViewModel(that.element);
                var parameterValues = webUtil.formatLookupQueryDefParameterValues(source, propertyDef.LookupQueryDefParameterValues);
                return parameterValues;
            },

            _hasLookupValuesChanged: function () {
                var that = this;
                var result = false;
                if (that._propertyDef && that._lookupValues) {
                    var parameterValues = that._getLookupParameterValues(that._propertyDef);
                    result = parameterValues.join().toLowerCase() !== that._lookupValues.join().toLowerCase();
                }
                return result;
            },

            _populate: function (callback) {
                var that = this;
                if (that._entityDefId !== null && that.options.propertyName !== null && !that._isInitialized) {
                    metadataCache.getPropertyDef(that._entityDefId, that.options.propertyName,
                        function (propertyDef, error) {
                            if (util.success(propertyDef, error)) {

                                var source = null;

                                if (propertyDef.LookupQueryDefParameterValues && propertyDef.LookupQueryDefParameterValues.length > 0) {
                                    source = webUtil.getAssociatedViewModel(that.element);
                                    if (!source) {
                                        source = that.dataSource && util.isFunction(that.dataSource.getParameterSource) ? that.dataSource.getParameterSource() : null;
                                    }
                                    if (!source) {
                                        //logger.error('Error loading data for the ModularisPropDropDown ' + that.element.prop('outerHTML') + '. Please check the bindings.');
                                        util.notify(callback, {});
                                        return;
                                    }
                                }

                                var parameterValues = webUtil.formatLookupQueryDefParameterValues(source, propertyDef.LookupQueryDefParameterValues);
                                var displaySettings = webUtil.getLookupDropDownDisplaySettings(propertyDef);
                                var hasSourceDataBind = hasSourceDatabind(that.element);
                                if (!hasSourceDataBind) {
                                    var defaultDataSource = new ModularisDataSource({
                                        modularis: {
                                            activity: {
                                                method: 'getCollectionByQueryDefID',
                                                entityTypeName: propertyDef.LookupEntity,
                                                queryDefID: propertyDef.LookupQueryDefID,
                                                parameters: parameterValues,
                                                options: that.options.additionalOptions
                                            }
                                        }
                                    });
                                    that.setDataSource(defaultDataSource);
                                    that.value(that._initialValue);
                                }

                                var dropDownListOptions = util.copy(that.options);

                                if (!dropDownListOptions.template || dropDownListOptions.template.length === 0) {
                                    dropDownListOptions.template = displaySettings.displayTemplate;
                                }

                                if (!dropDownListOptions.valueTemplate || dropDownListOptions.valueTemplate.length === 0) {
                                    dropDownListOptions.valueTemplate = displaySettings.displayTemplate;
                                }

                                if (!dropDownListOptions.dataValueField || dropDownListOptions.dataValueField.length === 0) {
                                    dropDownListOptions.dataValueField = propertyDef.LookupProperty;
                                }

                                if (!dropDownListOptions.dataTextField || dropDownListOptions.dataTextField.length === 0) {
                                    dropDownListOptions.dataTextField = displaySettings.firstDisplayProperty;
                                }

                                dropDownListOptions.optionLabelTemplate = dropDownListOptions.optionLabel;

                                //Allow an empty string as optionLabel
                                if (dropDownListOptions.optionLabel == null) {
                                    dropDownListOptions.optionLabelTemplate = localization.translate('propdropdown_option_label');
                                } else if (dropDownListOptions.optionLabel.length > 0 && dropDownListOptions.optionLabel.indexOf(' ') < 0) {
                                    //Try to localize the given option label
                                    var labelTranslation = localization.translate(dropDownListOptions.optionLabel);
                                    if (labelTranslation != null) {
                                        dropDownListOptions.optionLabelTemplate = labelTranslation;
                                    }
                                }

                                that.setOptions(dropDownListOptions);

                                that._propertyDef = propertyDef;
                                that._lookupValues = parameterValues;
                                that._isInitialized = true;
                                util.notify(callback, {});
                            }
                        }
                    );
                } else {
                    util.notify(callback, {});
                }
            }
        });
        //#endregion

        //#region Property binders

        var dropDownBinderContainer = {
            /*eslint-disable no-param-reassign*/

            init: function () {
                kendo.data.binders.widget.entityDefId = kendo.data.Binder.extend({

                    init: function (widget, bindings, options) {
                        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);
                    },

                    refresh: function () {
                        var that = this;
                        var value = that.bindings.entityDefId.get();
                        $(that.element).getKendoModularisPropDropDown().entityDefId(value);
                    }

                });

                kendo.data.binders.widget.selectedValue = kendo.data.Binder.extend({

                    init: function (widget, bindings, options) {
                        var that = this;
                        kendo.data.Binder.fn.init.call(that, widget.element[0], bindings, options);
                        $(that.element).on('change', function () {
                            that.change();
                        });
                    },

                    refresh: function () {
                        var that = this;
                        var value = that.bindings.selectedValue.get();
                        $(that.element).getKendoModularisPropDropDown().selectedValue(value);
                    },

                    change: function () {
                        var that = this;
                        var value = that.element.value;
                        that.bindings.selectedValue.set(value);
                    }

                });

            }
            /*eslint-enable no-param-reassign*/
        };



        //#endregion

        return {
            widgetClass: ModularisPropDropDown,
            binderContainer: dropDownBinderContainer
        };

    }
);
