define('modularisGeneral/widgetHelper',
   ['jquery', 'kendo', 'util'],
   function ($, kendo, modularisUtil) {
       'use strict';

       var messageWindowDefaultOptions = {
           title: 'Message',
           message: 'Message here',
           buttonConfiguration: ['OK'],
           buttonPressed: null,
           localizeText: true,
           windowOptions: {
               modal: true,
               visible: false,
               width: '30%'
           }
       };

       var widgetHelper = {

           showMessageNotification: function (message, type, options) {

               if (!message) { message = 'Message'; }
               if (!type) { type = 'info'; }

               var defaultOptions = {};

               var notificationOptions = {};
               $.extend(notificationOptions, defaultOptions, options);

               //create element for the notification
               var notificationId = 'notification-' + modularisUtil.newGuid(),
                  notificationSelector = '#' + notificationId;

               $('body').append('<span id="' + notificationId + '"></span>');

               // initialize the widget
               var notificationElement = $(notificationSelector);
               notificationElement.kendoNotification(notificationOptions);

               // get the widget instance
               var notificationWidget = notificationElement.getKendoNotification();

               // display a "foo" message
               notificationWidget.show(message, type);

               notificationWidget.bind('hide', function () {
                   $(notificationSelector).remove();
               });

           },

           showMessageWindow: function (options) {

               var messageWindowOptions = {};
               $.extend(true, messageWindowOptions, messageWindowDefaultOptions, options);

               if (!messageWindowOptions.windowOptions.title) {
                   messageWindowOptions.windowOptions.title = messageWindowOptions.title;
               }

               var windowId = 'message-window-' + modularisUtil.newGuid(),
                     windowSelector = '#' + windowId;

               var buttonFormat = '<button class="windowButton" type="button" style="width:auto;">{0}</button>';

               //Create buttons
               var buttonMarkup = '';
               for (var index = 0; index < messageWindowOptions.buttonConfiguration.length; index++) {
                   var buttonConfig = messageWindowOptions.buttonConfiguration[index];
                   if (typeof (buttonConfig) == 'string') {
                       buttonMarkup += String.format(buttonFormat, buttonConfig);
                   }
                   //Add a tab between buttons
                   if (index !== (messageWindowOptions.buttonConfiguration.length - 1)) {
                       buttonMarkup += '&nbsp;';
                   }
               }

               var templateMarkupFormat = '<div id="#= windowId #"><div class="m-align-center" style="margin-bottom: 10px;">#= message #</div><div class="m-align-center">{0}</div></div>';
               var templateMarkup = String.format(templateMarkupFormat, buttonMarkup);
               var template = kendo.template(templateMarkup);

               //Try to localize 

               var templateData = {
                   windowId: windowId,
                   message: messageWindowOptions.message
               };

               $('body').append(template(templateData));

               var dialog = $(windowSelector).kendoWindow(messageWindowOptions.windowOptions).getKendoWindow();

               var clickHandler = function (event) {
                   var text = event.sender.element.text();
                   modularisUtil.notify(messageWindowOptions.buttonPressed, text);
                   dialog.close();
               };

               $(windowSelector + ' .windowButton').kendoButton({
                   click: clickHandler
               });

               dialog.center();
               dialog.open();
               dialog.bind('close', function (event) {
                   event.sender.destroy();
               });
           }

       };

       return widgetHelper;

   }
);
