define('modularis/i18n/translators/htmlTranslator',
    [],function () {
        'use strict';

        var HtmlTranslator = function () {
            var that = this;
            that.execute = function (options, localization) {
                if (typeof (options.element.html) === 'function') {
                    var translationResult = localization.translate(options.key);

                    if (translationResult == null) {
                        return false;
                    }
                    options.element.html(translationResult);
                }

                return true;
            };
        };

        return HtmlTranslator;
    });
