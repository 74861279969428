define('logger',
   [],function () {
       'use strict';
       /*eslint no-console:0*/

       var logger = {
           error: function (object) {
               console.error(object);
           },
           info: function (object) {
               console.info(object);
           }
       };

       return logger;

   }
);
