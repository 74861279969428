define('configLoader',
   ['jquery'],
   function ($) {
       'use strict';

       var timeoutInMinutes = 10,
           seconds = 60,
           milliseconds = 1000,
           marginInMilliseconds = 10000;

       var defaultAppConfig = {
           uiDefID: '00000000-0000-0000-0000-000000000000',
           systemName: 'Modularis',
           serverConfigs: [
              {
                  name: 'Local Server',
                  customerCode: 'MyCompany',
                  customerID: 'C93F949C-41B8-4C9E-95AA-B030B31F6F3F',
                  primaryNotificationURI: null,
                  primaryURI: 'http://localhost:8080/Modularis',
                  secondaryNotificationURI: null,
                  secondaryURI: null,
                  bindingPrefix: null,
                  servicePrincipalName: null
              }
           ],

           defaultServerName: 'Local Server',

           debug: {
               enabled: false
           },

           realTimeNotifications: {
               enabled: true,
               responseDataType: 'binary',
               timeout: (timeoutInMinutes * seconds * milliseconds) + marginInMilliseconds,
               accumulateMessages: false
           },

           handleSessionTimeout: true,
           allowServerSelection: false,
           layout: {
               defaultPublicLayoutPath: 'shared/_defaultLayout',
               defaultAuthenticatedUserLayoutPath: 'shared/_authenticatedUserLayout',
               defaultNewBrowsingContextLayoutPath: null
           },
           newBrowsingContext: {
               cloneSession: false,
               cloneSessionCommand: null
           },
           sessionCookie: {
               enabled: true,
               expirationDays: 7
           },
           style: {
               basePath: 'styles/',
               cache: false,
               sheets: [
                  'bootstrap/bootstrap.min',
                  'modularis/modularis.fonts.min',
                  'themeCommonStyleSheet',
                  'themeStyleSheet',
                  'modularis/modularis.custom.min'
               ],
               theme: {
                   defaultTheme: 'Default',
                   allowThemeSelection: true,
                   cookieDuration: 30,
                   themePath: 'modularis/',
                   //common stylesheet path
                   commonPath: 'kendo/',
                   themePrefix: 'kendo.modularis',
                   //common  stylesheet prefix
                   commonPrefix: 'kendo',
                   themeExtension: '.min.css',
                   commonExtension: '.min.css',
                   availableThemes: [
                      { name: 'Default', commonStylesheet: 'common-material' },
                      { name: 'Modnight', commonStylesheet: 'common' },
                      { name: 'Dark', commonStylesheet: 'common' }
                   ]
               }
           },
           view: {
               wrap: false
           },
           cacheViews: false,
           urlArgs: 'v=' + (new Date()).getTime(),
           templatesBasePath: 'views/',
           templatesExtension: '.tmpl.htm',
           viewModelsBasePath: 'app/viewModels/',
           defaultTemplateContainerSelector: '#main',
           localization: {
               resourcesBasePath: 'app/i18n/',
               allowDefaultCultureOverride: true,
               localizeWidget: false,
               loadDataResources: false,
               kendoCulturePath: 'kendo/cultures/kendo.culture.{0}.min',
               kendoMessagesPath: 'kendo/messages/kendo.messages.{0}.min',
               dataResourcePath: 'data/dataResources.{0}',
               entityResourcePath: 'entity/entityResources.{0}',
               stringResourcePath: 'string/stringResources.{0}',
               supportedCultures: ['en'],
               validateModulesInServer: false
           },
           defaultRoutes: {
               login: '#/login',
               logOff: '#/log-off',
               afterSuccessfulLogin: '#/',
               tenantSelector: '#/tenant-selector'
           },
           entity: {
               folderName: 'entity',
               convertToJavaScriptEntity: true,
               logEntityErrors: false,
               checkCollectionDuplicatedKeys: true
           }
       };

       var configLoader = {

           appConfig: null,

           init: function (newAppConfig) {

               if (newAppConfig != null) {
                   this.appConfig = $.extend(true, defaultAppConfig, newAppConfig);

                   //Manually merge settings that are handled as arrays

                   //server configs
                   if (defaultAppConfig.serverConfigs.length !== newAppConfig.serverConfigs.length) {
                       this.appConfig.serverConfigs = newAppConfig.serverConfigs;
                   }

                   //supported cultures
                   if (newAppConfig.localization && newAppConfig.localization.supportedCultures) {
                       if (defaultAppConfig.localization.supportedCultures.length !== newAppConfig.localization.supportedCultures.length) {
                           this.appConfig.localization.supportedCultures = newAppConfig.localization.supportedCultures;
                       }
                   }

                   //available themes
                   if (newAppConfig.style && newAppConfig.style.theme && newAppConfig.style.theme.availableThemes) {
                       if (newAppConfig.style.theme.availableThemes.length !== defaultAppConfig.style.theme.availableThemes.length) {
                           this.appConfig.style.theme.availableThemes = newAppConfig.style.theme.availableThemes;
                       }
                   }

                   //stylesheets to load
                   if (newAppConfig.style && newAppConfig.style.sheets) {
                       if (newAppConfig.style.sheets.length !== defaultAppConfig.style.sheets.length) {
                           this.appConfig.style.sheets = newAppConfig.style.sheets;
                       }
                   }

               } else {
                   this.appConfig = defaultAppConfig;
               }
           },

           getConfigValue: function (configSettingName) {
               return this.appConfig[configSettingName];
           },

           containsConfigValue: function (configSettingName) {
               var contains = (configSettingName in this.appConfig);
               return contains;
           }

       };

       return configLoader;

   }
);
