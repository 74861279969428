define('modularis/viewModels/widgets/itemDataContainer',
    ['jquery', 'kendo', 'viewModelBase', 'util', 'enums'],
    function ($, kendo, viewModelBaseFactory, util, enums) {
        'use strict';
        var selectEvent = 'select',
            unselectEvent = 'unselect',
            dataBoundEvent = 'dataBound',
            changeEvent = 'change';

        var half = 2;

        var editModes = enums.editModes;

        var booleanSettings = ['editable', 'filterable', 'groupable', 'pageable', 'scrollable', 'sortable', 'navigatable', 'selectEntityOnNavigation'];

        var capitalizeFirstLetter = function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        var getCoverId = function (widgetId, name) {
            return String.format('{0}-{1}-cover', widgetId, name);
        };

        var getWrapperId = function (widgetId, name) {
            return String.format('{0}-{1}-wrapper', widgetId, name);
        };

        var addCoverDiv = function (widgetId, name, uiControl) {
            var coverId = getCoverId(widgetId, name);
            var wrapperId = getWrapperId(widgetId, name);
            var cover = uiControl;

            var height = cover.outerHeight();

            if (name !== 'ButtonContainer') {
                cover = document.getElementById(wrapperId);

                if (!cover) {
                    var wrapperClass = String.format('{0}-grid-wrapper', name);
                    var wrapper = $(uiControl).wrap('<div id="' + wrapperId + '" style="position:relative" class="' + wrapperClass + '"></div>');
                    cover = wrapper.parent();
                }
            }

            $('<div class=\"' + coverId + '\">').css({
                'width': '100%',
                'height': height,
                'position': 'absolute',
                'top': 0,
                'z-index': 10000
            }).appendTo(cover);

            uiControl.addClass('k-state-disabled');
        };

        var removeCoverDiv = function (widgetId, name, list) {
            var coverId = getCoverId(widgetId, name);
            var cover = list.parent().find('.' + coverId);
            var coverExists = cover.length > 0;

            if (coverExists) {
                cover.remove();
                list.removeClass('k-state-disabled');
            }

            return coverExists;
        };

        var updateCoverDiv = function (widgetId, name, list) {
            var coverDeleted = removeCoverDiv(widgetId, name, list);

            if (coverDeleted) {
                setTimeout(function () {
                    addCoverDiv(widgetId, name, list);
                }, 0);
            }
        };

        var setEnable = function (widgetId, widgetSelector, containerName, toggle) {
            var uiControl = $(widgetSelector);
            var coverFunction = toggle ? removeCoverDiv : addCoverDiv;

            if (uiControl.length === 0) {
                return false;
            }

            coverFunction(widgetId, containerName, uiControl);
            return true;
        };

        var normalizeColumns = function (columnsArray) {
            if (!columnsArray || !columnsArray.length) {
                return;
            }

            var column = null;
            for (var columnIndex = 0; columnIndex < columnsArray.length; columnIndex++) {
                column = columnsArray[columnIndex];
                /*
                    Added validation and conversion to JSON object to avoid the injection of javascript code
                    through the "column.attributes" property after kendo converted this property
                    to an Observable Object.
                */
                if (column.attributes && column.attributes instanceof kendo.Observable) {
                    column.attributes = util.toJSON(column.attributes);
                }
            }
        };

        var normalizeBeforeSelectUnSelectCallsResponse = function (response, error, sourceItems) {
            if (!response) {
                response = { cancel: false };
            }

            if (error) {
                response.cancel = true;
            } else {
                if (!response.items) {
                    response.items = sourceItems;
                }
            }

            return response;
        };

        var ItemSelectorViewModel = function () {

            var that = this;
            viewModelBaseFactory.ViewModelBase.call(that);

            that.flag = false;
            that.selectEnable = false;
            that.unselectEnable = false;
            that.selectAllEnable = false;
            that.unselectAllEnable = false;
            that.gridsHeight = 200;
            that.changeEditMode = null;

            that.widgetStyle = null;
            that._tempColumns = null;
            that._itemsSelectedMulti = null;
            that._tempSelectedColumns = null;
            that._tempPlaceholder = null;
            that._additionalAttributes = null;

            that.items = null;
            that.itemsSelected = null;
            that.allItems = new kendo.data.DataSource();

            that.widgetId = util.newGuid();
            that.availableItemsWidgetSelector = '#' + that.widgetId + ' .available-grid';
            that.selectedItemsWidgetSelector = '#' + that.widgetId + ' .selected-grid';
            that.buttonContainerSelector = '#' + that.widgetId + ' .m-button-container';

            //#region Attributes specific to entityEditList style
            that._entityTypeName = null;
            that._selectedEntitiesTypeName = null;
            //#endregion

            //#region Attributes specific to entityEditList and grid style
            that._editable = null;
            that._selectedItemsEditable = null;
            that._filterable = null;
            that._selectedItemsFilterable = null;
            that._groupable = null;
            that._selectedItemsGroupable = null;
            that._pageable = null;
            that._selectedItemsPageable = null;
            that._scrollable = null;
            that._selectedItemsScrollable = null;
            that._sortable = null;
            that._selectedItemsSortable = null;
            that._navigatable = null;
            that._selectEntityOnNavigation = null;
            //#endregion

            //#region Attributes specific to Enable feature
            that._availableItemListEnabled = null;
            that._selectedItemListEnabled = null;
            that._buttonContainerEnabled = null;
            that._gridsEnableValue = null;
            //#endregion

            that.selectedItemsTitle = '';
            that.availableItemsTitle = '';

            that._beforeSelectedItemsHandler = null;
            that._beforeUnselectedItemsHandler = null;
            that._beforeSelectedItemsAsyncHandler = null;
            that._beforeUnselectedItemsAsyncHandler = null;

            that.itemsSelectedChangeHandler = null;
            that.itemsUnselectedChangeHandler = null;
        };

        ItemSelectorViewModel.prototype = new viewModelBaseFactory.ViewModelBase();

        ItemSelectorViewModel.prototype.setSelectedItems = function (items) {
            var that = this;
            this.set('itemsSelected', items);
            if (this.get('itemsSelected')) {
                that.itemsSelectedChangeHandler = this.get('itemsSelected').bind('change', $.proxy(that.itemsChange, that));
                this.get('itemsSelected').read();
            }
        };

        ItemSelectorViewModel.prototype.selectChange = function () {
            var that = this;
            that.updateEnabledButtons();
            that.trigger(changeEvent, { sourceContainer: 'selectedItems' });
        };

        ItemSelectorViewModel.prototype.unselectChange = function () {
            var that = this;
            that.updateEnabledButtons();
            that.trigger(changeEvent, { sourceContainer: 'unselectedItems' });
        };

        ItemSelectorViewModel.prototype.itemsChange = function () {
            var that = this;
            that.setAllItems();
            that.updateEnabledButtons();
        };

        ItemSelectorViewModel.prototype.updateEnabledButtons = function () {
            var that = this;
            var selectEnable = false;
            var selectAllEnable = false;
            var unselectAllEnable = false;
            var unselectEnable = false;

            var availableItemsWidget = kendo.widgetInstance($(that.availableItemsWidgetSelector));
            if (availableItemsWidget) {
                var availableItemsWidgetSelection = availableItemsWidget.select();
                selectEnable = availableItemsWidgetSelection.length > 0;
            }

            var selectedItemsWidget = kendo.widgetInstance($(that.selectedItemsWidgetSelector));
            if (selectedItemsWidget) {
                var selectedItemsWidgetSelection = selectedItemsWidget.select();
                unselectEnable = selectedItemsWidgetSelection.length > 0;
            }


            if (that.items) {
                selectAllEnable = that.items.view().length > 0;
                if (!selectAllEnable) {
                    selectEnable = false;
                }
            }


            if (that.itemsSelected) {
                unselectAllEnable = that.itemsSelected.view().length > 0;

                if (!unselectAllEnable) {
                    unselectEnable = false;
                }
            }

            that.set('selectAllEnable', selectAllEnable);
            that.set('selectEnable', selectEnable);
            that.set('unselectEnable', unselectEnable);
            that.set('unselectAllEnable', unselectAllEnable);
        };

        ItemSelectorViewModel.prototype.setAllItems = function () {
            var that = this;
            that.allItems.data([]);
            var items = [];
            if (that.items) {
                if (that.items.data().length > 0) {
                    items = items.concat(that.items.data());
                }
            }
            if (that.itemsSelected) {
                if (that.itemsSelected.data().length > 0) {
                    items = items.concat(that.itemsSelected.data());
                }
            }

            that.allItems.data(items[0]);
        };

        ItemSelectorViewModel.prototype.setOptions = function (options) {
            var that = this;
            that.set('_tempColumns', options.columns);
            that.set('_gridsHeight', options.height);
            that.set('_tempSelectedColumns', options.columnsSelected);
            that.set('_tempPlaceholder', options.placeholder);
            that.set('_tempTextField', options.textField);
            that.set('_tempValueField', options.valueField);
            that.set('widgetStyle', options.style);
            that.set('selectedItemsTitle', that._translateTitle(options.selectedItemsTitle));
            that.set('availableItemsTitle', that._translateTitle(options.unselectedItemsTitle));
            that.changeEditMode = options.changeEditMode;

            var optionsToAssign = ['entityTypeName', 'selectedEntitiesTypeName', 'additionalAttributes'];
            optionsToAssign = optionsToAssign.concat(booleanSettings);

            for (var index = 0; index < optionsToAssign.length; index++) {
                var optionName = optionsToAssign[index];
                that['_' + optionName] = options[optionName];
                if (booleanSettings.indexOf(optionName) >= 0) {
                    var selectedItemsOption = 'selectedItems' + capitalizeFirstLetter(optionName);
                    if (('_' + selectedItemsOption) in that) {
                        that['_' + selectedItemsOption] = options[selectedItemsOption];
                    }
                }
            }

            if (options.selectedItems) {
                that.setSelectedItems(options.selectedItems);
            }
            if (options.unselectedItems) {
                that.setUnselectedItems(options.unselectedItems);
            }
        };

        ItemSelectorViewModel.prototype.setButtonContainerPosition = function () {
            var that = this;
            var buttonsPosition = (this._gridsHeight - $(that.buttonContainerSelector).height()) / half;
            $(that.buttonContainerSelector).css({ bottom: buttonsPosition });
        };

        ItemSelectorViewModel.prototype.adjustLayout = function () {
            var that = this;

            var available, selected;

            normalizeColumns(that._tempColumns);
            normalizeColumns(that._tempSelectedColumns);

            switch (that.widgetStyle.toLowerCase()) {
                case 'toggle':
                    var options = {
                        placeholder: that._tempPlaceholder,
                        dataTextField: that._tempTextField,
                        dataValueField: that._tempValueField,
                        dataSource: that.allItems,
                        value: that._itemsSelectedMulti,
                        viewModel: that
                    };
                    var template = '<div style="list-style-type: none; padding:10px">#:' + that._tempTextField + '#</div>';
                    var optionsLV = { selectable: 'multiple', template: kendo.template(template), dataSource: that.allItems, viewModel: that };
                    var multi = $('#' + that.widgetId + ' select.multiselect').kendoMultiSelect(options).data('kendoMultiSelect');
                    var listview = $('#' + that.widgetId + ' .m-listview-selector').kendoListView(optionsLV).data('kendoListView');
                    that._selectAtList(that.itemsSelected.data());
                    that._selectAtMulti(that.itemsSelected.data());
                    multi.bind('change', that.onMultiChange);
                    listview.bind('change', that.onListChange);
                    break;
                case 'entityeditlist':
                    var availableEditListOptions = {
                        height: that._gridsHeight,
                        entityTypeName: that._entityTypeName,
                        columns: that._tempColumns
                    };

                    var selectedEditListOptions = $.extend({}, availableEditListOptions);

                    that._assignBooleanSettings(availableEditListOptions, selectedEditListOptions);

                    $(that.availableItemsWidgetSelector).kendoEntityEditList(availableEditListOptions);

                    if (that._tempSelectedColumns) {
                        selectedEditListOptions.columns = that._tempSelectedColumns;
                    }

                    if (that._selectedEntitiesTypeName) {
                        selectedEditListOptions.entityTypeName = that._selectedEntitiesTypeName;
                    }

                    $(that.selectedItemsWidgetSelector).kendoEntityEditList(selectedEditListOptions);

                    that.setButtonContainerPosition();
                    that._bindFilterEvent();
                    break;
                default:
                    available = kendo.widgetInstance($(that.availableItemsWidgetSelector));
                    selected = kendo.widgetInstance($(that.selectedItemsWidgetSelector));

                    var availableOptions = { columns: that._tempColumns, height: that._gridsHeight },
                        selectedOptions = $.extend({}, availableOptions);

                    if (that._tempSelectedColumns) {
                        selectedOptions.columns = that._tempSelectedColumns;
                    }

                    that._assignBooleanSettings(availableOptions, selectedOptions);

                    if (available) { available.setOptions(availableOptions); }
                    if (selected) { selected.setOptions(selectedOptions); }

                    that.setButtonContainerPosition();
                    that._bindFilterEvent();
            }
        };

        //Override function from ViewModelBase
        ItemSelectorViewModel.prototype.setValue = function (key, value) {
            var that = this;
            switch (key) {
                case 'selectedItems':
                    that.setSelectedItems(value);
                    break;
                case 'unselectedItems':
                    that.setUnselectedItems(value);
                    break;
                case 'options':
                    that.setOptions(value);
                    break;
                case 'beforeSelectedItemsHandler':
                    that._beforeSelectedItemsHandler = value;
                    break;
                case 'beforeUnselectedItemsHandler':
                    that._beforeUnselectedItemsHandler = value;
                    break;
                case 'beforeSelectedItemsAsyncHandler':
                    that._beforeSelectedItemsAsyncHandler = value;
                    break;
                case 'beforeUnselectedItemsAsyncHandler':
                    that._beforeUnselectedItemsAsyncHandler = value;
                    break;
                case 'height':
                    that._setHeight(value);
                    break;
                case 'enable':
                    that.enable(value);
                    break;
                //no default
            }
        };

        ItemSelectorViewModel.prototype.setHeight = function (newHeight) {
            var that = this;

            that.set('_gridsHeight', newHeight);

            var gridContentSelector = '.k-grid-content';
            var available = $(that.availableItemsWidgetSelector);
            var selected = $(that.selectedItemsWidgetSelector);
            var buttonContainer = $(that.buttonContainerSelector);

            available.height(newHeight);
            selected.height(newHeight);

            updateCoverDiv(that.widgetId, 'available', available);
            updateCoverDiv(that.widgetId, 'selected', selected);
            updateCoverDiv(that.widgetId, 'ButtonContainer', buttonContainer);

            var availableChildrenElements = available.children().not(gridContentSelector);
            var selectedChildrenElements = selected.children().not(gridContentSelector);
            var availableChildrenElementsHeight = 0;
            var selectedChildrenElementsHeight = 0;

            availableChildrenElements.each(function () {
                availableChildrenElementsHeight += $(this).outerHeight();
            });

            selectedChildrenElements.each(function () {
                selectedChildrenElementsHeight += $(this).outerHeight();
            });

            available.children(gridContentSelector).height(newHeight - availableChildrenElementsHeight);
            selected.children(gridContentSelector).height(newHeight - selectedChildrenElementsHeight);

            that.setButtonContainerPosition();
        };

        ItemSelectorViewModel.prototype.enable = function (toggle) {
            var that = this;

            this.set('_gridsEnableValue', toggle);

            that._enableAvailableItemList(toggle);
            that._enableSelectedItemList(toggle);
            that._enableButtonContainer(toggle);
        };

        ItemSelectorViewModel.prototype.disposeViewModel = function () {
            var that = this;
            var filterEventName = 'filter';
            that._unbindWidgetEvent(that.availableItemsWidgetSelector, filterEventName, that._handleFilterEvent);
            that._unbindWidgetEvent(that.selectedItemsWidgetSelector, filterEventName, that._handleFilterEvent);
        };

        ItemSelectorViewModel.prototype._unbindWidgetEvent = function (widgetSelector, eventName, eventHandler) {
            var widget = kendo.widgetInstance($(widgetSelector));
            if (widget) {
                widget.unbind(eventName, eventHandler);
            }
        };

        ItemSelectorViewModel.prototype.afterViewIsDisplayed = function () {
            var that = this;

            that._assignAdditionalAttributes();
            that.adjustLayout();

            if (that._availableItemListEnabled === false) {
                that._enableAvailableItemList(that._gridsEnableValue);
            }

            if (that._selectedItemListEnabled === false) {
                that._enableSelectedItemList(that._gridsEnableValue);
            }

            if (that._buttonContainerEnabled === false) {
                that._enableButtonContainer(that._gridsEnableValue);
            }
        };

        ItemSelectorViewModel.prototype.setUnselectedItems = function (items) {
            var that = this;
            this.set('items', items);
            if (this.get('items')) {
                that.itemsUnselectedChangeHandler = this.get('items').bind('change', $.proxy(that.itemsChange, that));
                this.get('items').read();
            }
        };

        ItemSelectorViewModel.prototype._bindFilterEvent = function () {
            var that = this;
            var timeout = 500;
            setTimeout(function () {
                var eventName = 'filter';
                var availableItemsWidget = kendo.widgetInstance($(that.availableItemsWidgetSelector));
                var selectedItemsWidget = kendo.widgetInstance($(that.selectedItemsWidgetSelector));

                var proxyFunction = $.proxy(that._handleFilterEvent, that);
                availableItemsWidget.unbind(eventName, proxyFunction);
                availableItemsWidget.bind(eventName, proxyFunction);
                selectedItemsWidget.unbind(eventName, proxyFunction);
                selectedItemsWidget.bind(eventName, proxyFunction);
            }, timeout);
        };

        ItemSelectorViewModel.prototype._handleFilterEvent = function (event) {
            //Solves issue that causes grid to reload its data when the user clicks the "clear" button in the filter UI.
            var dataSource = event.sender.dataSource;
            if (dataSource.getFilterDeleteModeEntities && !dataSource.getFilterDeleteModeEntities()) {
                if (dataSource.view().length === 0 && dataSource.data().length === 0) {
                    event.preventDefault();
                }
            }
            var that = this;
            var timeout = 500;
            setTimeout(function () {
                that.updateEnabledButtons();
            }, timeout);
        };

        ItemSelectorViewModel.prototype._enableAvailableItemList = function (toggle) {
            var that = this;
            that._availableItemListEnabled = setEnable(that.widgetId, that.availableItemsWidgetSelector, 'available', toggle);
        };

        ItemSelectorViewModel.prototype._enableSelectedItemList = function (toggle) {
            var that = this;
            that._selectedItemListEnabled = setEnable(that.widgetId, that.selectedItemsWidgetSelector, 'selected', toggle);
        };

        ItemSelectorViewModel.prototype._enableButtonContainer = function (toggle) {
            var that = this;
            that._buttonContainerEnabled = setEnable(that.widgetId, that.buttonContainerSelector, 'ButtonContainer', toggle);
        };

        ItemSelectorViewModel.prototype._beforeItemUnselection = function (items, allItemsUnselected) {
            return this._beforeSelection(this._beforeUnselectedItemsHandler, items, allItemsUnselected);
        };

        ItemSelectorViewModel.prototype._beforeItemSelection = function (items, allItemsSelected) {
            return this._beforeSelection(this._beforeSelectedItemsHandler, items, allItemsSelected);
        };

        ItemSelectorViewModel.prototype._beforeItemUnselectionAsync = function (items, allItemsUnselected, callback) {
            return this._beforeSelectionAsync(this._beforeUnselectedItemsAsyncHandler, items, allItemsUnselected, callback);
        };

        ItemSelectorViewModel.prototype._beforeItemSelectionAsync = function (items, allItemsSelected, callback) {
            return this._beforeSelectionAsync(this._beforeSelectedItemsAsyncHandler, items, allItemsSelected, callback);
        };

        ItemSelectorViewModel.prototype._beforeSelection = function (handler, items, allItemsIncluded) {
            var result = {
                cancel: false,
                items: items
            };
            if (handler) {
                var parameter = {
                    items: items,
                    allItems: allItemsIncluded
                };
                result = handler(parameter);

                if (!result) { result = {}; }

                if (!result.items) {
                    result.items = items;
                }
            }
            return result;
        };

        ItemSelectorViewModel.prototype._beforeSelectionAsync = function (handler, items, allItemsIncluded, callback) {
            if (handler) {
                var parameter = {
                    items: items,
                    allItems: allItemsIncluded,
                    callback: callback
                };

                handler(parameter);

                return true;
            }

            return false;
        };

        ItemSelectorViewModel.prototype.select = function (event) {
            var that = this;

            this.get('itemsSelected').unbind('change', that.itemsSelectedChangeHandler);
            this.get('items').unbind('change', that.itemsUnselectedChangeHandler);

            var available = kendo.widgetInstance($(that.availableItemsWidgetSelector)),
                sourceWidgetItems = available.select();

            var sourceItems = $.map(sourceWidgetItems, function (element) {
                if (element.className.indexOf('k-grouping-row') > -1) { return null; }
                return available.dataItem(element);
            });

            var successfulAsyncHandler = that._beforeItemSelectionAsync(sourceItems, true, function (response, error) {

                response = normalizeBeforeSelectUnSelectCallsResponse(response, error, sourceItems);

                that._endSelect(event, response, sourceItems);
            });

            if (successfulAsyncHandler === false) {
                var result = that._beforeItemSelection(sourceItems, false);
                that._endSelect(event, result, sourceItems);
            }
        };

        ItemSelectorViewModel.prototype.unselect = function (event) {
            var that = this;

            this.get('itemsSelected').unbind('change', that.itemsSelectedChangeHandler);
            this.get('items').unbind('change', that.itemsUnselectedChangeHandler);

            var selected = kendo.widgetInstance($(that.selectedItemsWidgetSelector)),
                sourceWidgetItems = selected.select();

            var sourceItems = $.map(sourceWidgetItems, function (element) {
                if (element.className.indexOf('k-grouping-row') > -1) { return null; }
                return selected.dataItem(element);
            });

            var successfulAsyncHandler = that._beforeItemUnselectionAsync(sourceItems, false, function (response, error) {

                response = normalizeBeforeSelectUnSelectCallsResponse(response, error, sourceItems);

                that._endUnSelect(event, response, sourceItems);
            });

            if (successfulAsyncHandler === false) {
                var result = that._beforeItemUnselection(sourceItems, false);
                that._endUnSelect(event, result, sourceItems);
            }
        };

        ItemSelectorViewModel.prototype.selectAllItems = function (event) {
            var that = this;

            this.get('itemsSelected').unbind('change', that.itemsSelectedChangeHandler);
            this.get('items').unbind('change', that.itemsUnselectedChangeHandler);

            var available = kendo.widgetInstance($(that.availableItemsWidgetSelector));
            var sourceItems = that._getItems(available);
            var sourceItemsCopy = sourceItems.slice(0);

            var successfulAsyncHandler = that._beforeItemSelectionAsync(sourceItems, true, function (response, error) {

                response = normalizeBeforeSelectUnSelectCallsResponse(response, error, sourceItemsCopy);

                that._endSelectAllItems(event, response, sourceItemsCopy);
            });

            if (successfulAsyncHandler === false) {
                var result = that._beforeItemSelection(sourceItems, true);
                that._endSelectAllItems(event, result, sourceItemsCopy);
            }
        };

        ItemSelectorViewModel.prototype.unselectAll = function (event) {
            var that = this;

            this.get('itemsSelected').unbind('change', that.itemsSelectedChangeHandler);
            this.get('items').unbind('change', that.itemsUnselectedChangeHandler);

            var selected = kendo.widgetInstance($(that.selectedItemsWidgetSelector));
            var sourceItems = selected.dataItems();

            var successfulAsyncHandler = that._beforeItemUnselectionAsync(sourceItems, true, function (response, error) {

                response = normalizeBeforeSelectUnSelectCallsResponse(response, error, sourceItems);

                that._endUnSelectAll(event, response, sourceItems, selected);
            });

            if (successfulAsyncHandler === false) {
                var result = that._beforeItemUnselection(sourceItems, true);
                that._endUnSelectAll(event, result, sourceItems, selected);
            }
        };

        ItemSelectorViewModel.prototype._endSelect = function (event, result, sourceItems) {
            var that = this;

            if (result.cancel) {
                return;
            }
            var destinationItems = result.items;

            var currentAvailableItems = that.items.data();
            var currentSelectedItems = that.itemsSelected.data();

            that._changeEditMode(destinationItems, enums.editModes.deleteMode, enums.editModes.update);

            //Add the current selected items to destinationItems array.
            for (var currentSelectedIndex = currentSelectedItems.length - 1;
                currentSelectedIndex >= 0;
                currentSelectedIndex--) {
                var selectedItem = currentSelectedItems[currentSelectedIndex];
                if (destinationItems.indexOf(selectedItem) === -1) {
                    destinationItems.unshift(selectedItem);
                }
            }

            var currentAvailableItemsArray = [];
            //Create the array with the availables items.
            for (var currentAvailableIndex = 0;
                currentAvailableIndex < currentAvailableItems.length;
                currentAvailableIndex++) {
                var availableItem = currentAvailableItems[currentAvailableIndex];
                if (sourceItems.indexOf(availableItem) === -1) {
                    currentAvailableItemsArray.push(availableItem);
                }
            }

            var currentFilter = that.itemsSelected.filter();
            that.itemsSelected.filter(currentFilter);

            that.itemsSelected.data(destinationItems);
            that.items.data(currentAvailableItemsArray);
            that.itemsChange();

            that.itemsSelectedChangeHandler = this.get('itemsSelected').bind('change', $.proxy(that.itemsChange, that));
            that.itemsUnselectedChangeHandler = this.get('items').bind('change', $.proxy(that.itemsChange, that));

            that.trigger(selectEvent, { allItems: false, items: that.itemsSelected.data() });
            that.set('selectEnable', false);
            that.set('unselectEnable', false);
            that._focusNextElement(event.sender);
        };

        ItemSelectorViewModel.prototype._endSelectAllItems = function (event, result, sourceItemsCopy) {
            var that = this;

            if (result.cancel) { return; }

            var destinationItems = result.items;
            var currentSelectedItems = that.itemsSelected.data();

            that._changeEditMode(destinationItems, enums.editModes.deleteMode, enums.editModes.update);

            for (var step = currentSelectedItems.length - 1; step >= 0; step--) {
                var selectedItem = currentSelectedItems[step];
                if (destinationItems.indexOf(selectedItem) === -1) {
                    destinationItems.unshift(currentSelectedItems[step]);
                }
            }

            var currentFilter = that.itemsSelected.filter();
            that.itemsSelected.filter(currentFilter);
            that.itemsSelected.data(destinationItems);

            /* We are checking if there are any remaining elements in the items dataSource 
             * that were not selected because they are not visible. For instance, elements removed by a temp filter*/
            var availableItems = [];
            if (sourceItemsCopy.length < that.items.data().length) {
                var remainingAvailableItems = that.items.data();
                for (var index = 0; index < remainingAvailableItems.length; index++) {
                    var currentRemainingItem = remainingAvailableItems[index];
                    if (sourceItemsCopy.indexOf(currentRemainingItem) < 0) {
                        availableItems.push(currentRemainingItem);
                    }
                }
            }

            that.items.data(availableItems);
            that.itemsChange();

            that.itemsSelectedChangeHandler = this.get('itemsSelected').bind('change', $.proxy(that.itemsChange, that));
            that.itemsUnselectedChangeHandler = this.get('items').bind('change', $.proxy(that.itemsChange, that));

            that.trigger(selectEvent, { allItems: true, items: that.itemsSelected.data() });
            that._focusNextElement(event.sender);
        };

        ItemSelectorViewModel.prototype._endUnSelect = function (event, result, sourceItems) {
            var that = this;

            if (result.cancel) { return; }
            var destinationItems = result.items;

            var currentAvailableItems = that.items.data();
            var currentSelectedItems = that.itemsSelected.data();

            var selectedItemsArray = [];
            //Create an array with the selected items
            for (var index = 0; index < currentSelectedItems.length; index++) {
                var selectedItem = currentSelectedItems[index];
                var foundSelectedItem = sourceItems.indexOf(selectedItem) > -1;
                var pushToArray = false;

                if (!foundSelectedItem) {
                    pushToArray = true;
                } else {
                    if (this.changeEditMode) {
                        var entityMetadata = selectedItem.getEntityMetadata();
                        var editMode = entityMetadata.getEditMode();

                        if (editMode === editModes.update) {
                            entityMetadata.setEditMode(enums.editModes.deleteMode);
                            pushToArray = true;
                        }
                    }
                }

                if (pushToArray) {
                    selectedItemsArray.push(selectedItem);
                }
            }

            //Add the available items to the destination items array.
            for (var step = currentAvailableItems.length - 1; step >= 0; step--) {
                destinationItems.unshift(currentAvailableItems[step]);
            }

            var currentFilter = that.itemsSelected.filter();
            that.itemsSelected.filter(currentFilter);
            that.itemsSelected.data(selectedItemsArray);
            that.items.data(destinationItems);

            that.itemsChange();

            that.itemsSelectedChangeHandler = this.get('itemsSelected').bind('change', $.proxy(that.itemsChange, that));
            that.itemsUnselectedChangeHandler = this.get('items').bind('change', $.proxy(that.itemsChange, that));

            that.trigger(unselectEvent, { allItems: false, items: that.items.data() });
            that.set('selectEnable', false);
            that.set('unselectEnable', false);
            that._focusNextElement(event.sender);
        };

        ItemSelectorViewModel.prototype._endUnSelectAll = function (event, result, sourceItems, selectedItemsWidget) {
            var that = this;

            if (result.cancel) { return; }
            var destinationItems = result.items;

            var currentAvailableItems = that.items.data();
            var currentSelectedItems = that._getItems(selectedItemsWidget);

            var selectedItemsArray = [];
            for (var index = 0; index < currentSelectedItems.length; index++) {
                var selectedItem = currentSelectedItems[index];
                var foundSelectedItem = sourceItems.indexOf(selectedItem) > -1;
                var pushToArray = false;

                if (!foundSelectedItem) {
                    pushToArray = true;
                } else {
                    if (this.changeEditMode) {
                        var entityMetadata = selectedItem.getEntityMetadata();
                        var editMode = entityMetadata.getEditMode();

                        if (editMode !== editModes.insert) {
                            entityMetadata.setEditMode(enums.editModes.deleteMode);
                            pushToArray = true;
                        }
                    }
                }

                if (pushToArray) {
                    selectedItemsArray.push(selectedItem);
                }
            }

            /* We are checking if there are any remaining elements in the items dataSource 
             * that were not selected because they are not visible. For instance, elements removed by a temp filter*/
            if (currentSelectedItems.length < that.itemsSelected.data().length) {
                var remainingSelectedItems = that.itemsSelected.data();
                for (var itemIndex = 0; itemIndex < remainingSelectedItems.length; itemIndex++) {
                    var currentRemainingItem = remainingSelectedItems[itemIndex];
                    if (currentSelectedItems.indexOf(currentRemainingItem) < 0) {
                        selectedItemsArray.push(currentRemainingItem);
                    }
                }
            }

            for (var step = currentAvailableItems.length - 1; step >= 0; step--) {
                destinationItems.push(currentAvailableItems[step]);
            }

            var currentFilter = that.itemsSelected.filter();
            that.itemsSelected.filter(currentFilter);

            that.itemsSelected.data(selectedItemsArray);
            that.items.data(destinationItems);
            that.itemsChange();

            that.itemsSelectedChangeHandler = this.get('itemsSelected').bind('change', $.proxy(that.itemsChange, that));
            that.itemsUnselectedChangeHandler = this.get('items').bind('change', $.proxy(that.itemsChange, that));

            that.trigger(unselectEvent, { allItems: true, items: that.items.data() });
            that._focusNextElement(event.sender);
        };

        ItemSelectorViewModel.prototype._getItems = function (widget) {
            var dataSource = widget.dataSource;

            if (dataSource.group().length === 0) {
                return dataSource.view();
            }

            var result = [];
            //Iterate over groups to get items.
            var groups = dataSource.view();
            for (var index = 0; index < groups.length; index++) {
                var groupItems = groups[index].items;
                for (var groupIndex = 0; groupIndex < groupItems.length; groupIndex++) {
                    result.push(groupItems[groupIndex]);
                }
            }
            return result;
        };

        ItemSelectorViewModel.prototype._focusNextElement = function (currentButtonWithFocus) {
            if (this._navigatable) {
                var element = currentButtonWithFocus.element;
                var index = element.closest('li').index(),
                    lastIndex = 3,
                    secondLastIndex = 2,
                    itemIndex = 0;
                var buttonList = element.closest('ul');
                var listItems;
                var foundElementToFocus = false;
                var listItem;
                if (index >= 0 && index <= secondLastIndex) {
                    //Find next enabled button
                    listItems = buttonList.children('li:gt(' + index + ')');
                    for (itemIndex = 0; itemIndex < listItems.length && !foundElementToFocus; itemIndex++) {
                        listItem = $(listItems[itemIndex]);
                        if (listItem.find('button:enabled').length > 0) {
                            listItem.find('button:enabled').focus();
                            foundElementToFocus = true;
                        }
                    }
                } else if (index === lastIndex) {
                    //This is the last button
                    //Assign focus to the selected elements datasource if there are elements there.
                    //Otherwise, assign focus to the previous enabled button
                    if (this.get('itemsSelected').view().length > 0) {
                        var widget = this.getSelectedItemsWidget();
                        if (widget.table) {
                            widget.table.focus();
                            foundElementToFocus = true;
                        }
                    }
                }

                if (!foundElementToFocus) {
                    listItems = buttonList.children('li:lt(' + lastIndex + ')');

                    for (itemIndex = secondLastIndex; itemIndex >= 0; itemIndex--) {
                        listItem = $(listItems[itemIndex]);
                        if (listItem.find('button:enabled').length > 0) {
                            listItem.find('button:enabled').focus();
                        }
                    }
                }
            }
        };

        ItemSelectorViewModel.prototype.getSelectedItemsWidget = function () {
            return kendo.widgetInstance($(this.selectedItemsWidgetSelector));
        };

        ItemSelectorViewModel.prototype.getUnselectedItemsWidget = function () {
            return kendo.widgetInstance($(this.availableItemsWidgetSelector));
        };

        ItemSelectorViewModel.prototype._changeEditMode = function (entityArray, editModeToValidate, newEditMode) {
            if (this.changeEditMode) {
                for (var destinationIndex = 0; destinationIndex < entityArray.length; destinationIndex++) {
                    var destinationItem = entityArray[destinationIndex];

                    var entityMetadata = destinationItem.getEntityMetadata();
                    if (entityMetadata.getEditMode() === editModeToValidate) {
                        entityMetadata.setEditMode(newEditMode);
                    }
                }
            }
        };

        //#region DataBound events

        ItemSelectorViewModel.prototype.handleSelectedItemsDataBound = function () {
            this.trigger(dataBoundEvent, { sourceContainer: 'selectedItems' });
        };

        ItemSelectorViewModel.prototype.handleUnselectedItemsDataBound = function () {
            this.trigger(dataBoundEvent, { sourceContainer: 'unselectedItems' });
        };

        //#endregion

        //functions for toggle mode

        ItemSelectorViewModel.prototype.onMultiChange = function () {
            var viewModel = this.options.viewModel;
            var selected = this.value();
            viewModel._setAtSources(selected);
            viewModel._selectAtList(viewModel.get('itemsSelected'));
        };

        ItemSelectorViewModel.prototype.onListChange = function () {

            var viewModel = this.options.viewModel;
            if (viewModel.flag) { return; }
            var array = [];
            var data = viewModel.allItems.view();
            $.map(this.select(), function (item) {
                array.push(data[$(item).index()]);
            });
            viewModel.set('itemsSelected', array);
            viewModel._selectAtMulti(viewModel.get('itemsSelected'));
        };

        ItemSelectorViewModel.prototype._setAtSources = function (selected) {
            var that = this, array = [];
            for (var step = 0; step < that.allItems.data().length; step++) {
                for (var stepb = 0; stepb < selected.length; stepb++) {
                    if (that.allItems.data()[step][this._tempValueField] === selected[stepb]) {
                        array.push(that.allItems.data()[step]);
                    }
                }
            }
            that.set('itemsSelected', array);
        };

        ItemSelectorViewModel.prototype._selectAtMulti = function (selectedValues) {
            var array = [], id = this._tempValueField;
            for (var step = 0; step < this.allItems.data().length; step++) {
                for (var stepb = 0; stepb < selectedValues.length; stepb++) {
                    if (this.allItems.data()[step][id] === selectedValues[stepb][id]) {
                        array.push(this.allItems.data()[step][id]);
                    }
                }
            }
            var multi = $('#' + this.widgetId + ' select.multiselect').data('kendoMultiSelect');
            multi.value(array);
            this._setDiff();

        };

        ItemSelectorViewModel.prototype._setDiff = function () {
            var array1 = this.itemsSelected;
            var array2 = this.allItems.data();
            var foo = [];
            var step = 0;
            //Avoid eslint error.
            $.noop(step);
            var con = -1;
            $.grep(array2, function (el) {
                if ($.inArray(el, array1) === con) { foo.push(el); }
                step++;
            });
            this.set('items', foo);
        };

        ItemSelectorViewModel.prototype._selectAtList = function (selectedValues) {
            this.set('flag', true);
            var listView = $('#' + this.widgetId + ' .m-listview-selector').data('kendoListView');
            listView.clearSelection();
            var data = this.allItems.view();
            for (var step = data.length - 1; step >= 0; step--) {
                for (var stepb = selectedValues.length - 1; stepb >= 0; stepb--) {
                    if (selectedValues[stepb][this._tempValueField] === data[step][this._tempValueField]) {
                        listView.select(listView.element.children()[step]);
                    }
                }
            }
            this.set('flag', false);
            this._setDiff();
        };

        ItemSelectorViewModel.prototype.selectAll = function () {
            this.set('itemsSelected', this.allItems.data());
            this._selectAtMulti(this.allItems.data());
            this._selectAtList(this.allItems.data());
        };

        ItemSelectorViewModel.prototype.selectNone = function () {
            this.set('itemsSelected', []);
            this._selectAtMulti([]);
            this._selectAtList([]);
        };

        ItemSelectorViewModel.prototype._translateTitle = function (title) {
            var translation = this.translate(title);
            if (!translation) {
                translation = title;
            }
            return translation;
        };

        ItemSelectorViewModel.prototype._assignAdditionalAttributes = function () {
            var that = this;
            //the additional attributes will be passed to a hidden input in the view.
            //this helps with custom Kendo validations that require an input tag.
            if (that._additionalAttributes) {
                var inputElement = $('#' + that.widgetId + ' input:hidden');
                for (var attributeName in that._additionalAttributes) {
                    if (that._additionalAttributes.hasOwnProperty(attributeName)) {
                        var attributeValue = that._additionalAttributes[attributeName];
                        inputElement.attr(attributeName, attributeValue);
                        if (attributeName === 'name') {
                            var invalidMessage = $('#' + that.widgetId + ' .k-invalid-msg');
                            if (invalidMessage && invalidMessage.length > 0) {
                                invalidMessage.attr('data-for', attributeValue);
                            }
                        }
                    }
                }
            }
        };

        //Function used by grid and EEL styles
        ItemSelectorViewModel.prototype._assignBooleanSettings = function (availableOptions, selectedOptions) {
            /*eslint-disable no-param-reassign*/
            var that = this;

            for (var index = 0; index < booleanSettings.length; index++) {
                var setting = booleanSettings[index],
                    property = '_' + setting,
                    selectedItemsProperty = '_selectedItems' + capitalizeFirstLetter(setting);
                if ((property in that) && (that[property] !== null)) {
                    availableOptions[setting] = that[property];
                    selectedOptions[setting] = that[property];
                }
                if ((selectedItemsProperty in that) && (that[selectedItemsProperty] !== null)) {
                    selectedOptions[setting] = that[selectedItemsProperty];
                }
            }
            /*eslint-enable no-param-reassign*/
        };

        var initializeForView = function (callback) {
            var itemSelectorViewModel = new ItemSelectorViewModel();
            util.notify(callback, itemSelectorViewModel, null);
        };

        return {
            initializeForView: initializeForView
        };

    }
);
