define('metadataCache',
    ['jquery', 'RESTActivityWebServiceProxy', 'RESTSecurityWebServiceProxy', 'clientCache', 'util', 'enums'],
    function ($, activityServiceProxy, securityServiceProxy, clientCache, modularisUtil, enums) {
        'use strict';

        var getEntityDefDefaultOptions = {
            callback: null,
            entityDefId: null,
            includePropertyDefs: false
        };

        var metadataCache = (function () {

            var entityDefs = [];
            var moduleGroups = [];

            var addEntityDef = function (entityDef) {
                entityDefs.push(entityDef);
            };

            var addPropertyDef = function (entityDefId, propertyDef) {
                var entityDef = getEntityDefFromCache(entityDefId);
                if (entityDef !== null) {
                    entityDef.PropertyDefs.Items.push(propertyDef);
                }
            };

            var getEntityDefFromCache = function (entityDefId) {
                var entityDef;
                for (var index = 0; index < entityDefs.length; index++) {
                    entityDef = entityDefs[index];
                    if (entityDef.EntityDefID === entityDefId) {
                        return entityDef;
                    }
                }
                return null;
            };

            var getEntityDefFromServer = function (entityDefId, selectedEntities, callbak) {

                var getChildren = Boolean(selectedEntities);

                var options = {};
                options[enums.options.convertToJavaScriptEntity] = false;

                if (selectedEntities) {
                    options[enums.options.selectedEntities] = selectedEntities;
                }

                activityServiceProxy.getByKey('Modularis.Metadata.Entity.EntityDef', entityDefId, getChildren, options, callbak);

            };

            var getPropertyDefFromEntityDef = function (entityDef, propertyName) {
                if (entityDef == null || entityDef.PropertyDefs == null || !modularisUtil.isDefined(entityDef.PropertyDefs.Items)) {
                    return null;
                }

                var propertyDefs = entityDef.PropertyDefs.Items;
                if (propertyDefs.length > 0) {
                    for (var index = 0; index < propertyDefs.length; index++) {
                        var propertyDef = propertyDefs[index];
                        if (propertyDef.PropertyName === propertyName) {
                            return propertyDef;
                        }
                    }
                }
                return null;
            };

            var getModuleGroupsFromServer = function (callback) {
                securityServiceProxy.getAvailableModules(callback);
            };

            securityServiceProxy.bind(enums.securityEventName.logOff, function () {
                //Clean up variables
                entityDefs = [];
                moduleGroups = [];
            });

            return {

                getEntityDef: function (getEntityDefOptions) {

                    var options = {};
                    $.extend(options, getEntityDefDefaultOptions, getEntityDefOptions);

                    var callback = options.callback,
                       entityDefId = options.entityDefId,
                       includePropertyDefs = options.includePropertyDefs,
                       includeEntityDefSettings = options.includeEntityDefSettings;


                    var entityDef = getEntityDefFromCache(entityDefId);
                    if (entityDef != null) {
                        modularisUtil.notify(callback, entityDef, null);
                        return;
                    }

                    var entitiesToInclude = [];

                    if (includePropertyDefs) {
                        entitiesToInclude.push('PropertyDefs');
                    }

                    if (includeEntityDefSettings) {
                        entitiesToInclude.push('EntityDefSettings');
                    }

                    var selectedEntities = String.format('[EntityDef:{0}]', entitiesToInclude.join());

                    getEntityDefFromServer(entityDefId, selectedEntities, function (entityDef, error) {
                        var responseObject = null;

                        if (modularisUtil.success(entityDef, error)) {
                            responseObject = entityDef;
                            //Add to cache.
                            addEntityDef(responseObject);
                        }

                        modularisUtil.notify(callback, responseObject, error);
                    });

                },

                getPropertyDef: function (entityDefId, propertyName, callback) {
                    var entityDef = getEntityDefFromCache(entityDefId);
                    var propertyDef = null;
                    var entiyDefInCache = false;
                    if (entityDef !== null) {
                        entiyDefInCache = true;
                        propertyDef = getPropertyDefFromEntityDef(entityDef, propertyName);
                        if (propertyDef !== null) {
                            modularisUtil.notify(callback, propertyDef, null);
                            return;
                        }
                    }

                    if (propertyDef === null) {
                        var where = '<<PropertyDef.EntityDefID>> = ? AND <<PropertyDef.PropertyName>> = ?';
                        var parameterValues = [entityDefId, propertyName];
                        var options = {};
                        options[enums.options.convertToJavaScriptEntity] = false;
                        var entityTypeName = 'Modularis.Metadata.Entity.PropertyDef';
                        var getChildren = false;
                        if (!entiyDefInCache) {
                            where = '<<EntityDef.EntityDefID>> = ? AND <<EntityDef.EntityDefID>> = <<PropertyDef.EntityDefID>> AND <<PropertyDef.PropertyName>> = ?';
                            options[enums.options.selectedEntities] = '[EntityDef:PropertyDefs]';
                            entityTypeName = 'Modularis.Metadata.Entity.EntityDef';
                            getChildren = true;
                        }
                        activityServiceProxy.getCollectionByWhere(entityTypeName, where, parameterValues, getChildren, options,
                            function (data, error) {
                                var responseObject = null;

                                if (modularisUtil.success(data, error)) {
                                    if (entiyDefInCache) {
                                        var responsePropertyDef = data.Items[0];
                                        responseObject = responsePropertyDef;
                                        addPropertyDef(entityDefId, responsePropertyDef);
                                    } else {
                                        var responseEntityDef = data.Items[0];
                                        responseObject = getPropertyDefFromEntityDef(responseEntityDef, propertyName);
                                        addEntityDef(responseEntityDef);
                                    }
                                }

                                modularisUtil.notify(callback, responseObject, error);
                            }
                        );
                    }
                },

                getModuleGroups: function (callback) {
                    if (moduleGroups.length > 0) {
                        modularisUtil.notify(callback, moduleGroups, null);
                        return;
                    }

                    getModuleGroupsFromServer(function (foundModuleGroups, error) {
                        var responseObject = null;

                        if (modularisUtil.isSuccessfulResponse(foundModuleGroups, error)) {
                            responseObject = foundModuleGroups;
                            moduleGroups = foundModuleGroups;
                        }

                        modularisUtil.notify(callback, responseObject, error);
                    });

                }
            };

        })();

        return metadataCache;
    }
);
