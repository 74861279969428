define('proxyHelper',
   ['logger', 'util'],
   function (logger, util) {
       /*eslint-env es6 */
       'use strict';

       var proxyHelper = {

           _getIntValueFromBinaryArray: function (array, start, end) {

               var bytesAsArrayBuffer = array.buffer.slice(start, end);
               var intValue = new Int32Array(bytesAsArrayBuffer)[0];
               return intValue;

           },

           _uint8ArrayToString: function (array) {

               //Function based on http://stackoverflow.com/a/12713326

               var chunkSize = 0x8000;
               var result = [];
               for (var index = 0; index < array.length; index += chunkSize) {
                   result.push(String.fromCharCode.apply(null, array.subarray(index, index + chunkSize)));
               }
               return result.join('');

               //return String.fromCharCode.apply(null, array);

           },

           processBinaryMessage: function (binaryMessage) {

               var that = this;

               var messageCollection = { Items: [] };
               var data = new Uint8Array(binaryMessage);

               var index = 0,
                  totalLength = data.length,
                  eight = 8,
                  four = 4;

               try {
                   while (totalLength > 0) {

                       //var messageLength = data.subarray(index, index + eight);
                       var messageLength = new Uint8Array(data.buffer.slice(index, index + eight));
                       var length = that._getIntValueFromBinaryArray(messageLength, 0, four);

                       var dataLength = that._getIntValueFromBinaryArray(messageLength, four, eight);

                       //var encodedMessage = data.subarray(index + eight, index + eight + length);
                       var encodedMessage = new Uint8Array(data.buffer.slice(index + eight, index + eight + length));

                       var messageString = that._uint8ArrayToString(encodedMessage);
                       var message = JSON.parse(messageString);

                       if (dataLength > 0) {
                           //message.Data = data.subarray(index + eight + length, index + eight + length + dataLength);
                           message.Data = new Uint8Array(data.buffer.slice(index + eight + length, index + eight + length + dataLength));
                       }

                       messageCollection.Items.push(message);

                       index += (eight + length + dataLength);
                       totalLength -= (eight + length + dataLength);

                   }
               } catch (parsingError) {
                   logger.error('Exception parsing binary message. Description: ' + parsingError.description);
               }

               return messageCollection;
           },

           changeFirstLetterToLowerCase: function (object) {
               var newParams = {};

               for (var propertyName in object) {
                   if (object.hasOwnProperty(propertyName)) {
                       var newPropertyName = propertyName[0].toLowerCase() + propertyName.substring(1);
                       newParams[newPropertyName] = object[propertyName];
                   }
               }

               return newParams;
           },

           handleServerConfigurationException: function (activeServer, callback) {
               var result = false;

               if (!activeServer) {
                   var exceptionMessage = 'An error occurred with the server configuration. Please check the configuration file.';

                   logger.error(exceptionMessage);
                   util.notify(callback, null, new Error(exceptionMessage));
                   result = true;
               }

               return result;
           }

       };
       return proxyHelper;

   }
);
