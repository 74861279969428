define('modularis/entity/notification/notificationMessage',
    ['jquery', 'modularis/entity/common', 'entityBase', 'entityCollectionBase', 'enums'],
    function ($, modelCommon, baseEntity, entityCollectionBase, enums) {
        'use strict';
        var types = enums.propertyTypes;
        var propertyDefs = [
            { name: 'MessageID', type: types.typeGuid },
            { name: 'CustomMessageType', type: types.typeString },
            { name: 'MessageBody', type: types.typeString },
            { name: 'MessageObject', type: types.typeObject },
            { name: 'MessageType', type: types.typeInt32 },
            { name: 'Sender', type: types.typeString },
            { name: 'Target', type: types.typeString },
            { name: 'Data', type: types.typeBLOB },
            { name: 'Timeout', type: types.typeInt32 }
        ];

        var assemblyName = modelCommon.AssemblyName;
        var defaultEntitySerializationMetadata = {
            AssemblyName: assemblyName,
            Culture: null,
            Name: 'NotificationMessage',
            Type: 'Entity',
            AllowNulls: false,
            Dirty: true,
            EditMode: enums.editModes.insert,
            Valid: true,
            Attributes: [],
            Errors: []
        };

        var EntityBase = baseEntity.EntityBase;
        var EntityCollectionBase = entityCollectionBase.EntityCollectionBase;

        /**
         * @classdesc NotificationMessage entity class.
         * @constructs modularis.entity.notification.NotificationMessage
         * 
         * @property {string} MessageID - NotificationMessage ID.
         * @property {string} CustomMessageType - Custom Message Type.
         * @property {string} MessageBody - Message Body.
         * @property {Object} MessageObject - Message Object.
         * @property {number} MessageType - Message Type.
         * @property {string} Sender - Message Sender.
         * @property {string} Target - Message Target.
         * @property {byte[]} Data - Message Target.
         * @property {number} Timeout - Timeout.
         * 
         * @augments modularis.EntityBase
         */
        var NotificationMessage = function () {
            EntityBase.call(this, defaultEntitySerializationMetadata, propertyDefs, 'MessageID');

            $.extend(this._propertyValues, {
                MessageID: null,
                CustomMessageType: null,
                MessageBody: null,
                MessageObject: null,
                MessageType: null,
                Sender: null,
                Target: null,
                Data: null,
                Timeout: null
            });
        };

        //Inheritance
        NotificationMessage.prototype = new EntityBase();
        NotificationMessage.prototype.constructor = NotificationMessage;

        Object.defineProperties(NotificationMessage.prototype, {

            'MessageID': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('MessageID');
                },
                set: function (newMessageID) {
                    this.setPropertyValue('MessageID', newMessageID);
                }
            },

            'CustomMessageType': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('CustomMessageType');
                },
                set: function (newCustomMessageType) {
                    this.setPropertyValue('CustomMessageType', newCustomMessageType);
                }
            },

            'MessageBody': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('MessageBody');
                },
                set: function (newMessageBody) {
                    this.setPropertyValue('MessageBody', newMessageBody);
                }
            },

            'MessageObject': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('MessageObject');
                },
                set: function (newMessageObject) {
                    this.setPropertyValue('MessageObject', newMessageObject);
                }
            },

            'MessageType': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('MessageType');
                },
                set: function (newMessageType) {
                    this.setPropertyValue('MessageType', newMessageType);
                }
            },

            'Sender': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('Sender');
                },
                set: function (newSender) {
                    this.setPropertyValue('Sender', newSender);
                }
            },

            'Target': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('Target');
                },
                set: function (newTarget) {
                    this.setPropertyValue('Target', newTarget);
                }
            },

            'Data': {
               enumerable: true,
               get: function () {
                  return this.getPropertyValue('Data');
               },
               set: function (newData) {
                  this.setPropertyValue('Data', newData);
               }
            },

            'Timeout': {
               enumerable: true,
               get: function () {
                  return this.getPropertyValue('Timeout');
               },
               set: function (newTimeout) {
                  this.setPropertyValue('Timeout', newTimeout);
               }
            }
        });

        //<Accelerator.Custom.Start>

        //Object.defineProperty(Department.prototype, 'PropertyName', { value: 'propertyValue' });

        //<Accelerator.Custom.End>

        // collection constructor
        var NotificationMessages = function () {

            EntityCollectionBase.call(this, assemblyName, 'NotificationMessages', 'Modularis.Notification.Entity.NotificationMessages');

        };

        //Inheritance
        NotificationMessages.prototype = new EntityCollectionBase();
        NotificationMessages.prototype.constructor = NotificationMessages;

        return {
            NotificationMessage: NotificationMessage,
            NotificationMessages: NotificationMessages
        };

    }
);
