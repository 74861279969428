define('mediator/mediatorBase',
    ['util'],
    function (util) {
        'use strict';

        /**
        * @classdesc Base class for mediator classes that handles the communication between colleague objects.
        * @constructs modularis.web.mediator.MediatorBase
        */
        var MediatorBase = function () {

            var that = this;

            that._colleagues = {};
        };

        /**
         * Registers a colleague.
         * @function register
         * @param {object} colleague - Colleague to be registered.
         * 
         * @instance
         * @memberOf modularis.web.mediator.MediatorBase
         */
        MediatorBase.prototype.register = function (colleague) {
            var that = this;

            if (!colleague || !colleague._colleagueId) { return; }

            var targetColleagueId = colleague._colleagueId;
            that._colleagues[targetColleagueId] = colleague;

        };

        /**
         * Unregisters a colleague.
         * @function unregister
         * @param {string} colleagueId - Unique identifier of the colleague to be unregistered.
         * 
         * @instance
         * @memberOf modularis.web.mediator.MediatorBase
         */
        MediatorBase.prototype.unregister = function (colleagueId) {
            var that = this;

            if (!colleagueId || !(colleagueId in that._colleagues)) {
                return;
            }

            delete that._colleagues[colleagueId];
        };

        /**
         * Sends a message to the colleagues registered to this mediator instance.
         * @function sendMessage
         * @param {string} senderColleagueId - Unique identifier of the sender colleague.
         * @param {string} messageType - Message type.
         * @param {object} [payload] - Payload object to be included in the message.
         * @param {string} [targetColleagueId] - Unique identifier of the target colleague. If it is not provided, the message is sent to all the registered colleagues.
         * @param {boolean} [isAsync] - Indicates if the message must be sent asynchronously to the colleagues. Its default value is true.
         * @instance
         * @memberOf modularis.web.mediator.MediatorBase
         */
        MediatorBase.prototype.sendMessage = function (senderColleagueId, messageType, payload, targetColleagueId, isAsync) {
            var that = this;

            if (!messageType || !senderColleagueId || (targetColleagueId && senderColleagueId === targetColleagueId)) { return; }

            if (typeof isAsync === 'undefined' || isAsync === null) {
                isAsync = true;
            }

            if (targetColleagueId) {
                that._sendMessageToColleague(senderColleagueId, targetColleagueId, messageType, payload, isAsync);
            } else {
                for (var colleagueId in that._colleagues) {
                    if (that._colleagues.hasOwnProperty(colleagueId)) {

                        if (colleagueId !== senderColleagueId) {
                            that._sendMessageToColleague(senderColleagueId, colleagueId, messageType, payload, isAsync);
                        }
                    }
                }
            }
        };

        MediatorBase.prototype._sendMessageToColleague = function (senderColleagueId, targetColleagueId, messageType, payload, isAsync) {
            var that = this;

            var colleague = that._colleagues[targetColleagueId];

            if (!colleague || !util.isFunction(colleague._receiveMessage)) { return; }

            if (isAsync) {
                setTimeout(function () {
                    colleague._receiveMessage(senderColleagueId, messageType, payload, isAsync);
                }, 0);
            } else {
                colleague._receiveMessage(senderColleagueId, messageType, payload, isAsync);
            }
        };


        return MediatorBase;

    }
);
