define('enums',
    {
        sessionExpired: 'sessionExpired',

        /**
         * Session Authentication Status
         * @readonly
         * @enum {number}
         * @memberof modularis.enums
         */
        authenticationStatus: {
            /** Not authenticated */
            notAuthenticated: 0,
            /** Authentication successful */
            authenticationSuccessful: 1,
            /** Authentication failed */
            authenticationFailed: 2,
            /** Authentication cancelled */
            authenticationCancelled: 3,
            /** Account disabled */
            accountDisabled: 4,
            /** Not authorized for system or interface*/
            notAuthorizedForSystemOrInterface: 5,
            /** No roles are defined for this system */
            systemRolesNotDefined: 6,
            /** Session expired */
            sessionExpired: 7,
            /** Out of licenses */
            outOfLicenses: 8
        },

        /**
         * Device Types
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        deviceTypes: {
            /** Desktop or laptop PC */
            desktop: 'desktop',
            /** Mobile phone */
            phone: 'phone',
            /** Tablet */
            tablet: 'tablet'
        },

        /**
         * Entity Edit Modes
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        editModes: {
            /** Insert */
            insert: 'Insert',
            /** Update */
            update: 'Update',
            /** Delete */
            deleteMode: 'Delete',
            /** Unknown */
            unknown: 'Unknown'
        },

        /**
         * Http Codes
         * @readonly
         * @enum {number}
         * @memberof modularis.enums
         */
        httpCodes: {
            /** Ok */
            ok: 200,
            /** Bad request */
            badRequest: 400,
            /** Unauthorized or session expired */
            unauthorized: 401,
            /** Forbidden or access denied */
            forbidden: 403,
            /** Internal server error */
            internalServerError: 500
        },

        /**
         * Entity Property Types
         * @readonly
         * @enum {number}
         * @memberof modularis.enums
         */
        propertyTypes: {
            /** Undefined */
            typeUndefined: -1,
            /** String */
            typeString: 0,
            /** Boolean */
            typeBoolean: 1,
            /** Byte */
            typeByte: 2,
            /** Int16 */
            typeInt16: 3,
            /** Int32 */
            typeInt32: 4,
            /** Single */
            typeSingle: 5,
            /** Double */
            typeDouble: 6,
            /** Decimal */
            typeDecimal: 7,
            /** Date/Time */
            typeDateTime: 8,
            /** Object */
            typeObject: 9,
            /** Blob/byte array */
            typeBLOB: 10,
            /** Timestamp */
            typeTimeStamp: 11,
            /** Int64 */
            typeInt64: 12,
            /** Guid */
            typeGuid: 13,
            /** Entity */
            typeEntity: 100,
            /** Entity Collection */
            typeEntityCollection: 101,
            /** eCollection */
            typeCollection: 102,
            /** eDictionary */
            typeDictionary: 103
        },

        /**
         * Notification Message Types
         * @readonly
         * @enum {number}
         * @memberof modularis.enums
         */
        notificationMessageTypes: {
            /** Timeout */
            timeout: 0,
            /** Entity Added */
            onAddEntity: 1,
            /** Entity Updated */
            onUpdateEntity: 2,
            /** Entity Deleted */
            onDeleteEntity: 3,
            /** Entity Collection Added */
            onAddEntityCollection: 4,
            /** Entity Collection Updated */
            onUpdateEntityCollection: 5,
            /** Entity Collection Deleted */
            onDeleteEntityCollection: 6,
            /** Heartbeat */
            heartbeat: 7,
            /** Custom message */
            custom: 8,
            /** Internal Message */
            internal: 9,
            /** Access Denied Message */
            accessDenied: 10
        },

        /**
         * Options for use with service proxy calls
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        options: {
            /** Command separator */
            commandSeparator: 'CommandSeparator',
            /** Convert to JavaScript entity */
            convertToJavaScriptEntity: 'ConvertToJavaScriptEntity',
            /** Default server */
            defaultServer: 'DefaultServer',
            /** Clones the fetched entity or entities and its children and preparing the cloned instances to be inserted as new records. */
            duplicate: 'Duplicate',
            /** Initialize on server */
            initializeOnServer: 'InitializeOnServer',
            /** Optimize for web (adds additional display properties - ending with 'DV' - for each linked property) */
            optimizeForWeb: 'OptimizeForWeb',
            /** Optimize for web display (replaces linked property values with display properties) */
            optimizeForWebDisplay: 'OptimizeForWebDisplay',
            /** Defines the properties to include in the entity response. */
            properties: 'Properties',
            /** Refetches data after a save operation */
            refetch: 'Refetch',
            /** Returns the entire session rather than just the session id when calling Authenticate */
            returnEntireSession: 'ReturnEntireSession',
            /** Selected Entities string used to define the depth of the object graphs returned by Get methods */
            selectedEntities: 'SelectedEntities',
            /** Session timeout specified when calling Authenticate */
            sessionTimeout: 'SessionTimeout',
            /** Removes entity state (_Entity and _EntityCollection objects) from the response. */
            simpleJSON: 'SimpleJSON',
            /** Used to set the 1-based index of the last row to retrieve from the result set of the query. This option and the SQLOption.StartRow are required to paginate through a result set. */
            sqlOptionEndRow: 'SQLOption.EndRow',
            /** Indicates whether or not to retrieve the total count of rows when paginating. */
            sqlOptionGetTotalCount: 'SQLOption.GetTotalCount',
            /** Used to set the 1-based index of the first row to retrieve from the result set of the query. This option and the SQLOption.EndRow are required to paginate through a result set.*/
            sqlOptionStartRow: 'SQLOption.StartRow',
            /** Defines the maximum number of records to retrieve when calling a Get method */
            topN: 'TopN'
        },

        /**
         * Validation States used by the AsyncValidator widget
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        validationState: {
            /** Checking */
            checking: 'checking',
            /** Invalid */
            invalid: 'invalid',
            /** Valid */
            valid: 'valid'
        },

        /**
         * Events available in new models
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        viewModelEventName: {
            /** Save completed */
            saveCompleted: 'saveEntity:completed',
            /** Save error */
            saveError: 'saveEntity:error',
            /** Delete completed */
            deleteCompleted: 'deleteEntity:completed',
            /** Delete error */
            deleteError: 'deleteEntity:error',
            /** Load completed */
            loadCompleted: 'loadEntity:completed',
            /** Load error */
            loadError: 'loadEntity:error'
        },

        /**
         * Events for ModularisView widget
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        modularisViewEventName: {
            /** Before view model is assigned */
            beforeViewModelAssigned: 'modularisView:beforeViewModelAssigned',
            /** Before view is assigned */
            beforeViewAssigned: 'modularisView:beforeViewAssigned',
            /** After view model is assigned */
            afterViewModelAssigned: 'modularisView:afterViewModelAssigned',
            /** After view is assigned */
            afterViewAssigned: 'modularisView:afterViewAssigned',
            /** Shown/Showed */
            showed: 'modularisView:showed'
        },

        /**
         * Notifications triggered by the notification service proxy
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        notificationEventName: {
            /** Add Entity */
            onAddEntity: 'notification:onAddEntity',
            /** Update Entity */
            onUpdateEntity: 'notification:onUpdateEntity',
            /** Delete Entity */
            onDeleteEntity: 'notification:onDeleteEntity',
            /** Add Entity Collection */
            onAddEntityCollection: 'notification:onAddEntityCollection',
            /** Update Entity Collection */
            onUpdateEntityCollection: 'notification:onUpdateEntityCollection',
            /** Delete Entity Collection */
            onDeleteEntityCollection: 'notification:onDeleteEntityCollection',
            /** Heartbeat */
            onHeartbeat: 'notification:onHeartbeat',
            /** Message Received */
            onReceiveMessage: 'notification:onReceiveMessage'
        },

        /**
         * Events triggered by the security service proxy
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        securityEventName: {
            /** Authentication successful */
            authenticationSuccessful: 'security:authenticate:success',
            /** Authentication failed */
            authenticationFailed: 'security:authenticate:error',
            /** Login completed */
            loginCompleted: 'login:completed',
            /** Login error */
            loginError: 'login:error',
            /** Logoff completed */
            logOff: 'security:logOff',
            /** Unauthorized */
            unauthorized: 'security:authorized'
        },

        /**
         * General proxy events
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        proxyEventName: {
            /** Http Error */
            httpError: 'proxy:httpError'
        },

        /**
         * Proxy method names
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        methodNames: {
            /**Get All*/
            GetAll: 'GetAll',
            /**Get Collection By Where*/
            GetCollectionByWhere: 'GetCollectionByWhere',
            /**Get Collection By QueryDef Name*/
            GetCollectionByQueryDefName: 'GetCollectionByQueryDefName',
            /**Get Collection By QueryDef ID*/
            GetCollectionByQueryDefID: 'GetCollectionByQueryDefID',
            /**Get Collection By QueryDef*/
            GetCollectionByQueryDef: 'GetCollectionByQueryDef',
            /**Get By Key*/
            GetByKey: 'GetByKey',
            /**Check Security*/
            CheckSecurity: 'CheckSecurity',
            /**Get New*/
            GetNew: 'GetNew',
            /**Check Security Collection**/
            CheckSecurityCollection: 'CheckSecurityCollection',
            /**Delete Entity**/
            DeleteEntity: 'Delete',
            /**Delete Collection**/
            DeleteCollection: 'DeleteCollection',
            /**Duplicate**/
            Duplicate: 'Duplicate',
            /**Duplicate Collection**/
            DuplicateCollection: 'DuplicateCollection',
            /**Get All Count**/
            GetAllCount: 'GetAllCount',
            /**Get AuditData**/
            GetAuditData: 'GetAuditData',
            /**Get Count By Where**/
            GetCountByWhere: 'GetCountByWhere',
            /**Get Count By QueryDef**/
            GetCountByQueryDef: 'GetCountByQueryDef',
            /**Save**/
            Save: 'Save',
            /**Save Collection**/
            SaveCollection: 'SaveCollection',
            /**Validate**/
            Validate: 'Validate',
            /**Validate Collection**/
            ValidateCollection: 'ValidateCollection'
        },

        /**
         * ItemSelector widget styles.
         * @readonly
         * @enum {string}
         * @memberof modularis.enums
         */
        itemSelectorStyles: {
            /**EntityEditList style*/
            entityEditList: 'entityeditlist',
            /**Grid style*/
            grid: 'grid',
            /**Toggle style*/
            toggle: 'toggle'
        }
    }
);
