define('modularisData/dataAccessAdaptee',
    ['jquery', 'entityCollectionBase', 'util', 'enums'],
   function ($, entityCollectionContainer, util, enums) {
       'use strict';

       var noTotalCount = -1;

       /*eslint-disable no-unused-vars*/
       var DataAccessAdaptee = function (config) {
           var that = this;
           that.parameterSource = null;
           that.dataSourceOptions = {};
           that.paginationTotalCount = noTotalCount;
           that.init(config);
       };

       DataAccessAdaptee.prototype.init = function (config) { };

       DataAccessAdaptee.prototype.read = function (options) { };

       DataAccessAdaptee.prototype.updateParameters = function (newParameters) { };

       DataAccessAdaptee.prototype.updateEntityParameters = function (entities) { };

       DataAccessAdaptee.prototype.updateParameterSource = function (source) {
           this.parameterSource = source;
       };

       DataAccessAdaptee.prototype.getParameterSource = function (source) {
           return this.parameterSource;
       };

       DataAccessAdaptee.prototype.setDataSourceOptions = function (dataSourceOptions) {
           this.dataSourceOptions = dataSourceOptions;
       };

       DataAccessAdaptee.prototype.getRealTimeEntitiesToMonitor = function () {
           return [];
       };

       DataAccessAdaptee.prototype._addPaginationOptions = function (proxyOptions, readOptions) {
           var that = this;
           //Check if server-side pagination is enabled
           if (!that.dataSourceOptions.serverPaging) {
               return proxyOptions;
           }

           var options = {};
           if (proxyOptions && util.isObject(proxyOptions)) {
               options = util.copy(proxyOptions);
           }
           options[enums.options.sqlOptionStartRow] = readOptions.data.skip + 1;
           options[enums.options.sqlOptionEndRow] = readOptions.data.skip + readOptions.data.take;

           if (that.paginationTotalCount === noTotalCount) {
               options[enums.options.sqlOptionGetTotalCount] = true;
           }
           return options;
       };

       DataAccessAdaptee.prototype._getDefaultRequestCallback = function (readOptions) {
           var callback = function (operationResult, operationError) {
               if (!util.success(operationResult, operationError)) {
                   readOptions.error(operationError);
                   return;
               }
               var result = operationResult;
               if (result && result.Items && (result._EntityCollection || result instanceof entityCollectionContainer.EntityCollectionBase)) {
                   var total = util.isFunction(result.getCollectionMetadata) ? result.getCollectionMetadata().getTotalCount() : result._EntityCollection.TotalCount;
                   if (total && total >= 0) {
                       this.paginationTotalCount = total;
                   }
                   result = result.Items;
               }

               if (!result) { result = []; }
               readOptions.success(result);
           };
           return $.proxy(callback, this);
       };

       return DataAccessAdaptee;
   }
);
