define('modularis/base/dynamicEntityHelper',
    ['jquery', 'RESTActivityWebServiceProxy', 'metadataCache', 'util'],
    function ($, RESTActivityWebServiceProxy, metadataCache, util) {
        'use strict';

        var dynamicEntityHelper = {

            getNew: function (entityDefID, options, callback) {
                metadataCache.getEntityDef({
                    entityDefId: entityDefID,
                    includePropertyDefs: true,
                    callback: function (entityDef, error) {
                        if (util.success(entityDef, error)) {

                            var proxyOptions = { entityDef: entityDef };
                            $.extend(proxyOptions, options);

                            RESTActivityWebServiceProxy.getNew(entityDefID, proxyOptions, callback);
                        }
                    }
                });
            }
        };

        return dynamicEntityHelper;
    }
);
