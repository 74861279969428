define('modularis/web/widgets/binders/localizeBinder',
   ['jquery', 'kendo', 'modularis/i18n/localizationHelper'],
   function ($, kendo, localizationHelper) {
       'use strict';

       var localeBinderContainer = {

           init: function () {
               /*eslint no-param-reassign: 0*/

               var refresh = function () {
                   var that = this;
                   localizationHelper.localize(that.element, that.keyMapping);
               };

               var initWidget = function (binder, element, bindings, options) {
                   var elementBinder = binder;

                   kendo.data.Binder.fn.init.call(elementBinder, element, bindings, options);

                   elementBinder.keyMapping = {};

                   for (var key in elementBinder.bindings.localize.path) {
                       if (elementBinder.bindings.localize.path.hasOwnProperty(key)) {
                           elementBinder.keyMapping[key] = elementBinder.bindings.localize.path[key];
                       }
                   }
               };

               kendo.data.binders.widget.localize = kendo.data.Binder.extend({
                   init: function (widget, bindings, options) {
                       var that = this;
                       initWidget(that, widget.element[0], bindings, options);
                   },
                   refresh: refresh
               });

               kendo.data.binders.localize = kendo.data.Binder.extend({
                   init: function (element, bindings, options) {
                       var that = this;
                       initWidget(that, element, bindings, options);
                   },
                   refresh: refresh
               });

           }

       };

       return localeBinderContainer;

   }
);
