define('RESTNotificationWebServiceProxy',
    ['jquery', 'RESTWebServiceProxy', 'clientCache', 'configLoader', 'proxyHelper', 'logger', 'util', 'enums'],
    function ($, proxyBase, clientCache, configLoader, proxyHelper, logger, util, enums) {
        /*eslint-env es6 */
        'use strict';

        /**
         * Handler for real-time notifications.
         * @callback notificationHandler
         * @param {Object} event - Object that contains all the information related to the notification.
         * @param {modularis.entity.notification.NotificationMessage} event.notificationMessage - The NotificationMessage entity sent by the platform.
         */

        var eventNames = enums.notificationEventName,
            notificationMessageTypes = enums.notificationMessageTypes;

        var separator = '|';

        var urlFormat = '{0}{1}/GetMessage/{2}/{3}/{4}';

        //#region Message type mapping

        var messageTypeMapping = [];
        messageTypeMapping[notificationMessageTypes.timeout] = null;
        messageTypeMapping[notificationMessageTypes.onAddEntity] = eventNames.onAddEntity;
        messageTypeMapping[notificationMessageTypes.onUpdateEntity] = eventNames.onUpdateEntity;
        messageTypeMapping[notificationMessageTypes.onDeleteEntity] = eventNames.onDeleteEntity;
        messageTypeMapping[notificationMessageTypes.onAddEntityCollection] = eventNames.onAddEntityCollection;
        messageTypeMapping[notificationMessageTypes.onUpdateEntityCollection] = eventNames.onUpdateEntityCollection;
        messageTypeMapping[notificationMessageTypes.onDeleteEntityCollection] = eventNames.onDeleteEntityCollection;
        messageTypeMapping[notificationMessageTypes.onHeartbeat] = eventNames.onHeartbeat;
        messageTypeMapping[notificationMessageTypes.custom] = eventNames.onReceiveMessage;
        messageTypeMapping[notificationMessageTypes.internal] = eventNames.onReceiveMessage;
        messageTypeMapping[notificationMessageTypes.accessDenied] = null;

        //#endregion
        /*eslint no-continue: 0*/
        //#region Process messages

        var processNotificationMessages = function (responseData) {

            if (responseData == null) {
                return;
            }

            var messagesCollection = responseData;

            if ((responseData instanceof ArrayBuffer) && responseData.byteLength) {
                messagesCollection = proxyHelper.processBinaryMessage(responseData);
            } else if (typeof (responseData) === 'string') {
                messagesCollection = JSON.parse(responseData);
            }

            if ((!messagesCollection) || (!messagesCollection.Items)) {
                return;
            }

            if (configLoader.appConfig.realTimeNotifications.accumulateMessages) {
                //Send multiple notification messages in one single event. The notification are accumulated based on the message type.
                triggerEventForEachMessageType(messagesCollection);
            } else {
                //Send one event for each notification message
                triggerEventForEachMessage(messagesCollection);
            }

        };

        var triggerEventForEachMessageType = function (messagesCollection) {

            var messageGroup = {};

            for (var index = 0; index < messagesCollection.Items.length; index++) {
                var notificationMessage = messagesCollection.Items[index];
                var messageType = notificationMessage.MessageType;

                //Check for Access Denied
                if (messageType === notificationMessageTypes.accessDenied) {
                    logger.info('RESTNotificationWebServiceProxy - Access denied');
                    continue;
                }

                var eventName = messageTypeMapping[messageType];

                if (eventName != null) {

                    var groupName = eventName;
                    if (groupName === eventNames.onReceiveMessage) {
                        groupName += separator + notificationMessage.CustomMessageType;
                    }

                    if (!(groupName in messageGroup)) {
                        messageGroup[groupName] = [];
                    }

                    messageGroup[groupName].push(notificationMessage);
                }
            }

            for (var eventType in messageGroup) {

                if (messageGroup.hasOwnProperty(eventType)) {
                    try {

                        var eventObject = {
                            notificationMessages: messageGroup[eventType]
                        };

                        var type = eventType, customNotificationType = null;
                        if (eventType.indexOf(separator) >= 0) {
                            type = eventType.substring(0, eventType.indexOf(separator));
                            customNotificationType = eventType.substring(eventType.indexOf(separator) + 1);
                        }

                        eventObject.type = type;
                        if (customNotificationType) {
                            eventObject.customNotificationType = customNotificationType;
                        }

                        $.event.trigger(eventObject);
                    } catch (error) {
                        logger.error('Error in a notification event listener: ' + error);
                    }
                }
            }
        };

        var triggerEventForEachMessage = function (messagesCollection) {

            for (var index in messagesCollection.Items) {
                if (messagesCollection.Items.hasOwnProperty(index)) {
                    var notificationMessage = messagesCollection.Items[index];
                    var messageType = notificationMessage.MessageType;

                    //Check for Access Denied
                    if (messageType === notificationMessageTypes.accessDenied) {
                        logger.info('RESTNotificationWebServiceProxy - Access denied');
                        continue;
                    }

                    var eventName = messageTypeMapping[messageType];
                    if (eventName != null) {

                        try {
                            $.event.trigger({
                                type: eventName,
                                notificationMessage: notificationMessage
                            });
                        } catch (error) {
                            logger.error('Error in a notification event listener: ' + error);
                        }
                    }
                }
            }

        };

        var processMessage = function (message) {

            var result = message;
            if (util.isFunction(message.getInstanceWithSerializationMetadata)) {
                result = message.getInstanceWithSerializationMetadata();
            }
            return result;
        };

        //#endregion

        /**
         * Modularis Notification Service Proxy
         * @namespace notificationServiceProxy
         * @memberof modularis.proxy
         */

        var RESTNotificationWebServiceProxy = function (serviceName) {

            var that = this;
            proxyBase.RESTWebServiceProxy.call(that, serviceName);

            //#region Start and stop notifications service

            var started = false;
            var url = null;
            var currentRequest;

            var poll = function () {
                if (started) {
                    //debugger;
                    currentRequest = $.ajax({
                        cache: false,
                        url: url,
                        success: processNotificationMessages,
                        complete: poll,
                        //timeout: (10 * 60 * 1000) + 10000 //10 minutes + 10 seconds
                        timeout: configLoader.appConfig.realTimeNotifications.timeout,
                        dataType: configLoader.appConfig.realTimeNotifications.responseDataType,
                        //processData must be false for binary responses.
                        processData: (configLoader.appConfig.realTimeNotifications.responseDataType !== 'binary'),
                        responseType: 'arraybuffer'
                    });
                }
            };

            var start = function () {

                //Define URL
                var activeServer = clientCache.getActiveServer();
                var deviceName = clientCache.getDeviceName();
                var serviceName = that.getServiceName();
                var primaryURI = activeServer.primaryURI;

                if (primaryURI.endsWith('/')) {
                    serviceName += '.svc';
                } else {
                    primaryURI += '/';
                }

                url = String.format(urlFormat, primaryURI, serviceName, activeServer.customerID, clientCache.getSessionID(), deviceName);

                started = true;
                poll();
            };

            var stop = function () {
                started = false;
                currentRequest.abort();
            };

            //#endregion

            //#region Functions to send notifications

            /**
             * Sends a Real-Time Notification to a given device.
             * @name sendNotificationToDevice
             * @function
             * 
             * @param {modularis.entity.notification.NotificationMessage} notificationMessage - The notification message to send to the device.
             * @param {String} targetDeviceName - Targets where the notification message will be sent.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.sendNotificationToDevice = function (notificationMessage, targetDeviceName, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Message: processMessage(notificationMessage),
                    Options: options,
                    RecipientID: targetDeviceName,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('SendNotificationToDevice', params, callback);
            };

            /**
             * Sends a Real-Time Notification to a given user.
             * @name sendNotificationToUser
             * @function
             * 
             * @param {modularis.entity.notification.NotificationMessage} notificationMessage - The notification message to send to the user.
             * @param {String} targetUsername - Targets where the notification message will be sent.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.sendNotificationToUser = function (notificationMessage, targetUsername, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Message: processMessage(notificationMessage),
                    Options: options,
                    RecipientID: targetUsername,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('SendNotificationToUser', params, callback);
            };

            /**
             * Sends a Real-Time Notification to a given user session.
             * @name sendNotificationToUserSession
             * @function
             * 
             * @param {modularis.entity.notification.NotificationMessage} notificationMessage - The notification message to send to the user session.
             * @param {string} targetUserSessionID - Targets where the notification message will be sent.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.sendNotificationToUserSession = function (notificationMessage, targetUserSessionID, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Message: processMessage(notificationMessage),
                    Options: options,
                    RecipientID: targetUserSessionID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('SendNotificationToUserSession', params, callback);
            };

            /**
             * Sends a Real-Time Notification to all connected clients.
             * @name sendBroadcastNotification
             * @function
             * 
             * @param {modularis.entity.notification.NotificationMessage} notificationMessage - The notification message to broadcast.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.sendBroadcastNotification = function (notificationMessage, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Message: processMessage(notificationMessage),
                    Options: options,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('SendBroadcastNotification', params, callback);
            };

            //#endregion

            //#region Functions to bind to notification events

            var bindNotificationHandler = function (eventName, callback) {

                if (!started) {
                    if (configLoader.containsConfigValue('realTimeNotifications') && configLoader.appConfig.realTimeNotifications.enabled) {
                        start();
                    } else {
                        throw new Error('Real-time notifications are disabled. Check app config.');
                    }
                }

                return that.bind(eventName, callback);
            };

            //AddEntity
            /**
             * Subscribes the given function to the AddEntity notifications.
             * @name onAddEntity
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onAddEntity = function (handler) {
                return bindNotificationHandler(eventNames.onAddEntity, handler);
            };

            //UpdateEntity
            /**
             * Subscribes the given function to the UpdateEntity notifications.
             * @name onUpdateEntity
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onUpdateEntity = function (handler) {
                return bindNotificationHandler(eventNames.onUpdateEntity, handler);
            };

            //DeleteEntity
            /**
             * Subscribes the given function to the DeleteEntity notifications.
             * 
             * @name onDeleteEntity
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onDeleteEntity = function (handler) {
                return bindNotificationHandler(eventNames.onDeleteEntity, handler);
            };

            //AddEntityCollection
            /**
             * Subscribes the given function to the AddEntityCollection notifications.
             * @name onAddEntityCollection
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onAddEntityCollection = function (handler) {
                return bindNotificationHandler(eventNames.onAddEntityCollection, handler);
            };

            //UpdateEntityCollection
            /**
             * Subscribes the given function to the UpdateEntityCollection notifications.
             * @name onUpdateEntityCollection
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onUpdateEntityCollection = function (handler) {
                return bindNotificationHandler(eventNames.onUpdateEntityCollection, handler);
            };

            //DeleteEntityCollection
            /**
             * Subscribes the given function to the DeleteEntityCollection notifications.
             * @name onDeleteEntityCollection
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onDeleteEntityCollection = function (handler) {
                return bindNotificationHandler(eventNames.onDeleteEntityCollection, handler);
            };

            //Heartbeat
            /**
             * Subscribes the given function to the Heartbeat notifications.
             * @name onHeartbeat
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onHeartbeat = function (handler) {
                return bindNotificationHandler(eventNames.onHeartbeat, handler);
            };

            //ReceiveMessage
            /**
             * Subscribes the given function to the ReceiveMessage notifications.
             * @name onReceiveMessage
             * @function
             * 
             * @param {notificationHandler} handler - The function to be executed when a notification is received.
             * @memberof modularis.proxy.notificationServiceProxy
             * @returns {string} Event handler ID.
             */
            that.onReceiveMessage = function (handler) {
                return bindNotificationHandler(eventNames.onReceiveMessage, handler);
            };

            //#endregion

            //#region Functions to unbind notification events

            //AddEntity
            /**
             * Unsubscribes the function with the given ID from the AddEntity notifications.
             * @name unbindAddEntity
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.unbindAddEntity = function (eventHandlerID) {
                that.unbind(eventNames.onAddEntity, eventHandlerID);
            };

            //UpdateEntity
            /**
             * Unsubscribes the function with the given ID from the UpdateEntity notifications.
             * @name unbindUpdateEntity
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.unbindUpdateEntity = function (eventHandlerID) {
                that.unbind(eventNames.onUpdateEntity, eventHandlerID);
            };

            //DeleteEntity
            /**
             * Unsubscribes the function with the given ID from the DeleteEntity notifications.
             * @name unbindDeleteEntity
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.unbindDeleteEntity = function (eventHandlerID) {
                that.unbind(eventNames.onDeleteEntity, eventHandlerID);
            };

            //AddEntityCollection
            /**
             * Unsubscribes the function with the given ID from the AddEntityCollection notifications.
             * @name unbindAddEntityCollection
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.unbindAddEntityCollection = function (eventHandlerID) {
                that.unbind(eventNames.onAddEntityCollection, eventHandlerID);
            };

            //UpdateEntityCollection
            /**
             * Unsubscribes the function with the given ID from the UpdateEntityCollection notifications.
             * @name unbindUpdateEntityCollection
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
             */
            that.unbindUpdateEntityCollection = function (eventHandlerID) {
                that.unbind(eventNames.onUpdateEntityCollection, eventHandlerID);
            };

            //DeleteEntityCollection
            /**
             * Unsubscribes the function with the given ID from the DeleteEntityCollection notifications.
             * @name unbindDeleteEntityCollection
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
             */
            that.unbindDeleteEntityCollection = function (eventHandlerID) {
                that.unbind(eventNames.onDeleteEntityCollection, eventHandlerID);
            };

            //Heartbeat
            /**
             * Unsubscribes the function with the given ID from the Heartbeat notifications.
             * @name unbindHeartbeat
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.unbindHeartbeat = function (eventHandlerID) {
                that.unbind(eventNames.onHeartbeat, eventHandlerID);
            };

            //ReceiveMessage
            /**
             * Unsubscribes the function with the given ID from the ReceiveMessage notifications.
             * @name unbindReceiveMessage
             * @function
             * 
             * @param {string} [eventHandlerID] - ID of the event handler to unbind.
             * @memberof modularis.proxy.notificationServiceProxy
             */
            that.unbindReceiveMessage = function (eventHandlerID) {
                that.unbind(eventNames.onReceiveMessage, eventHandlerID);
            };

            /**
             * Unbinds all the event handlers listening for notifications.
             * @name unbindAllEvents
             * @function
             * 
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.unbindAllEvents = function () {
                that.unbindAddEntity();
                that.unbindUpdateEntity();
                that.unbindDeleteEntity();
                that.unbindAddEntityCollection();
                that.unbindUpdateEntityCollection();
                that.unbindDeleteEntityCollection();
                that.unbindHeartbeat();
                that.unbindReceiveMessage();
            };

            /**
             * Closes the connnection established by the client to listen for real-time notifications.
             * @name closeConnection
             * @function
             * 
             * @memberof modularis.proxy.notificationServiceProxy
               */
            that.closeConnection = function () {
                if (started) {
                    //Closes the connection and unbind all event handlers
                    that.unbindAllEvents();
                    stop();
                }
            };

            //#endregion

        };

        //Inheritance
        RESTNotificationWebServiceProxy.prototype = new proxyBase.RESTWebServiceProxy();
        RESTNotificationWebServiceProxy.prototype.constructor = RESTNotificationWebServiceProxy;

        return new RESTNotificationWebServiceProxy('RESTNotificationWebService');

    }
);
