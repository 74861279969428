define('modularisData/commandAdaptee',
   ['jquery', 'modularisData/dataAccessAdaptee', 'RESTCommandWebServiceProxy', 'objectBuilder', 'metadataCache'],
   function ($, DataAccessAdaptee, commandServiceProxy, objectBuilder, metadataCache) {
       'use strict';

       var executeCommandOnCollection = function (command, entityCollectionConfig, commandOptions, callback) {

           //Invoke metadata cache to get the entitydef object
           metadataCache.getEntityDef({
               entityDefId: entityCollectionConfig.requestType,
               callback: function (entityDef) {

                   //Create an entityCollection object based on the received metadata.
                   objectBuilder.createEntityCollection({
                       entityTypeName: entityCollectionConfig.requestType,
                       entityCollectionName: entityDef.EntityCollectionName,
                       copyFrom: entityCollectionConfig.requestData,
                       callback: function (entityCollection) {
                           commandServiceProxy.executeCommandOnCollection(command, entityCollection, commandOptions, callback);
                       }
                   });

               }
           });

           //Create entityCollection

       };

       var CommandAdaptee = function (config) {
           var that = this;
           that.readProcessor = null;
           that.readCommandName = null;
           that.readCommandParameters = null;
           that.readCommandOptions = {};
           that.readCommandType = 'default';

           that.readCommandEntityCollection = null;

           DataAccessAdaptee.call(that, config);
       };

       CommandAdaptee.prototype = new DataAccessAdaptee();

       CommandAdaptee.prototype.init = function (config) {
           var that = this;
           if (config.processor) {
               that.readProcessor = config.processor;
           }
           if (config.name) {
               that.readCommandName = config.name;
           }
           if (config.parameters) {
               that.readCommandParameters = config.parameters;
           }
           if (config.commandOptions) {
               that.readCommandOptions = config.commandOptions;
           }

           if (config.entityCollection) {
               that.readCommandType = 'entityCollection';
               that.readCommandEntityCollection = config.entityCollection;
           }
       };

       CommandAdaptee.prototype.read = function (options) {
           var that = this;
           var command = {
               processor: that.readProcessor,
               name: that.readCommandName,
               parameters: that.readCommandParameters
           };
           var commandOptions = null;
           switch (that.readCommandType) {
               case 'entityCollection':
                   commandOptions = that._addPaginationOptions(that.readCommandOptions, options);
                   executeCommandOnCollection(command, that.readCommandEntityCollection, commandOptions, that._getDefaultRequestCallback(options));
                   break;
               case 'default':
                   commandOptions = that._addPaginationOptions(that.readCommandOptions, options);
                   commandServiceProxy.executeCommand(command, commandOptions, that._getDefaultRequestCallback(options));
                   break;
                   //no default
           }
       };

       CommandAdaptee.prototype.updateParameters = function (newParameters) {
           $.extend(this.readCommandParameters, newParameters);
       };

       CommandAdaptee.prototype.updateEntityParameters = function (entities) {
           this.readCommandEntityCollection = entities;
       };

       CommandAdaptee.prototype.updateParameterSource = function () {
           throw new Error('Function not supported for data sources related to the command service');
       };

       return CommandAdaptee;
   }
);
