define('mediator/colleagueViewModelBase',
    ['jquery', 'viewModelBase', 'mediator/colleagueBase'],
    function ($, viewModelBase, ColleagueBase) {
        'use strict';

        var ViewModelBase = viewModelBase.ViewModelBase;

        /**
        * @classdesc Base class that enables the View Model colleague to send and receive messages using a mediator.
        * 
        * @param {string} [colleagueId] - Unique identifier of the colleague. 
        * If the unique identifier is provided in the constructor, the colleague will be automatically registered to the {@link modularis.web.mediator.mainMediator|Main Mediator}.
        * 
        * @constructs modularis.web.mediator.ColleagueViewModelBase
        * @augments modularis.web.ViewModelBase
        * @augments modularis.web.mediator.ColleagueBase
        */
        var ColleagueViewModelBase = function (colleagueId) {
            var that = this;
            ViewModelBase.call(that);
            ColleagueBase.call(that, colleagueId);

            //#region Properties

            //#endregion

        };

        ColleagueViewModelBase.prototype = new ViewModelBase();
        ColleagueViewModelBase.prototype.constructor = ColleagueViewModelBase;

        $.extend(ColleagueViewModelBase.prototype, ColleagueBase.prototype);

        /**
        * Disposes the view model and unregisters it from the mediator. If you overwrites this function, be sure to unregister this colleague, to prevent it to continue receiving messages after the view model has been disposed.
        * @function disposeViewModel 
        * @instance
        * @memberOf modularis.web.mediator.ColleagueViewModelBase
        */
        ColleagueViewModelBase.prototype.disposeViewModel = function () {
            var that = this;
            that.unregisterAsColleague();
            ViewModelBase.prototype.disposeViewModel.call(that);
        };

        return ColleagueViewModelBase;


    }
);
