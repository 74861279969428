define('modularis/viewModels/entity/entityDataContainer',
    ['jquery', 'viewModelBase', 'entityHelper', 'util', 'enums'],
    function ($, viewModelBase, entityHelper, modularisUtil, enums) {
        'use strict';
        /*Generic view model to display entity edit lists or entity views in a generic way*/

        var EntityDataContainer = function () {
            var that = this;
            viewModelBase.ViewModelBase.call(that);

            that.mainContainerId = 'entity-container-' + modularisUtil.newGuid();
            that._containerSelector = '#' + that.mainContainerId + ' .widget-container';

            that.isCollection = false;
            that.isEntity = false;

            that.collectionName = null;
            that.entityName = null;
            that.entityTypeName = null;
            that.operation = null;
            that.instanceKey = null;
        };

        EntityDataContainer.prototype = new viewModelBase.ViewModelBase();

        EntityDataContainer.prototype.loadSettings = function (options) {
            var that = this;
            if (options.routeParameters) {

                if (options.routeParameters.entityCollection) {
                    var entityCollectionNameParam = options.routeParameters.entityCollection;
                    that.isCollection = true;
                    that.entityTypeName = entityHelper.getEntityTypeByEntityName(entityCollectionNameParam);
                   
                    if (that.entityTypeName) {
                        that.collectionName = entityHelper.getEntityCollectionNameByEntityName(entityCollectionNameParam);
                    } else {
                        that.collectionName = entityCollectionNameParam;
                        that.entityTypeName = entityHelper.getEntityTypeByCollectionName(that.collectionName);
                    }
                } else if (options.routeParameters.entityName) {
                    that.isEntity = true;
                    that.entityName = options.routeParameters.entityName;
                    that.entityTypeName = entityHelper.getEntityTypeByEntityName(that.entityName);
                    that.operation = options.routeParameters.operation;
                    that.instanceKey = options.routeParameters.key;
                }

            }

        };

        EntityDataContainer.prototype.afterViewIsDisplayed = function () {
            var that = this;
            if (that.isCollection) {
                $(that._containerSelector).kendoEntityEditList({
                    entityTypeName: that.entityTypeName,
                    modularisEditable: 'route',
                    modularisAllowDelete: true,
                    modularisAllowAdd: true,
                    sortable: true,
                    filterable: true,
                    toolbar: [
                        { name: 'pdf' },
                    ],
                    toolbarColumnMenu: true,
                    print: true,
                    //search: true,
                    noRecords: {
                        template: '<div class="empty-grid"><div data-icon-m="f" class="icon m-grid-logo"></div><p>Sorry, no ' + this.collectionName + ' found</p></div>'
                    }
                });
            } else if (that.isEntity) {

                //establish edit mode
                var editMode = enums.editModes.insert;
                if (that.operation === 'edit' && that.instanceKey) {
                    editMode = enums.editModes.update;
                }

                var viewOptions = {
                    entityTypeName: that.entityTypeName,
                    editMode: editMode
                };

                if (that.instanceKey) {
                    viewOptions.entityInstanceKey = that.instanceKey;
                }

                $(that._containerSelector).kendoModularisView(viewOptions);
            }
        };

        var initializeForView = function (callback, options) {
            var entityDataContainerViewModel = new EntityDataContainer();
            entityDataContainerViewModel.loadSettings(options);
            modularisUtil.notify(callback, entityDataContainerViewModel, null);
        };

        return {
            initializeForView: initializeForView
        };

    }
);
