define('modularis/web/widgets/modularisMenu',
   ['kendo', 'modularis/web/widgets/modularisView'],
   function (kendo, modularisViewContainer) {
       'use strict';
       var ModularisView = modularisViewContainer.widgetClass;
       var COLLAPSE = 'onCollapse', EXPAND = 'onExpand';

       /**
		 * @classdesc Modularis module menu widget.
		 * @constructs modularis.web.widgets.ModularisModuleMenu
		 * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
		 * 
		 * @property {Object} options - Widget options.
		 * @property {string} [options.orientation='vertical'] - Module menu orientation. Could be horizontal or vertical.
		 * @property {boolean} [options.defaultHeaderTooltips=false] - If true, a title header attribute will be added to each column.
		 * @property {number} [options.breakpoint=767] - Specifies the page width at which the widget will be hidden. You also need to adjust the CSS mediaqueries.
		 * @property {number} [options.animationSpeed=500] - Specifies the tree collapse/expand animation speed in miliseconds. 
		 * @property {string} [options.expandMode='multiple'] - Specifies how the Tree items are displayed when opened and closed. The following values are available: "single" Display one item at a time when an item is opened; opening an item will close the previously opened item. "multiple" Display multiple values at one time; opening an item has no visual impact on any other items in the PanelBar.
		 * 
		 */

       var ModularisModuleMenu = ModularisView.extend({

           init: function (element, options) {
               var that = this;
               options.view = options.orientation === 'vertical' ? 'shared/widgets/moduleMenu/_verticalMenu' : 'shared/widgets/moduleMenu/_horizontalMenu';
               ModularisView.fn.init.call(that, element, options);
               that.afterInit();
           },
           afterInit: function () {
               var that = this;
               that.setViewModelValue('breakpoint', that.options.breakpoint);
               that.setViewModelValue('animationSpeed', that.options.animationSpeed);
               that.setViewModelValue('expandMode', that.options.expandMode);
               that.setViewModelValue('orientation', that.options.orientation);
           },
           events: [
               COLLAPSE,
               EXPAND
           ],
           options: {
               name: 'ModularisMenu',
               orientation: 'vertical',
               viewModel: 'modularis/viewModels/widgets/moduleMenu',
               breakpoint: 767,
               animationSpeed: 500,
               expandMode: 'multiple'
           }
       });

       return {
           widgetClass: ModularisModuleMenu
       };

   }
);
