define('RESTWebServiceProxy',
    ['jquery', 'clientCache', 'objectBuilder', 'proxyHelper', 'configLoader', 'eventHelper', 'util', 'enums'],
    function ($, clientCache, objectBuilder, proxyHelper, configLoader, eventHelper, util, enums) {
        'use strict';
        /*eslint-disable no-param-reassign*/

        /**
         * Request callback for asynchronous operations where the backend platform or the web server are involved.
         * @callback requestCallback
         * @param {Object|string|modularis.EntityBase|modularis.EntityCollectionBase} responseObject - Response object when the request was succesfully executed.
         * @param {Object|string} responseError - Error object when the request failed.
         */

        var defaultServerOption = enums.options.defaultServer,
            convertToJSEntityOption = enums.options.convertToJavaScriptEntity;
        var fullEventHandlerIDFormat = '{0}.{1}';

        var optionsToIgnore = [defaultServerOption, convertToJSEntityOption];

        var sendRequest = function (serviceName, methodName, requestData, parseToJSONString, callback, dataType, httpMethod, additionalOptions) {
            var modifiedServiceName = serviceName,
                parseResponseToJSEntity = false;

            var activeServer = clientCache.getActiveServer();

            if (additionalOptions) {

                if (defaultServerOption in additionalOptions) {
                    activeServer = additionalOptions[defaultServerOption];
                    delete additionalOptions[defaultServerOption];
                }

                if (convertToJSEntityOption in additionalOptions) {
                    parseResponseToJSEntity = additionalOptions[convertToJSEntityOption];
                    delete additionalOptions[convertToJSEntityOption];
                }
            }

            if (proxyHelper.handleServerConfigurationException(activeServer, callback)) { return; }

            var primaryURI = activeServer.primaryURI;
            if (primaryURI.endsWith('/')) {
                modifiedServiceName = serviceName + '.svc';
            } else {
                primaryURI += '/';
            }

            var url = primaryURI + modifiedServiceName + '/' + methodName;
            var ajaxRequestData = requestData;
            if ((!additionalOptions) || util.isEmptyObject(additionalOptions) || (additionalOptions.processData)) {
                ajaxRequestData = parseToJSONString ? JSON.stringify(requestData) : requestData;
            }

            var responseDataType = dataType == null ? 'json' : dataType;
            var requestMethod = httpMethod == null ? 'POST' : httpMethod;

            var requestOptions = {
                type: requestMethod,
                url: url,
                data: ajaxRequestData,
                dataType: responseDataType,
                cache: false
            };

            if (additionalOptions && !util.isEmptyObject(additionalOptions)) {
                $.extend(requestOptions, additionalOptions);
            }

            //Add required option for binary data
            if (responseDataType === 'binary') {
                requestOptions.processData = false;
            }

            $.ajax(requestOptions)
              .done(function (responseResult) {

                  var responseObject = responseResult,
                      errorObject = null;
                  try {

                      //if(responseDataType )

                      if (typeof (responseResult) === 'string' && (responseResult.length > 0) & responseDataType === 'json') {
                          responseObject = JSON.parse(responseResult);
                      }
                      //Check whether the response should be parsed to JavaScript entity
                      if (parseResponseToJSEntity) {

                          if (responseObject._Entity) {
                              //it is a single entity
                              objectBuilder.createEntityObject({
                                  copyFrom: responseObject,
                                  callback: callback
                              });
                              return;
                          } else if (responseObject._EntityCollection) {
                              //it is a collection
                              objectBuilder.createEntityCollection({
                                  copyFrom: responseObject,
                                  callback: callback
                              });
                              return;
                          }
                      }

                      //responseObject = simpleObject;
                  } catch (parseError) {
                      errorObject = parseError;
                  }
                  util.notify(callback, responseObject, errorObject);

              })
                .fail(function (errorResult) {
                    var errorObject = {
                        status: errorResult.status,
                        statusText: errorResult.statusText
                    };

                    if (errorResult.responseText && errorResult.responseText.length > 0) {
                        try {
                            errorObject.response = JSON.parse(errorResult.responseText);

                            if (errorObject.response.Message && errorObject.response.Message.length > 0) {
                                try {
                                    errorObject.response.Message = JSON.parse(errorObject.response.Message);
                                } catch (messageParseError) {
                                    //that's fine. Message is not a JSON string.
                                }
                            }
                        } catch (parseError) {
                            errorObject.response = errorResult.responseText;
                        }
                    }

                    eventHelper.trigger(enums.proxyEventName.httpError, { url: url, code: errorObject.status });
                    util.notify(callback, null, errorObject);
                }
            );

        };

        var processRequestData = function (requestData, additionalOptions) {
            var data = requestData;
            var options = requestData.Options;
            var dataCloned = false;
            if (util.isDefined(options) && options != null) {
                var type = typeof (options);
                if (type === 'object') {
                    var optionsString = '';
                    var separator = '|';
                    for (var field in options) {
                        if (options.hasOwnProperty(field) && optionsToIgnore.indexOf(field) < 0) {
                            optionsString += field + separator + options[field] + separator;
                        }
                    }
                    optionsString = optionsString.substr(0, optionsString.length - 1);

                    data = util.copy(requestData);
                    dataCloned = true;
                    data.Options = optionsString;
                }
            }

            if (requestData.Payload) {

                var serializedPayload = null;
                if (util.isFunction(requestData.Payload.getInstanceWithSerializationMetadata)) {
                    serializedPayload = requestData.Payload.getInstanceWithSerializationMetadata();
                } else if (util.isFunction(requestData.Payload.toJSON)) {
                    //In some cases, specially when the entity comes from the UI, it's possible that the toJSON function is defined.
                    serializedPayload = requestData.Payload.toJSON();
                }

                if (serializedPayload) {
                    if (!dataCloned) { data = util.copy(requestData); }
                    data.Payload = serializedPayload;
                }

            }

            if (additionalOptions && util.isDefined(additionalOptions.convertToFormData)) {

                //Transform request data object to a FormData JavaScript object. Commonly needed when binary data needs to be send.
                var formData = new FormData();
                for (var property in data) {
                    if (data.hasOwnProperty(property)) {

                        if (!(data[property] instanceof Array)) {
                            formData.append(property, data[property]);
                        } else {
                            //Special code to handle scenario where data is an array with binary data on each position.
                            var array = data[property];
                            for (var index = 0; index < array.length; index++) {
                                var currentItem = array[index];
                                formData.append(currentItem.name, currentItem.data);
                            }
                        }
                    }
                }
                data = formData;
            }

            return data;
        };

        var processOptions = function (requestData, additionalOptions) {
            var extraOptions = additionalOptions || {};
            var requestOptions = requestData.Options;
            if (requestOptions) {
                if (defaultServerOption in requestOptions) {
                    extraOptions[defaultServerOption] = requestOptions[defaultServerOption];
                }
                if (convertToJSEntityOption in requestOptions) {
                    extraOptions[convertToJSEntityOption] = requestOptions[convertToJSEntityOption];
                }
            }
            return extraOptions;
        };

        var unsubscribeHandlers = function (handlerIDs, handlersContainer) {
            if ((typeof handlerIDs === 'string') && handlerIDs.lastIndexOf('.') > 0) {
                handlerIDs = [handlerIDs];
            }

            var handlerInfo;
            var handlerIdPosition = 2;

            if ((typeof handlerIDs === 'string') && handlerIDs.lastIndexOf('.') < 0) {
                delete handlersContainer[handlerIDs];
            } else if (handlerIDs && util.isObject(handlerIDs)) {
                for (var index = 0; index < handlerIDs.length; index++) {
                    handlerInfo = handlerIDs[index].split('.');

                    if (handlersContainer[handlerInfo[0]][handlerInfo[handlerIdPosition]]) {
                        delete handlersContainer[handlerInfo[0]][handlerInfo[handlerIdPosition]];
                    }
                }
            }
        };

        var subscribeHandlers = function (methodName, handlersContainer, handler) {
            var guid = util.newGuid();

            if (!handlersContainer[methodName]) {
                handlersContainer[methodName] = {};
            }

            handlersContainer[methodName][guid] = handler;

            return methodName + '.' + handlersContainer.name + '.' + guid;
        };

        var proxyHandlers = { beforeHandlers: { name: 'Before' }, afterHandlers: { name: 'After' } };

        var RESTWebServiceProxy = function (webServiceName) {

            var serviceName = webServiceName;

            this.getServiceName = function () {
                return serviceName;
            };

        };

        RESTWebServiceProxy.prototype = {
            sendJSONRequest: function (methodName, requestData, callback, responseDataType, httpMethod, additionalOptions) {
                var data = processRequestData(requestData, additionalOptions);
                var extraOptions = processOptions(requestData, additionalOptions);
                sendRequest(this.getServiceName(), methodName, data, true, callback, responseDataType, httpMethod, extraOptions);
            },

            sendPlainTextRequest: function (methodName, requestData, callback, responseDataType, httpMethod) {
                var data = processRequestData(requestData);
                sendRequest(this.getServiceName(), methodName, data, false, callback, responseDataType, httpMethod);
            },

            bind: function (eventName, callback) {

                //Use jQuery's event namespaces to assign unique identifiers to the event handlers
                var eventHandlerID = util.newGuid();
                $(document).on(String.format(fullEventHandlerIDFormat, eventName, eventHandlerID), callback);
                return eventHandlerID;
            },

            unbind: function (eventName, eventHandlerID) {

                if (eventHandlerID == null) {
                    //In this case, all event handlers for the given event will be removed.
                    $(document).off(eventName);
                } else {
                    $(document).off(String.format(fullEventHandlerIDFormat, eventName, eventHandlerID));
                }
            },

            _trigger: function (eventName, eventData) {

                var triggerObject = {
                    type: eventName
                };
                $.extend(true, triggerObject, eventData);
                $.event.trigger(triggerObject);
            },

            _getActiveServer: function (options) {
                var activeServer = clientCache.getActiveServer();
                if (options && options[defaultServerOption]) {
                    activeServer = options[defaultServerOption];
                }
                return activeServer;
            },

            _updateOptions: function (currentOptions, convertToJSEntity) {

                var options = currentOptions;
                if ((!currentOptions) || (!(convertToJSEntityOption in currentOptions))) {
                    var transformToJSEntity = false;
                    if (util.isDefined(convertToJSEntity)) {
                        transformToJSEntity = Boolean(convertToJSEntity) && configLoader.appConfig.entity.convertToJavaScriptEntity;
                    }
                    options = util.copy(currentOptions) || {};
                    options[convertToJSEntityOption] = transformToJSEntity;
                }
                return options;
            },

            _subscribeBeforeHandler: function (methodName, handler) {
                var handlerID = subscribeHandlers(methodName, proxyHandlers.beforeHandlers, handler);

                return handlerID;
            },

            _subscribeAfterHandler: function (methodName, handler) {
                var handlerID = subscribeHandlers(methodName, proxyHandlers.afterHandlers, handler);

                return handlerID;
            },

            _unsubscribeBeforeHandlers: function (handlerIDs) {
                unsubscribeHandlers(handlerIDs, proxyHandlers.beforeHandlers);
            },

            _unsubscribeAfterHandlers: function (handlerIDs) {
                unsubscribeHandlers(handlerIDs, proxyHandlers.afterHandlers);
            },

            _invokeBeforeHandlers: function (methodName, params) {
                var availableHandlers = proxyHandlers.beforeHandlers[methodName];

                var cancel = false;

                if (availableHandlers) {
                    for (var availableHandler in availableHandlers) {
                        if (availableHandlers.hasOwnProperty(availableHandler)) {
                            cancel = proxyHandlers.beforeHandlers[methodName][availableHandler].call(this, params);
                            if (cancel) {
                                return true;
                            }
                        }
                    }
                }
                return cancel;
            },

            _afterCallback: function (methodName, params, originalCallback) {
                var that = this;
                return function (response, error) {
                    params = proxyHelper.changeFirstLetterToLowerCase(params);
                    params.responseObject = response;
                    params.responseError = error;

                    var availableHandlers = proxyHandlers.afterHandlers[methodName];
                    if (availableHandlers) {

                        for (var availableHandler in availableHandlers) {
                            if (availableHandlers.hasOwnProperty(availableHandler)) {
                                proxyHandlers.afterHandlers[methodName][availableHandler].call(that, params);
                            }
                        }
                    }
                    util.notify(originalCallback, params.responseObject, params.responseError);
                };
            }
        };

        return {
            RESTWebServiceProxy: RESTWebServiceProxy
        };

    }
);

