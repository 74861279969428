define('modularis/web/widgets/binders/selectedItemBinder',
   ['jquery', 'kendo'],
   function ($, kendo) {
       'use strict';

       var selectedItemBinderContainer = {

           init: function () {
               /*eslint no-param-reassign: 0*/
               kendo.data.binders.widget.selectedItem = kendo.data.Binder.extend({

                   init: function (widget, bindings, options) {
                       var that = this;
                       kendo.data.Binder.fn.init.call(that, widget.element[0], bindings, options);
                       widget.bind('change', $.proxy(that.change, that));
                   },
                   change: function (event) {
                       var that = this;
                       var view = event.sender.dataSource.view();
                       var selectedIndex = event.sender.select().index();
                       var selectedItem = view[selectedIndex];

                       that.bindings.selectedItem.set(selectedItem);
                   },
                   refresh: function () {
                       
                   }

               });

           }

       };

       return selectedItemBinderContainer;

   }
);
