define('modularis/i18n/translatorFactory',
    ['modularis/i18n/translators/tooltipTranslator',
     'modularis/i18n/translators/textTranslator',
     'modularis/i18n/translators/htmlTranslator',
     'modularis/i18n/translators/valueTranslator',
     'modularis/i18n/translators/titleTranslator',
     'modularis/i18n/translators/defaultTranslator'],
    function (TooltipTranslator, TextTranslator, HtmlTranslator, ValueTranslator, TitleTranslator, DefaultTranslator) {
        'use strict';

        var suffix = 'Translator';

        var translators = {
            TooltipTranslator: TooltipTranslator,
            TextTranslator: TextTranslator,
            HtmlTranslator: HtmlTranslator,
            ValueTranslator: ValueTranslator,
            TitleTranslator: TitleTranslator,
            DefaultTranslator: DefaultTranslator
        };

        var TranslatorFactory = function () {
            var that = this;
            that.translatorDictionary = {};
            that.createTranslator = function (property) {
                var translatorClass = property.charAt(0).toUpperCase() + property.substr(1) + suffix;

                var translator;

                if (!(property in that.translatorDictionary)) {
                    if (translatorClass in translators) {
                        that.translatorDictionary[property] = new translators[translatorClass]();
                    } else {
                        that.translatorDictionary[property] = new translators.DefaultTranslator();
                    }
                }

                translator = that.translatorDictionary[property];

                return translator;
            };
        };

        return TranslatorFactory;
    });
