define('securityHelper',
   ['RESTSecurityWebServiceProxy', 'clientCache', 'configLoader', 'enums'],
   function (securityServiceProxy, clientCache, configLoader, modularisEnums) {
       'use strict';
       var userRoles = null, approvedFunctions = null;

       var parseUserRoles = function () {


           /* e.g. with multi tenancy [Modularis.1000][SpecTec.1000]7137d263-b203-49ab-93d3-a506014aaff8:[Modularis.1000][SpecTec.1000] 
            * without multi tenancy [Modularis.1000][SpecTec.1000]
            */
           //make a copy
           var userRoleString = clientCache.getSession().UserRoles.toString();

           var systemRole,
              currentTenantID = null;

           userRoles = [];
           while (userRoleString.length > 0) {


               //Single tenant
               //[Modularis.1000]
               if (userRoleString.startsWith('[')) {
                   //Modularis.1000
                   systemRole = userRoleString.substring(1, userRoleString.indexOf(']'));

                   var userRole = {
                       //Modularis
                       systemName: systemRole.substring(0, systemRole.indexOf('.')),
                       //1000
                       roleID: parseInt(systemRole.substring(systemRole.indexOf('.') + 1)),
                       tenantID: currentTenantID
                   };

                   userRoles.push(userRole);

                   userRoleString = userRoleString.substring(userRoleString.indexOf(']') + 1);

               } else {
                   //Multi-tenant
                   var tenantID = userRoleString.substring(0, userRoleString.indexOf(':'));
                   userRoleString = userRoleString.substring(userRoleString.indexOf(':') + 1);

                   currentTenantID = tenantID;

               }
           }

       };

       var parseFunctions = function () {

           var approvedFunctionsString = clientCache.getSession().ApprovedFunctions.toString();
           var functionsStringArray = [approvedFunctionsString];

           var tenantID = clientCache.getActiveTenantID();

           var position = approvedFunctionsString.indexOf(tenantID);
           if (position >= 0) {
               functionsStringArray[0] = approvedFunctionsString.substring(0, position);
               functionsStringArray.push(approvedFunctionsString.substring(position + tenantID.length + 1));
           }

           var rangeStart, rangeEnd;

           approvedFunctions = [];
           // noprotect
           for (var index = 0; index < functionsStringArray.length; index++) {
               //e.g. {Modularis}1-2720|2739-2791{SpecTec}5000-5354
               var functionsString = functionsStringArray[index];

               //Create object with system name and string with ranges
               var systemAndRanges = {};
               while (functionsString.length > 0) {
                   //e.g. Modularis
                   var systemName = functionsString.substring(1, functionsString.indexOf('}'));
                   //e.g. 1-2720|2739-2791{SpecTec}5000-5354
                   functionsString = functionsString.substring(functionsString.indexOf('}') + 1);
                   var nextOpenBracePosition = functionsString.indexOf('{');
                   if (nextOpenBracePosition >= 0) {
                       systemAndRanges[systemName] = functionsString.substring(0, nextOpenBracePosition);
                       functionsString = functionsString.substring(nextOpenBracePosition);
                   } else {
                       systemAndRanges[systemName] = functionsString;
                       functionsString = '';
                   }
                   
               }

               for (var currentSystemName in systemAndRanges) {

                   if (systemAndRanges.hasOwnProperty(currentSystemName)) {

                       var ranges = systemAndRanges[currentSystemName].split('|');
                       for (var rangeIndex = 0; rangeIndex < ranges.length; rangeIndex++) {
                           var rangeString = ranges[rangeIndex];
                           rangeStart = parseInt(rangeString.substring(0, rangeString.indexOf('-')));
                           rangeEnd = parseInt(rangeString.substring(rangeString.indexOf('-') + 1));

                           approvedFunctions.push({
                               systemName: currentSystemName,
                               start: rangeStart,
                               end: rangeEnd,
                               tenantID: (index === 0 ? null : tenantID)
                           });
                       }
                   }
               }

           }

       };

       var securityHelper = {
           getUserRoles: function (systemName, tenantID) {
               var mySystemName = systemName;
               if (!mySystemName) { mySystemName = configLoader.appConfig.systemName; }

               var myTenantID = tenantID;
               if (!myTenantID) { myTenantID = null; }

               var roles = [];
               if (!userRoles) { parseUserRoles(); }

               for (var index = 0; index < userRoles.length; index++) {
                   if (userRoles[index].systemName === mySystemName && userRoles[index].tenantID === myTenantID) {
                       roles.push(userRoles[index].roleID);
                   }
               }

               return roles;
           },

           isUserInRole: function (roleID, systemName, tenantID) {
               var roles = this.getUserRoles(systemName, tenantID);
               return roles.indexOf(roleID) >= 0;
           },

           isFunctionApproved: function (functionID, systemName, tenantID) {
               var mySystemName = systemName;
               if (!mySystemName) { mySystemName = configLoader.appConfig.systemName; }

               var myTenantID = tenantID;
               if (!myTenantID) { myTenantID = null; }

               if (!approvedFunctions) { parseFunctions(); }

               for (var index = 0; index < approvedFunctions.length; index++) {
                   if (approvedFunctions[index].systemName === mySystemName && approvedFunctions[index].tenantID === myTenantID
                      && functionID >= approvedFunctions[index].start && functionID <= approvedFunctions[index].end) {
                       return true;
                   }
               }
               return false;
           }
       };

       securityServiceProxy.bind(modularisEnums.securityEventName.logOff, function () {
           userRoles = null;
           approvedFunctions = null;
       });

       return securityHelper;
   }
);
