define('modularis/web/widgetLoader',
    ['kendo', 'modularis/web/widgets/modularisView', 'modularis/web/widgets/propDropDown', 'modularis/web/widgets/modularisModuleMenu', 'modularis/web/widgets/modularisMenu',
       'modularis/web/widgets/locale', 'modularis/web/widgets/modularisTruncate', 'modularis/web/widgets/binders/buttonBinders', 'modularis/web/widgets/binders/dateBinder',
       'modularis/web/widgets/entityEditList', 'modularis/web/widgets/entityDropDownList', 'modularis/web/widgets/binders/selectedItemBinder', 'modularis/web/widgets/itemSelector',
       'modularis/web/widgets/binders/itemSelectorBinders', 'modularis/web/widgets/binders/localizeBinder', 'modularis/web/widgets/binders/activeEntityBinder',
       'modularis/web/widgets/asyncValidator', 'modularis/web/widgets/modularisValidator', 'modularis/web/widgets/binders/maxBinder', 'modularis/web/widgets/binders/minBinder'],
    function (kendo, modularisViewContainer, propDropDownContainer, modularisModuleMenuContainer, modularisMenuContainer,
      localeContainer, modularisTruncateContainer, buttonBinderContainer, dateBinderContainer,
      entityEditListContainer, entityDropDownListContainer, selectedItemBinderContainer, itemSelectorContainer,
      itemSelectorBindersContainer, localizeBinderContainer, activeEntityBinderContainer,
      asyncValidatorContainer, modularisValidatorContainer, maxBinderContainer, minBinderContainer) {
	    'use strict';

	    var ui = kendo.ui;

	    var widgetLoader = {

	        init: function () {

	            //Init widgets
	            ui.plugin(modularisViewContainer.widgetClass);
	            ui.plugin(propDropDownContainer.widgetClass);
                ui.plugin(modularisModuleMenuContainer.widgetClass);
                ui.plugin(modularisMenuContainer.widgetClass);
	            ui.plugin(localeContainer.widgetClass);
	            ui.plugin(modularisTruncateContainer.widgetClass);
	            ui.plugin(entityEditListContainer.widgetClass);
	            ui.plugin(entityDropDownListContainer.widgetClass);
	            ui.plugin(itemSelectorContainer.widgetClass);
	            ui.plugin(asyncValidatorContainer.widgetClass);
	            ui.plugin(modularisValidatorContainer.widgetClass);

	            //Init bindings
	            buttonBinderContainer.init();
	            propDropDownContainer.binderContainer.init();
	            dateBinderContainer.init();
	            selectedItemBinderContainer.init();
	            itemSelectorBindersContainer.init();
	            localizeBinderContainer.init();
	            activeEntityBinderContainer.init();
	            maxBinderContainer.init();
	            minBinderContainer.init();
	        },

	        ModularisView: modularisViewContainer.widgetClass,
	        PropDropDown: propDropDownContainer.widgetClass,
            ModularisModuleMenu: modularisModuleMenuContainer.widgetClass,
            ModularisMenu: modularisMenuContainer.widgetClass,
	        Locale: localeContainer.widgetClass,
	        ModularisTruncate: modularisTruncateContainer.widgetClass,
	        EntityEditList: entityEditListContainer.widgetClass,
	        EntityDropDownList: entityDropDownListContainer.widgetClass,
	        ItemSelector: itemSelectorContainer.widgetClass
	    };
	    return widgetLoader;
	}
);
