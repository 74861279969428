define('modularisBase/extensions',
    [],function () {
        'use strict';
        /*eslint-disable no-extend-native*/

        //#region String extensions

        //string.format

        if (!String.format) {
            String.format = function (format) {
                var args = Array.prototype.slice.call(arguments, 1);
                return format.replace(/{(\d+)}/g, function (match, number) {
                    return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
                });
            };
        }

        //startsWith

        if (typeof String.prototype.startsWith != 'function') {
            // see below for better implementation!
            String.prototype.startsWith = function (str) {
                return this.indexOf(str) === 0;
            };
        }

        //endsWith

        if (typeof String.prototype.endsWith !== 'function') {
            String.prototype.endsWith = function (suffix) {
                return this.indexOf(suffix, this.length - suffix.length) !== -1;
            };
        }

        //#endregion

        return {};
    }
);
