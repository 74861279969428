define('modularis/web/widgets/modularisTruncate',
   ['jquery', 'kendo', 'util'],
   function ($, kendo, util) {
       'use strict';
       var ui = kendo.ui, Widget = ui.Widget;

       //#region Widget definition

       var ModularisTruncate = Widget.extend({

           _truncatedText: null,

           init: function (element, options) {
               var that = this;
               Widget.fn.init.call(that, element, options);
           },

           options: {
               name: 'ModularisTruncate',
               textLength: 200
           },

           value: function(value) {

               var that = this;
               if (util.isDefined(value)) {
                   that.truncate(value);
                   return undefined;
               }
               return that._truncatedText;
           },

           truncate: function (originalText) {

               var that = this;
               if (originalText == null || originalText.length < that.options.textLength) {
                   that._truncatedText = originalText;
                   that.element.html(that._truncatedText);
                   return;
               }

               that._truncatedText = originalText.slice(0, that.options.textLength) +
                       '<span>...</span><a href="#" class="modularis-more">More</a>' +
                       '<span style="display:none;">' + 
                       originalText.slice(that.options.textLength, originalText.length) +
                       '<a href="#" class="modularis-less">Less</a></span>';

               that.element.html(that._truncatedText);

               $('a.modularis-more').click(function(event) {
                   event.preventDefault();

                   //hide 'more' hyperlink
                   $(this).hide();

                   var threeDotsSpan = $(this).prev();

                   //hide 'threeDotsSpan'
                   threeDotsSpan.hide();

                   var lessSpan = $(this).next();

                   //show 'less' hyperlink
                   lessSpan.show();
                   
               });

               $('a.modularis-less').click(function(event) {
                   event.preventDefault();

                   var lessSpan = $(this).parent();

                   //hide 'less' hyperlink
                   lessSpan.hide();

                   var moreHyperlink = lessSpan.prev();
                   moreHyperlink.show();

                   var moreSpan = moreHyperlink.prev();
                   moreSpan.show();
                   
               });

           }


       });

       //#endregion

       return {
           widgetClass: ModularisTruncate
       };
});
