define('modularis/web/widgets/itemSelector',
    ['jquery', 'kendo', 'modularis/web/widgets/modularisView', 'webUtil', 'util', 'enums'],
    function ($, kendo, modularisViewContainer, webUtil, util, enums) {
        'use strict';
        var ModularisView = modularisViewContainer.widgetClass;

        var selectEvent = 'select',
            unselectEvent = 'unselect',
            dataBoundEvent = 'dataBound',
            changeEvent = 'change';

        /**
         * @classdesc ItemSelector widget.
         * @constructs modularis.web.widgets.ItemSelector
         * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
         * 
         * @property {Object} options - Widget options.
         * @property {modularis.enums.itemSelectorStyles} [options.style=grid] - Type of item selector to render.
         * Grid style means that Kendo UI grids will be used to render the containers.
         * EntityEditList style will use Modularis' EntityEditLists to contain the data; this style is the ideal one when the objects to manipulate are entities.
         * The Toogle style is under development.
         * @property {boolean} [options.changeEditMode=true] - Set this property to false if you want to manage the EditMode of the Entites manually.
         * @property {number} [options.height=200] - Widget height.
         * 
         * @augments modularis.web.widgets.ModularisView
         */
        var ItemSelector = ModularisView.extend({
            init: function (element, options) {
                var that = this;
                ModularisView.fn.init.call(that, element, options);
                that.bind(enums.modularisViewEventName.afterViewModelAssigned, that._handleViewModelAssigned);

                var viewModelOptions = util.copy(that.options);
                viewModelOptions.additionalAttributes = webUtil.getAdditionalAttributes(that.element, that.options);
                viewModelOptions.selectedItems = that.options.selectedItems;
                viewModelOptions.unselectedItems = that.options.unselectedItems;
                that.setViewModelValue('options', viewModelOptions);
            },
            _handleViewModelAssigned: function () {
                var that = this;
                var viewModel = this.getViewModel();
                viewModel.bind(selectEvent, function (event) {
                    that.trigger(selectEvent, { allItems: event.allItems, items: event.items });
                });
                viewModel.bind(unselectEvent, function (event) {
                    that.trigger(unselectEvent, { allItems: event.allItems, items: event.items });
                });
                viewModel.bind(dataBoundEvent, function (event) {
                    that.trigger(dataBoundEvent, { sourceContainer: event.sourceContainer });
                });
                viewModel.bind(changeEvent, function (event) {
                    that.trigger(changeEvent, { sourceContainer: event.sourceContainer });
                });
            },
            options: {
                name: 'ItemSelector',
                viewModel: 'modularis/viewModels/widgets/itemDataContainer',
                style: enums.itemSelectorStyles.grid,
                height: 200,
                columns: null,
                columnsSelected: null,
                placeholder: 'Select an Item',
                textField: null,
                valueField: null,
                selectedItemsTitle: 'Selected items',
                unselectedItemsTitle: 'Available items',
                changeEditMode: true,

                //Options available for entityEditList and grid styles
                editable: null,
                selectedItemsEditable: null,
                filterable: null,
                selectedItemsFilterable: null,
                groupable: null,
                selectedItemsGroupable: null,
                pageable: null,
                selectedItemsPageable: null,
                scrollable: null,
                selectedItemsScrollable: null,
                sortable: null,
                selectedItemsSortable: null,
                navigatable: null,
                selectEntityOnNavigation: null,

                //Options specific to the entityEditList style
                entityTypeName: null,
                selectedEntitiesTypeName: null,

                //Options specific to the sources initialization
                selectedItems: null,
                unselectedItems: null

            },

            events: [selectEvent, unselectEvent, dataBoundEvent, changeEvent],

            adjustLayout: function () {
                var viewModel = this.getViewModel();
                viewModel.adjustLayout();
            },
            setSelectedItems: function (items) {
                this.setViewModelValue('selectedItems', items);
            },

            setUnselectedItems: function (items) {
                this.setViewModelValue('unselectedItems', items);
            },

            /**
             * Returns the widget that contains the selected items. 
             * The return type dependes on the style defined for the widget.
             * @returns {modularis.web.widgets.EntityEditList|kendo.ui.Grid} 
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            getSelectedItemsWidget: function () {
                var viewModel = this.getViewModel();
                return viewModel.getSelectedItemsWidget();
            },

            /**
             * Returns the widget that contains the unselected items. 
             * The return type dependes on the style defined for the widget.
             * @returns {modularis.web.widgets.EntityEditList|kendo.ui.Grid} 
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            getUnselectedItemsWidget: function () {
                var viewModel = this.getViewModel();
                return viewModel.getUnselectedItemsWidget();
            },

            /**
             * Set the height of the widget and will adjust the layout accordingly.
             * @param {number} newHeight - New height of the widget.
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            setHeight: function (newHeight) {
                var viewModel = this.getViewModel();
                viewModel.setHeight(newHeight);
            },

            /**
             * Enables or disables the ItemSelector. If set to false the ItemSelector will be disabled and will not allow user input. The ItemSelector is enabled by default and allows user input.
             * @param {boolean} toggle - Set to false to disable the widget or set it to true to enable it.
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            enable: function (toggle) {
                this.setViewModelValue('enable', toggle);
            },

            setButtonContainerPosition: function () {
                var viewModel = this.getViewModel();
                viewModel.setButtonContainerPosition();
            },

            _assignBeforeSelectedItemsHandler: function (method) {
                this.setViewModelValue('beforeSelectedItemsHandler', method);
            },

            _assignBeforeUnselectedItemsHandler: function (method) {
                this.setViewModelValue('beforeUnselectedItemsHandler', method);
            },

            _assignBeforeSelectedItemsAsyncHandler: function (method) {
                this.setViewModelValue('beforeSelectedItemsAsyncHandler', method);
            },

            _assignBeforeUnselectedItemsAsyncHandler: function (method) {
                this.setViewModelValue('beforeUnselectedItemsAsyncHandler', method);
            },

            _getViewPath: function () {
                var that = this;
                if (that._viewPath == null) {
                    that._viewPath = that.options.view || ('shared/widgets/itemSelector/_' + that.options.style + 'Style');
                }
                return that._viewPath;
            }

        });
        return {
            widgetClass: ItemSelector
        };

    });
