define('modularis/entity/security/userPreference',
    ['jquery', 'modularis/entity/common', 'entityBase', 'entityCollectionBase', 'enums'],
    function ($, modelCommon, baseEntity, entityCollectionBase, enums) {
       'use strict';
       var types = enums.propertyTypes;
       var propertyDefs = [
           { name: 'KeyName', type: types.typeString },
           { name: 'SystemName', type: types.typeString },
           { name: 'UserID', type: types.TypeInt32 },
           { name: 'Value', type: types.typeString },
           { name: 'Timestamp', type: types.typeTimeStamp }
       ];

       var assemblyName = modelCommon.AssemblyName;
       var defaultEntitySerializationMetadata = {
          AssemblyName: assemblyName,
          Culture: null,
          Name: 'UserPreference',
          Type: 'Entity',
          AllowNulls: false,
          Dirty: true,
          EditMode: enums.editModes.insert,
          Valid: true,
          Attributes: [],
          Errors: []
       };

       var EntityBase = baseEntity.EntityBase;
       var EntityCollectionBase = entityCollectionBase.EntityCollectionBase;

       var UserPreference = function () {
          EntityBase.call(this, defaultEntitySerializationMetadata, propertyDefs, 'UserID.SystemName.KeyName');

          $.extend(this._propertyValues, {
             KeyName: null,
             SystemName: null,
             UserID: null,
             Value: null,
             Timestamp: null
          });
       };

       //Inheritance
       UserPreference.prototype = new EntityBase();
       UserPreference.prototype.constructor = UserPreference;

       Object.defineProperties(UserPreference.prototype, {

          'KeyName': {
             enumerable: true,
             get: function () {
                return this.getPropertyValue('KeyName');
             },
             set: function (newKeyName) {
                this.setPropertyValue('KeyName', newKeyName);
             }
          },

          'SystemName': {
             enumerable: true,
             get: function () {
                return this.getPropertyValue('SystemName');
             },
             set: function (newSystemName) {
                this.setPropertyValue('SystemName', newSystemName);
             }
          },

          'UserID': {
             enumerable: true,
             get: function () {
                return this.getPropertyValue('UserID');
             },
             set: function (newUserID) {
                this.setPropertyValue('UserID', newUserID);
             }
          },

          'Value': {
             enumerable: true,
             get: function () {
                return this.getPropertyValue('Value');
             },
             set: function (newValue) {
                this.setPropertyValue('Value', newValue);
             }
          },

          'Timestamp': {
             enumerable: true,
             get: function () {
                return this.getPropertyValue('Timestamp');
             },
             set: function (newTimestamp) {
                this.setPropertyValue('Timestamp', newTimestamp);
             }
          }
       });

       //<Accelerator.Custom.Start>

       //Object.defineProperty(Department.prototype, 'PropertyName', { value: 'propertyValue' });

       //<Accelerator.Custom.End>

       // collection constructor
       var UserPreferences = function () {

          EntityCollectionBase.call(this, assemblyName, 'UserPreferences', 'Modularis.Security.Entity.UserPreferences');

       };

       //Inheritance
       UserPreferences.prototype = new EntityCollectionBase();
       UserPreferences.prototype.constructor = UserPreferences;

       return {
          UserPreference: UserPreference,
          UserPreferences: UserPreferences
       };

    }
);
