define('modularisGeneral/jQueryUtil',
   ['jquery', 'logger'],
   function ($, logger) {
       /*eslint-env es6 */
       'use strict';

       var jQueryUtil = {

           /**Invokes all the jQuery features that must personalized for the platform */
           configure: function () {
               this.enableCORS();
               this.createBinaryTransport();
           },

           enableCORS: function () {
               $.support.cors = true;
           },

           createBinaryTransport: function () {

               /**
                * Code based on:
                * http://www.artandlogic.com/blog/2013/11/jquery-ajax-blobs-and-array-buffers/
                * https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
                * http://www.henryalgus.com/reading-binary-files-using-jquery-ajax/
                */

               $.ajaxTransport('+binary', function (options, originalOptions, jqXHR) {
                   var binaryTransport = null;
                   // check for conditions and support for blob / arraybuffer response type
                   if (window.FormData && ((options.dataType && (options.dataType === 'binary'))
                      || (options.data && ((window.ArrayBuffer && options.data instanceof ArrayBuffer)
                      || (window.Blob && options.data instanceof Blob))))) {

                       binaryTransport = {
                           // create new XMLHttpRequest
                           send: function (headers, completeCallback) {
                               // setup all variables
                               var xhr = new XMLHttpRequest(),
                                  url = options.url,
                                  type = options.type,
                                  async = options.async || true,
                                  // blob or arraybuffer. Default is blob
                                  dataType = options.responseType || 'blob',
                                  data = options.data || null,
                                  username = options.username || null,
                                  password = options.password || null;

                               xhr.addEventListener('load', function () {
                                   var responseData = {};
                                   responseData[options.dataType] = xhr.response;
                                   // make callback and send data
                                   completeCallback(xhr.status, xhr.statusText, responseData, xhr.getAllResponseHeaders());
                               });

                               xhr.open(type, url, async, username, password);

                               // setup custom headers
                               for (var index in headers) {
                                   if (headers.hasOwnProperty(index)) {
                                       xhr.setRequestHeader(index, headers[index]);
                                   }
                               }

                               xhr.responseType = dataType;
                               xhr.send(data);
                           },
                           abort: function () {
                               try {
                                   if (jqXHR) {
                                       var jqXhrToAbort = jqXHR;
                                       jqXHR = null;
                                       jqXhrToAbort.abort();
                                       jqXhrToAbort = null;
                                   }
                               }
                               catch (ex) {
                                   logger.info('Error trying to abort binary response. Error: ', ex.description);
                               }

                           }
                       };
                   }
                   return binaryTransport;
               });

           }


       };

       return jQueryUtil;

   }
);
