define('modularis/web/widgets/entityDropDownList',
   ['jquery', 'kendo', 'clientCache', 'logger', 'util'],
   function ($, kendo, clientCache, logger, util) {
       'use strict';
       var ui = kendo.ui, Widget = ui.Widget;

       var changeEventName = 'change',
          dataBoundEventName = 'dataBound';

       //#region Widget definition

       var EntityDropDownList = Widget.extend({

           _dropDownList: null,
           _value: null,
           _old: null,
           _items: [],
           _dataSource: null,
           init: function (element, options) {
               var that = this;
               Widget.fn.init.call(that, element, options);
               /*eslint-disable no-param-reassign*/
               if (!options.attrId) { options.attrId = util.newGuid(); }
               if (!options.attrName) { options.attrName = options.attrId; }

               that.createSelector();

               if (options.value) {
                   that.value(options.value);
               }
               /*eslint-enable no-param-reassign*/
           },

           options: {
               name: 'EntityDropDownList',
               optionLabel: 'Select entity',
               dropDownTextField: 'PropertyName',
               dropDownValueField: 'PropertyValue',
               value: null,
               enable: true,
               valuePrimitive: false,

               attrId: null,
               attrName: null,
               attrRequired: false,
               attrRequiredMsg: 'Select entity'
           },

           events: [changeEventName, dataBoundEventName],

           //This function must be overwritten to load the data for the widget.
           loadData: function (/*callback*/) {

           },
           //This function can be overritten to excecute additional code from the widget after the value has been changed.
           onChange: function () {
                
           },
           createSelector: function () {
               var that = this;

               if (!clientCache.isAuthenticated()) { return; }

               that.loadData(function (data, error) {

                   if (!util.success(data, error)) {
                       logger.error('Error loading data');
                       return;
                   }


                   if (data instanceof Array) {
                       that._items = data;
                   } else if (util.hasItems(data)) {
                       that._items = data.Items;
                   }

                   $(that.element).attr('id', that.options.attrId);
                   $(that.element).attr('name', that.options.attrName);

                   if (that.options.attrRequired) {
                       $(that.element).prop('required', true);
                       $(that.element).data('required-msg', that.options.attrRequiredMsg);
                   }
                   that._dataSource = new kendo.data.DataSource({
                       data: that._items,
                       schema: {
                           model: {
                               id: that.options.dropDownValueField
                           }
                       }
                   });

                   $(that.element).kendoDropDownList({
                       dataSource: that._dataSource,
                       dataTextField: that.options.dropDownTextField,
                       dataValueField: that.options.dropDownValueField,
                       optionLabel: that.options.optionLabel,
                       value: that._value,
                       enable: that.options.enable,
                       valuePrimitive: that.options.valuePrimitive,
                       //Events
                       change: $.proxy(that._handleChange, that),
                       dataBound: $.proxy(that._handleDataBound, that)
                   });
                   that._dropDownList = $(that.element).getKendoDropDownList();

               });
           },

           //Required for the value binding.
           value: function (value) {
               var that = this;
               if (!util.isDefined(value)) {
                   return that._value;
               }
               that._update(value);
               that._old = that._value;
               return undefined;
           },

           //Required for the enabled binding
           enable: function (enableValue) {
               var that = this;
               if (that._dropDownList) {
                   that._dropDownList.enable(enableValue);
               }
           },

           //Event handlers

           _handleChange: function () {
               var that = this;
               var selectedValue = that._dropDownList.value();
               that._changeValue(selectedValue);
           },

           _handleDataBound: function (event) {
               var that = this;
               if (!that.value() && !that.options.optionLabel) {
                   that._selectFirst();
               }
               this.trigger(dataBoundEventName, event);
           },

           _update: function (value) {
              var that = this;
              that._value = value;
              if (that._dropDownList) {
                 if (that.options.valuePrimitive || !that._value) {
                    that._dropDownList.value(that._value);
                 } else {
                    that._dropDownList.value(that._value[that.options.dropDownValueField]);
                 }
              }
           },
           _selectFirst: function () {
               var that = this;
               var dataSource = that._dropDownList ? that._dropDownList.dataSource : that._dataSource;
               if (dataSource.data().length === 0) { return; }
               var first = dataSource.at(0);
               if (that.options.valuePrimitive) {
                   that._changeValue(first[that.options.dropDownValueField]);
               } else {
                   that._changeValue(first);
               }
           },
           _changeValue: function (selectedValue) {
               var that = this;
               if (that.options.valuePrimitive) {
                   that._change(selectedValue);
               } else {
                   var dataSource = that._dropDownList ? that._dropDownList.dataSource : that._dataSource;
                   var selectedItem = dataSource.get(selectedValue);
                   that._change(selectedItem);
               }
           },
           _change: function (value) {
               var that = this;
               if (that._old !== value) {
                   that._update(value);
                   that._old = value;
                   that.onChange();
                   that.trigger(changeEventName);
               }
           }


       });

       //#endregion

       return {
           widgetClass: EntityDropDownList
       };

   }
);
