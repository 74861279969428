define('localization',
    ['jquery', 'kendo', 'RESTActivityWebServiceProxy', 'RESTCommandWebServiceProxy', 'clientStorage', 'configLoader', 'logger', 'util', 'entityHelper'],
    function ($, kendo, activityServiceProxy, commandWebServiceProxy, clientStorage, configLoader, logger, util, entityHelper) {
        'use strict';

        var keySeparator = '_',
            widgetResourceMinimumTokens = 3,
            dvLength = 2;

        //#region Enums

        var resourceEnum = {
            //string: 0,
            entity: 1,
            data: 2
        };

        Object.freeze(resourceEnum);

        //#endregion

        //#region Culture manipulation functions

        var loadPreferredCultures = function (callback) {
            //Load cultures from the server, and, optionally, establish the current one.
            activityServiceProxy.getPreferredCultures(function (cultures, error) {

                var parsedCultures = null;
                if (util.success(cultures, error)) {
                    parsedCultures = cultures.toLowerCase().split('|');
                    clientStorage.setValue('preferredCultures', parsedCultures);
                }
                util.notify(callback, parsedCultures, error);
            });

        };


        //#endregion

        //#region General translation functions

        var tryToLocalizeDV = function (key, resourceType, localizeDisplayValue) {
            var result = (resourceType === resourceEnum.entity && localizeDisplayValue && key.endsWith('DV'));
            return result;
        };

        //Function for default culture resolution
        var translateToDefaultCulture = function (key, resourceType, localizeDisplayValue) {
            var translation = null;
            var requireFunction = util.getRequireJSFunction();
            var moduleName = getResourceModuleName(null, resourceType);
            var defaultCultureModule = null;

            try {
                defaultCultureModule = requireFunction(moduleName);
            } catch (exception) {
                //module not found
            }

            translation = getTranslation(key, defaultCultureModule);
            if (!translation && defaultCultureModule && tryToLocalizeDV(key, resourceType, localizeDisplayValue)) {
                var keyWithoutDV = key.substr(0, key.length - dvLength);
                translation = getTranslation(keyWithoutDV, defaultCultureModule);
            }

            return translation;
        };

        //Function for neutral culture resolution
        var translateToNeutralCulture = function (key, culture, resourceType, localizeDisplayValue) {

            var translation = null;

            var requireFunction = util.getRequireJSFunction();
            var neutralCulture = getNeutralCulture(culture);

            var moduleName = getResourceModuleName(neutralCulture, resourceType);

            var neutralCultureModule = null;
            try {
                neutralCultureModule = requireFunction(moduleName);
            } catch (exception) {
                //module not found
            }

            translation = getTranslation(key, neutralCultureModule);
            if (!translation && neutralCultureModule && tryToLocalizeDV(key, resourceType, localizeDisplayValue)) {
                var keyWithoutDV = key.substr(0, key.length - dvLength);
                translation = getTranslation(keyWithoutDV, neutralCultureModule, localizeDisplayValue);
            }

            if (translation == null) {
                translation = translateToDefaultCulture(key, resourceType);
            }

            return translation;
        };

        //Callback for specific culture
        var translateToSpecificCulture = function (key, culture, resourceType, localizeDisplayValue) {

            var translation = null;

            var requireFunction = util.getRequireJSFunction();
            var moduleName = getResourceModuleName(culture, resourceType);

            var specificCultureModule = null;

            try {
                specificCultureModule = requireFunction(moduleName);
            } catch (exception) {
                //module not found
            }

            translation = getTranslation(key, specificCultureModule);
            if (!translation && specificCultureModule && tryToLocalizeDV(key, resourceType, localizeDisplayValue)) {
                var keyWithoutDV = key.substr(0, key.length - dvLength);
                translation = getTranslation(keyWithoutDV, specificCultureModule);
            }

            if (translation == null) {
                var neutralCulture = getNeutralCulture(culture);
                translation = translateToNeutralCulture(key, neutralCulture, resourceType, localizeDisplayValue);
            }

            return translation;

        };


        var translateKey = function (key, resourceType, localizeDisplayValue) {
            var culture = getCurrentCulture();
            //Defines the module name that must used for the given culture. This method checks that the module exists.
            var translation = null;

            //Check if it's the default culture
            if (culture == null || culture.toLowerCase() === localization.getDefaultCulture().toLowerCase()) {
                translation = translateToDefaultCulture(key, resourceType, localizeDisplayValue);
            } else {
                //Now, let's verify if it's a neutral culture
                if (culture.indexOf('-') < 0) {
                    translation = translateToNeutralCulture(key, culture, resourceType, localizeDisplayValue);
                } else {
                    //Finally...
                    translation = translateToSpecificCulture(key, culture, resourceType, localizeDisplayValue);
                }
            }

            return translation;

        };

        var getCurrentCulture = function () {
            var culture = clientStorage.getValue('culture');
            return culture;
        };

        var getTranslation = function (key, resource) {
            var translation = null;

            if (resource != null) {
                if (key in resource) {
                    translation = resource[key];
                }
            }

            return translation;
        };

        //#endregion

        //#region Widget translation functions

        var translateWidgets = function (culture, callback) {
            //callback is invoked with a boolean response object (true if translation was successful, false in other cases).

            if (configLoader.appConfig.localization.localizeWidget) {
                getWidgetTranslations(culture, function (widgetResources) {

                    var tokens = null;

                    //Process widget resources
                    for (var resource in widgetResources) {
                        if (widgetResources.hasOwnProperty(resource)) {
                            tokens = resource.split(keySeparator);

                            if (tokens.length < widgetResourceMinimumTokens) {
                                util.notify(callback, false, 'Resource string must have at least 3 parts splitted by ' + keySeparator);
                                return;
                            }

                            //if (tokens.length == 3) {
                            //    // 0 --> kendo, 1 --> widget name, 2 --> property name
                            //    kendo.ui[tokens[1]].prototype.options.messages[tokens[2]] = widgetResources[resource];
                            //} else {

                            //Resources in object with an unknown depth
                            if (kendo.ui[tokens[1]] != null) {
                                var object = kendo.ui[tokens[1]].prototype.options;

                                for (var index = 2; index < (tokens.length - 1); index++) {
                                    object = object[tokens[index]];
                                }
                                object[tokens[tokens.length - 1]] = widgetResources[resource];
                            }
                            //}
                        }
                    }

                    util.notify(callback, true);

                });
            } else {
                util.notify(callback, true);
            }
        };

        var getWidgetTranslationsForCulture = function (culture, callback) {
            //Get translations just for given culture.

            var requireFunction = util.getRequireJSFunction();
            var moduleName = getResourceModuleName(culture);
            requireFunction([moduleName],
                function (resource) {
                    var result = {};
                    for (var property in resource) {
                        if (property.startsWith('kendo')) {
                            result[property] = resource[property];
                        }
                    }
                    callback(result);

                },
                function () {
                    //Callback when the resource module does not exist.
                    callback({});
                }
            );
        };

        var getWidgetTranslations = function (culture, callback) {
            //Get translations for all cultures (default, neutral and specific).
            //Get widget resources for default culture
            var defaultCulture = localization.getDefaultCulture();
            getWidgetTranslationsForCulture(defaultCulture, function (widgetDefaultResources) {

                if (culture !== defaultCulture) {
                    //Resources for neutral culture
                    var neutralCulture = getNeutralCulture(culture);
                    getWidgetTranslationsForCulture(neutralCulture, function (widgetNeutralResources) {
                        $.extend(widgetDefaultResources, widgetNeutralResources);

                        if (culture.indexOf('-') > -1) {
                            //Widget resources for specific culture
                            getWidgetTranslationsForCulture(culture, function (widgetSpecificResources) {
                                $.extend(widgetDefaultResources, widgetSpecificResources);
                                util.notify(callback, widgetDefaultResources);
                            });
                            return;
                        }

                        util.notify(callback, widgetDefaultResources);
                    });
                    return;
                }

                util.notify(callback, widgetDefaultResources);
            });

        };

        //#endregion

        //#region Helper functions

        var getResourceModuleName = function (culture, resourceType) {
            /*eslint-disable no-param-reassign*/
            var result = '',
                resourcePath = '',
                baseResourcesName = '';

            switch (resourceType) {
                case resourceEnum.data:
                    resourcePath = configLoader.appConfig.localization.dataResourcePath;
                    break;
                case resourceEnum.entity:
                    resourcePath = configLoader.appConfig.localization.entityResourcePath;
                    break;
                case resourceEnum.string:
                    resourcePath = configLoader.appConfig.localization.stringResourcePath;
                    break;
                default:
                    resourcePath = configLoader.appConfig.localization.stringResourcePath;
            }

            baseResourcesName = configLoader.appConfig.localization.resourcesBasePath + resourcePath;

            if (!culture) {
                culture = '';
                baseResourcesName = baseResourcesName.replace('.', '');
            }

            result = String.format(baseResourcesName, culture);

            return result;
            /*eslint-enable no-param-reassign*/
        };

        var getNeutralCulture = function (culture) {
            var neutralCulture = culture;
            if (culture.indexOf('-') > -1) {

                neutralCulture = culture.substr(0, culture.indexOf('-'));
            }
            return neutralCulture;
        };

        var loadResources = function (culture) {
            var validateModulesInserver = configLoader.appConfig.localization.validateModulesInServer;
            var deferredLoadResources = $.Deferred();
            var requestedModulesNames = null;
            //check the availibity of the resources.
            if (validateModulesInserver) {
                var resourcesPaths = [
                    configLoader.appConfig.localization.dataResourcePath,
                    configLoader.appConfig.localization.entityResourcePath,
                    configLoader.appConfig.localization.stringResourcePath
                ];


                requestedModulesNames = getRequestedModuleNames(culture, resourcesPaths);
                requestedModulesNames = requestedModulesNames.concat(getRequestedKendoMessagesModuleNames(culture));
            }
            checkCulturaFiles(requestedModulesNames).done(function (checkedModules) {
                //Create a deferred collection to load all localization resources

                var deferredCollection = [];

                var deferredDataCollection = loadDataResources(culture, checkedModules);
                var deferredEntityCollection = loadEntityResources(culture, checkedModules);
                var deferredStringCollection = loadStringResources(culture, checkedModules);
                var deferredKendoCultureCollection = changeKendoCultureResources(culture, checkedModules);
                var deferredKendoMessagesCollection = loadKendoMessageResources(culture, checkedModules);

                deferredCollection = deferredCollection.concat(deferredDataCollection);
                deferredCollection = deferredCollection.concat(deferredEntityCollection);
                deferredCollection = deferredCollection.concat(deferredStringCollection);
                deferredCollection = deferredCollection.concat(deferredKendoCultureCollection);
                deferredCollection = deferredCollection.concat(deferredKendoMessagesCollection);

                deferredLoadResources.resolve(deferredCollection);
            });

            return deferredLoadResources;
        };

        var checkCulturaFiles = function (requestedModules) {
            var deferredCheckedModules = $.Deferred();

            if (requestedModules) {
                var webServerBaseUrl = util.getWebServerBaseUrl();

                var objectWithUrls = requestedModules.reduce(function (object, moduleName, index) {
                    object['Url' + index] = webServerBaseUrl + moduleName + '.js';
                    return object;
                }, {});

                var commandDefinition = {
                    processor: 'Modularis',
                    name: 'CheckWebFiles',
                    parameters: objectWithUrls
                };

                commandWebServiceProxy.executeSimpleCommand(commandDefinition, function (response) {
                    deferredCheckedModules.resolve(JSON.parse(response));
                });
            } else {
                deferredCheckedModules.resolve(false);
            }

            return deferredCheckedModules;
        };

        var isModuleAvailable = function (moduleName, checkedModules) {
            // If the project is not configured for validate the modules in the server, this funtion return true to force the front end to load the files
            if (!checkedModules) {
                return true;
            }

            var requireFunction = util.getRequireJSFunction();
            var baseUrl = requireFunction.toUrl('').split('?')[0];
            var effectiveModudeName = moduleName.startsWith('kendo') ? baseUrl + moduleName : moduleName;

            var isAvailable = false;
            for (var index = 0; index < checkedModules.length; index++) {
                var checkedModuleName = checkedModules[index].name.replace(window.location.origin + '/', '').replace('.js', '');
                if (checkedModuleName === effectiveModudeName) {
                    isAvailable = checkedModules[index].available;
                    break;
                }
            }
            return isAvailable;
        };

        var getRequestedModuleNames = function (culture, resourcePaths) {
            //Get a modulenames collection for specific, neutral and default localization resources by culture and path

            var requestedModules = [];
            for (var index = 0; index < resourcePaths.length; index++) {
                var resourcePath = resourcePaths[index];
                var cultureFormat = configLoader.appConfig.localization.resourcesBasePath + resourcePath;

                if (supportsCulture(culture)) {
                    var specificModuleName = String.format(cultureFormat, culture);

                    requestedModules.push(specificModuleName);
                }

                var neutralCulture = getNeutralCulture(culture);

                if (supportsCulture(neutralCulture)) {
                    var neutralModuleName = String.format(cultureFormat, neutralCulture);
                    requestedModules.push(neutralModuleName);
                }

                var defaultModuleName = String.format(cultureFormat, '').replace('.', '');

                requestedModules.push(defaultModuleName);
            }


            return requestedModules;
        };

        var loadDataResources = function (culture, checkedModules) {
            //Create a deferred collection for all data localization resources
            var deferredCollection = [];

            if (configLoader.appConfig.localization.loadDataResources === true) {
                deferredCollection = buildDeferredResourcesCollection(culture, configLoader.appConfig.localization.dataResourcePath, checkedModules);
            }

            return deferredCollection;
        };

        var loadEntityResources = function (culture, checkedModules) {
            //Create a deferred collection for all entity localization resources
            var deferredCollection = buildDeferredResourcesCollection(culture, configLoader.appConfig.localization.entityResourcePath, checkedModules);
            return deferredCollection;
        };

        var loadStringResources = function (culture, checkedModules) {
            //Create a deferred collection for all string localization resources
            var deferredCollection = buildDeferredResourcesCollection(culture, configLoader.appConfig.localization.stringResourcePath, checkedModules);
            return deferredCollection;
        };

        var buildDeferredResourcesCollection = function (culture, resourcePath, checkedModules) {
            //Build a deferred collection for specific, neutral and default localization resources by culture and path
            var requireFunction = util.getRequireJSFunction();
            var deferredDefaultResource = $.Deferred();
            var deferredCollection = [deferredDefaultResource];

            var cultureFormat = configLoader.appConfig.localization.resourcesBasePath + resourcePath;

            if (supportsCulture(culture)) {
                var deferredSpecificResource = $.Deferred();
                deferredCollection.concat(deferredSpecificResource);

                var specificModuleName = String.format(cultureFormat, culture);
                if (isModuleAvailable(specificModuleName, checkedModules)) {
                    requireFunction([specificModuleName], function () {
                        deferredSpecificResource.resolve(specificModuleName);
                    }, function () {
                        deferredSpecificResource.resolve(specificModuleName);
                    });
                }

            }

            var neutralCulture = getNeutralCulture(culture);

            if (supportsCulture(neutralCulture)) {
                var deferredNeutralResource = $.Deferred();
                deferredCollection.concat(deferredNeutralResource);

                var neutralModuleName = String.format(cultureFormat, neutralCulture);
                if (isModuleAvailable(neutralModuleName, checkedModules)) {
                    requireFunction([neutralModuleName], function () {
                        deferredNeutralResource.resolve(neutralModuleName);
                    }, function () {
                        deferredNeutralResource.resolve(neutralModuleName);
                    });
                }
            }

            var defaultModuleName = String.format(cultureFormat, '').replace('.', '');
            if (isModuleAvailable(defaultModuleName, checkedModules)) {
                requireFunction([defaultModuleName], function () {
                    deferredDefaultResource.resolve(defaultModuleName);
                }, function () {
                    deferredDefaultResource.resolve(defaultModuleName);
                });
            }

            return deferredCollection;
        };

        //#region Kendo Cultures

        var setKendoCulture = function (culture) {
            var normalizedCulture = culture;
            var position = normalizedCulture.indexOf('-');
            if (position > 0) {
                normalizedCulture = normalizedCulture.substr(0, position + 1) + normalizedCulture.substr(position + 1).toUpperCase();
            }
            kendo.culture(normalizedCulture);
        };

        var changeKendoCultureResources = function (culture, checkedModules) {
            //Change the kendo culture validating the specific, neutral and default culture
            var requireFunction = util.getRequireJSFunction();
            var cultureFormatPath = configLoader.appConfig.localization.kendoCulturePath;
            var deferredKendoCultures = $.Deferred();

            changeToSpecificKendoCultureResource(requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules);

            return [deferredKendoCultures];
        };

        var changeToSpecificKendoCultureResource = function (requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules) {

            var shouldChangeToNeutralKendo = true;
            //Change the kendo culture validating the specific and neutral culture
            if (supportsCulture(culture)) {
                var moduleName = String.format(cultureFormatPath, culture);
                if (isModuleAvailable(moduleName, checkedModules)) {
                    shouldChangeToNeutralKendo = false;

                    requireFunction([moduleName], function () {
                        setKendoCulture(culture);
                        deferredKendoCultures.resolve();
                    }, function () {
                        changeToNeutralKendoCultureResource(requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules);
                    });
                }
            }

            if (shouldChangeToNeutralKendo) {
                changeToNeutralKendoCultureResource(requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules);
            }
        };

        var changeToNeutralKendoCultureResource = function (requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules) {
            var shouldChangeToDefaultKendo = true;

            //Change the kendo culture validating the neutral and default culture
            var neutralCulture = getNeutralCulture(culture);

            if (supportsCulture(neutralCulture)) {
                var moduleName = String.format(cultureFormatPath, neutralCulture);
                if (isModuleAvailable(moduleName, checkedModules)) {
                    shouldChangeToDefaultKendo = true;

                    requireFunction([moduleName], function () {
                        setKendoCulture(neutralCulture);
                        deferredKendoCultures.resolve();
                    }, function () {
                        changeToDefaultKendoCultureResource(requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules);
                    });
                }

            }

            if (shouldChangeToDefaultKendo) {
                changeToDefaultKendoCultureResource(requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules);
            }
        };

        var changeToDefaultKendoCultureResource = function (requireFunction, culture, cultureFormatPath, deferredKendoCultures, checkedModules) {
            //Change the kendo culture validating the default culture
            var defaultCulture = localization.getDefaultCulture();

            if (supportsCulture(defaultCulture)) {

                var moduleName = String.format(cultureFormatPath, defaultCulture);
                if (isModuleAvailable(moduleName, checkedModules)) {
                    requireFunction([moduleName], function () {
                        setKendoCulture(defaultCulture);
                        deferredKendoCultures.resolve();
                    }, function () {
                        deferredKendoCultures.resolve();
                    });
                } else {
                    deferredKendoCultures.resolve();
                }
            } else {
                deferredKendoCultures.resolve();
            }
        };

        //#endregion

        //#region Kendo Messages

        var getRequestedKendoMessagesModuleNames = function (culture) {
            var kendoMessageModuleNames = [];
            var requireFunction = util.getRequireJSFunction();
            var baseUrl = requireFunction.toUrl('').split('?')[0];
            var messageFormatPath = baseUrl + configLoader.appConfig.localization.kendoMessagesPath;
            var cultureFormatPath = baseUrl + configLoader.appConfig.localization.kendoCulturePath;

            var moduleName = String.format(messageFormatPath, culture);
            var neutralCulture = getNeutralCulture(culture);
            var neutralCultureModuleName = String.format(messageFormatPath, neutralCulture);
            var defaultCulture = localization.getDefaultCulture();
            var defaultModuleName = String.format(messageFormatPath, defaultCulture);
            var moduleNameSpecificKendo = String.format(cultureFormatPath, culture);
            var moduleNameNeutralKendo = String.format(cultureFormatPath, neutralCulture);
            var moduleNameDefaultKendo = String.format(cultureFormatPath, defaultCulture);

            kendoMessageModuleNames.push(moduleName);
            kendoMessageModuleNames.push(neutralCultureModuleName);
            kendoMessageModuleNames.push(defaultModuleName);
            kendoMessageModuleNames.push(moduleNameSpecificKendo);
            kendoMessageModuleNames.push(moduleNameNeutralKendo);
            kendoMessageModuleNames.push(moduleNameDefaultKendo);



            return kendoMessageModuleNames;
        };

        var loadKendoMessageResources = function (culture, checkedModules) {
            //Load the kendo messages validating the specific, neutral and default culture
            var requireFunction = util.getRequireJSFunction();
            var messageFormatPath = configLoader.appConfig.localization.kendoMessagesPath;

            var deferredKendoMessages = $.Deferred();

            loadSpecificKendoMessageResource(requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules);

            return [deferredKendoMessages];
        };

        var loadSpecificKendoMessageResource = function (requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules) {
            //Load the kendo messages validating the specific and neutral culture
            var loadNeutralKendoMessage = true;
            if (supportsCulture(culture)) {
                var moduleName = String.format(messageFormatPath, culture);

                if (isModuleAvailable(moduleName, checkedModules)) {
                    loadNeutralKendoMessage = false;
                    requireFunction([moduleName], function () {
                        deferredKendoMessages.resolve();
                    }, function () {
                        loadNeutralKendoMessageResource(requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules);
                    });
                }

            }

            if (loadNeutralKendoMessage) {
                loadNeutralKendoMessageResource(requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules);
            }
        };

        var loadNeutralKendoMessageResource = function (requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules) {
            //Load the kendo messages validating the neutral and default culture
            var neutralCulture = getNeutralCulture(culture);
            var loadDefaultKendoMessage = true;
            if (supportsCulture(neutralCulture)) {
                var moduleName = String.format(messageFormatPath, neutralCulture);

                if (isModuleAvailable(moduleName, checkedModules)) {
                    loadDefaultKendoMessage = false;
                    requireFunction([moduleName], function () {
                        deferredKendoMessages.resolve();
                    }, function () {
                        loadDefaultKendoMessageResource(requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules);
                    });
                }
            }

            if (loadDefaultKendoMessage) {
                loadDefaultKendoMessageResource(requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules);
            }
        };

        var loadDefaultKendoMessageResource = function (requireFunction, culture, messageFormatPath, deferredKendoMessages, checkedModules) {
            //Load the kendo messages validating the default culture
            var defaultCulture = localization.getDefaultCulture();

            if (supportsCulture(defaultCulture)) {
                var moduleName = String.format(messageFormatPath, defaultCulture);
                if (isModuleAvailable(moduleName, checkedModules)) {
                    requireFunction([moduleName], function () {
                        deferredKendoMessages.resolve();
                    }, function () {
                        deferredKendoMessages.resolve();
                    });
                }
                else {
                    deferredKendoMessages.resolve();
                }
            } else {
                deferredKendoMessages.resolve();
            }
        };

        //#endregion

        var replaceSeparator = function (string) {
            return string.replace(/\./g, keySeparator);
        };

        var supportsCulture = function (culture) {

            if (configLoader.appConfig.localization.allowDefaultCultureOverride === true) { return true; }

            //Indicate if the culture is available
            for (var index = 0; index < configLoader.appConfig.localization.supportedCultures.length; index++) {
                var supportedCulture = configLoader.appConfig.localization.supportedCultures[index];
                if (supportedCulture.toLowerCase() === culture.toLowerCase()) {
                    return true;
                }
            }
            return false;
        };

        //#endregion

        var localization = {

            //The character that separate the different parts of a key.
            keySeparator: keySeparator,

            /**
             * Finds a translation for the given key.
             * 
             * @memberof modularis.localization
             * @param {string} key - Key to find in the entity translation files if the key starts with the system name, otherwise it will be searched in the string resource files.
             * @returns {string}
             */
            translate: function (key) {
                var systemName = replaceSeparator(configLoader.appConfig.systemName);
                var result = null;
                if (key.startsWith(systemName)) {
                    result = this.translateEntityResource(key);
                } else {
                    result = this.translateStringResource(key);
                }
                return result;
            },
            /**
             * Finds a translation for the given property.
             * 
             * @memberof modularis.localization
             * @param {string} entityDefID - The full class name of the entity to which the property belongs.
             * @param {string} propertyName - Name of property to localize.
             * @returns {string}
             */
            translatePropertyName: function (entityDefID, propertyName) {
                var key = this.getPropertyNameKey(entityDefID, propertyName);
                return this.translateEntityResource(key, true);
            },
            /**
             * Finds a translation for the given entity full name.
             * 
             * @memberof modularis.localization
             * @param {string} entityDefID - The full class name of the entity.
             * @returns {string}
             */
            translateEntityName: function (entityDefID) {
                var key = this.getEntityNameKey(entityDefID);
                return this.translateEntityResource(key);
            },
            /**
             * Finds a translation for the given entity collection full name.
             * 
             * @memberof modularis.localization
             * @param {string} entityCollectionDefID - The full class name of the entity collection.
             * @returns {string}
             */
            translateEntityCollectionName: function (entityCollectionDefID) {
                var key = this.getEntityCollectionNameKey(entityCollectionDefID);
                return this.translateEntityResource(key);
            },
            /**
             * Finds a translation for the given key in the data resource files.
             * 
             * @memberof modularis.localization
             * @param {string} key - Data key to localize.
             * @returns {string}
             */
            translateDataResource: function (key) {
                var translation = translateKey(key, resourceEnum.data);
                return translation;
            },
            /**
             * Finds a translation for the given key in the entity resource files.
             * 
             * @memberof modularis.localization
             * @param {string} key - Entity, EntityCollection or property key to localize.
             * @param {booleam} localizeDisplayValue - Indicate whether the function should try to find an alternate translation in case the original tranlation is not found.
             * This parameter only applies to properties.
             * @returns {string}
             */
            translateEntityResource: function (key, localizeDisplayValue) {
                var translation = translateKey(key, resourceEnum.entity, localizeDisplayValue);
                return translation;
            },
            /**
             * Finds a translation for the given key in the string resource files.
             * 
             * @memberof modularis.localization
             * @param {string} key - String key to localize.
             * @returns {string}
             */
            translateStringResource: function (key) {
                var translation = translateKey(key, resourceEnum.string);
                return translation;
            },
            /**
             * Finds a translation for the data in the given entity collection.
             * 
             * @memberof modularis.localization
             * @param {modularis.EntityCollectionBase} entityCollection - Entity collection that contains the data to localize.
             * @param {string[]} propertyNames - The name of the properties to localize using the data resource files.
             * @returns {modularis.EntityCollectionBase} Translated entity collection data.
             */
            translateEntityCollectionData: function (entityCollection, propertyNames) {
                if (propertyNames && entityCollection) {
                    for (var colIndex = 0; colIndex < propertyNames.length; colIndex++) {

                        var entityItems = entityCollection.Items;
                        for (var entityIndex = 0; entityIndex < entityItems.length; entityIndex++) {
                            var key = entityItems[entityIndex][propertyNames[colIndex]];
                            if (key) {
                                var newValue = this.translateDataResource(key);
                                if (newValue) {
                                    entityItems[entityIndex][propertyNames[colIndex]] = newValue;
                                }
                            }
                        }
                    }
                }

                return entityCollection;
            },
            /**
             * Finds a translation for the data in the given entity.
             * 
             * @memberof modularis.localization
             * @param {modularis.EntityBase} entity - Entity that contains the data to localize.
             * @param {string[]} propertyNames - The name of the properties to localize using the data resource files.
             * @returns {modularis.EntityBase} Translated entity data.
             */
            translateEntityData: function (entity, propertyNames) {
                var entityCollection = this.translateEntityCollectionData({ Items: [entity] }, propertyNames);

                var entityResult = null;

                if (entityCollection.Items.length > 0) {
                    entityResult = entityCollection.Items[0];
                }

                return entityResult;
            },
            /**
             * Define translation key based on entityDefID.
             * 
             * @memberof modularis.localization
             * @param {string} entityDefID - The full class name of the entity.
             * @returns {string}
             */
            getEntityNameKey: function (entityDefID) {
                var key = replaceSeparator(entityDefID);
                return key;
            },
            /**
             * Define translation key based on entityDefID.
             * 
             * @memberof modularis.localization
             * @param {string} entityDefID - The full class name of the entity collection.
             * @returns {string}
             */
            getEntityCollectionNameKey: function (entityDefID) {
                var key = entityHelper.getEntityCollectionNameByTypeName(entityDefID);
                var entityDef = replaceSeparator(entityDefID);
                var indexOf = entityDef.lastIndexOf(this.keySeparator);

                key = entityDef.substring(0, indexOf) + this.keySeparator + key;

                return key;
            },
            /**
             * Define translation key based on entityDefID and property name.
             * 
             * @memberof modularis.localization
             * @param {string} entityDefID - The full class name of the entity.
             * @param {string} propertyName - Property name to localize.
             * @returns {string}
             */
            getPropertyNameKey: function (entityDefID, propertyName) {
                var key = replaceSeparator(entityDefID) + this.keySeparator + replaceSeparator(propertyName);
                return key;
            },
            /* functions for culture manipulation */
            /**
             * Gets the cultures preferred by the user based on the browser language settings.
             * 
             * @memberof modularis.localization
             * @returns {string[]}
             */
            getPreferredCultures: function () {
                var storageCultures = clientStorage.getValue('preferredCultures');
                return storageCultures;
            },
            /**
             * Gets the current culture used by the app.
             * 
             * @memberof modularis.localization
             * @returns {string}
             */
            getCurrentCulture: function () {
                var culture = getCurrentCulture();
                return culture;
            },
            /**
             * Returns a default culture, necessary in some cases where the current culture wasn't established.
             * 
             * @memberof modularis.localization
             * @returns {string}
             */
            getDefaultCulture: function () {
                var defaultCulture = 'en-US';
                if (configLoader.containsConfigValue('localization') && util.isDefined(configLoader.appConfig.localization.defaultCulture)) {
                    defaultCulture = configLoader.appConfig.localization.defaultCulture;
                }
                return defaultCulture;
            },
            /**
             * Set a new culture to localized widgets, strings, entities, data and messages of the entire system.
             * 
             * @memberof modularis.localization
             * @param {string} newCulture - The new culture that wants to load.
             * @param {requestCallback} callback -  The function to be executed when the process is completed.
             */
            setCurrentCulture: function (newCulture, callback) {
                clientStorage.setValue('culture', newCulture);

                loadResources(newCulture).done(function (deferredResourceCollection) {
                    $.when.apply($, deferredResourceCollection).always(function () {
                        //Translate widgets after culture change
                        translateWidgets(newCulture, function () {
                            util.notify(callback, newCulture);
                        });
                    });
                });
            },
            initialize: function (callback) {

                var that = this;

                var initWithDefaultCulture = function () {
                    var culture = that.getDefaultCulture();
                    that.setCurrentCulture(culture, callback);
                };

                //Check if culture must be based on browser's configuration or if it's defined with the app settings
                if (configLoader.appConfig.localization.allowDefaultCultureOverride === true) {
                    loadPreferredCultures(function (parsedCultures, loadCulturesError) {
                        if (util.success(parsedCultures, loadCulturesError)) {
                            that.setCurrentCulture(parsedCultures[0], callback);
                        } else {
                            logger.info('It looks like the backend services are offline. Please contact your system administration for more information');
                            initWithDefaultCulture(callback);
                        }
                    });
                } else {
                    initWithDefaultCulture(callback);
                }

            },
            /**
             * Clear current culture.
             * 
             * @memberof modularis.localization
             */
            clearSettings: function () {
                clientStorage.removeValue('culture');
                clientStorage.removeValue('preferredCultures');

            }

        };

        return localization;


    }
);
