define('modularisGeneral/browsingContextHelper',
   ['RESTCommandWebServiceProxy', 'clientCache', 'clientStorage', 'configLoader', 'util'],
   function (commandServiceProxy, clientCache, clientStorage, configLoader, modularisUtil) {
       'use strict';

       var browserLocalStorage = window.localStorage;

       var restorePrefix = 'restore_',
          createContextKey = restorePrefix + 'createContext',
          restoredRouteKey = restorePrefix + 'routeRestored';

       var browsingContextHelper = {

           restoreBrowsingContext: function (route, callback) {

               //Check if the view is being loaded on a new window.
               var shouldRestore = false;
               if (clientStorage.isLocalStorageAvailable()) {
                   var createContext = browserLocalStorage.getItem(createContextKey);
                   shouldRestore = createContext && this.isNewBrowsingContext(route);
               }

               if (shouldRestore) {

                   //Get keys
                   var index, length,
                        itemValue, keys = [];
                   for (index = 0, length = browserLocalStorage.length; index < length; index++) {
                       keys.push(browserLocalStorage.key(index));
                   }

                   //Put keys back in the clientStorage object.
                   for (index = 0, length = keys.length; index < length; index++) {

                       var key = keys[index];

                       if (key !== createContextKey && key.startsWith(restorePrefix)) {
                           var tempValue = browserLocalStorage.getItem(key);
                           try {
                               itemValue = JSON.parse(tempValue);
                           } catch (error) {
                               itemValue = tempValue;
                           }

                           var clientStorageKey = key.replace(restorePrefix, '');
                           clientStorage.setValue(clientStorageKey, itemValue);
                           browserLocalStorage.removeItem(key);
                       }
                   }

                   //Save a setting to indicate the route that was opened in the new tab.
                   clientStorage.setValue(restoredRouteKey, route.name);

                   this.cloneSession(callback);
                   return;
               }
               modularisUtil.notify(callback);

           },

           prepareForNewBrowsingContext: function (router, route) {

               var continueViewLoading = true;
               if (clientStorage.isLocalStorageAvailable()) {

                   var createContextKeyExists = browserLocalStorage.getItem(createContextKey) != null;

                   var restoredRouteValue = clientStorage.getValue(restoredRouteKey);
                   var contextAlreadyRestored = (restoredRouteValue != null) && (restoredRouteValue === route.name);

                   if (!createContextKeyExists && !contextAlreadyRestored) {
                       browserLocalStorage.setItem(createContextKey, true);

                       //Move settings temporarily from the Modularis clientCache to the browser's localStorage
                       var keys = clientStorage.getKeys();

                       for (var index = 0; index < keys.length; index++) {
                           var key = keys[index];

                           if (key !== restoredRouteKey) {
                               var value = clientStorage.getValue(key);

                               if (typeof (value) === 'object') { value = JSON.stringify(value); }

                               browserLocalStorage.setItem(restorePrefix + key, value);
                           }

                       }

                       continueViewLoading = false;
                       window.open(window.location.href, '_blank');

                       //Restore current context to the previous URL.
                       router.navigate('#:back');
                   } else {
                       browserLocalStorage.removeItem(createContextKey);
                       continueViewLoading = true;
                   }
               }

               return continueViewLoading;
           },

           isNewBrowsingContext: function (route) {
               return ('newBrowsingContext' in route) && route.newBrowsingContext;
           },

           cloneSession: function (callback) {
               //Check if the session must cloned.
               var browsingContextSettings = configLoader.appConfig.newBrowsingContext;
               if (browsingContextSettings.cloneSession && browsingContextSettings.cloneSessionCommand) {

                   var command = {
                       processor: browsingContextSettings.cloneSessionCommand.processor,
                       name: browsingContextSettings.cloneSessionCommand.name,
                       parameters: {
                           SessionID: clientCache.getSessionID(),
                           CustomerID: clientCache.getActiveCustomerID(),
                           DeviceName: clientCache.getDeviceName()
                       }
                   };

                   commandServiceProxy.executeSimpleCommand(command, function (retrievedSession, sessionError) {

                       if (modularisUtil.success(retrievedSession, sessionError)) {
                           clientCache.setSession(retrievedSession);
                       }
                       modularisUtil.notify(callback, retrievedSession, sessionError);

                   }, 'json');
                   return;
               }

               modularisUtil.notify(callback);

           }

       };
       return browsingContextHelper;
   }
);
