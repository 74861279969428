define('modularis/web/widgets/asyncValidator',
    ['jquery', 'kendo', 'util', 'enums', 'modularis/web/widgets/modularisValidator'],
    function ($, kendo, util, enums, modularisValidator) {
        'use strict';
        /*eslint new-cap:0*/
        var Validator = modularisValidator.widgetClass;

        //Get the DOM object in case the given field is a jQuery object.
        var getDOMField = function (inputField) {
            return inputField instanceof $ ? inputField.get(0) : inputField;
        };

        var AsyncValidator = Validator.extend({

            _currentValidations: [],
            _promises: [],

            init: function (element, options) {
                var that = this;
                Validator.fn.init.call(that, element, options);
            },

            options: {
                name: 'AsyncValidator'
            },

            registerValidation: function (validationName, inputField, currentState) {
                var that = this;

                var state = currentState;
                if (!state) {
                    state = enums.validationState.checking;
                }

                var deferred = $.Deferred(),
                    promise = null;
                var validation = that.getValidation(validationName, inputField);
                //Check if the validation already exists
                if (validation) {
                    validation.state = state;
                    promise = validation.promise;
                }else {
                    that._currentValidations.push({
                        name: validationName,
                        input: getDOMField(inputField),
                        state: state,
                        deferred: deferred
                    });
                    promise = deferred.promise();
                    that._promises.push(promise);
                }                
                return promise;
            },

            notifyValidationResult: function (callback) {
                var that = this;
                $.when.apply($, that._promises).always(function () {
                    var validationResult = true,
                        asyncRulesCount = 0;
                    if (arguments.length > 0) {
                        for (var index = 0; index < arguments.length && validationResult; index++) {
                            validationResult = arguments[index].result;
                            asyncRulesCount++;
                        }
                    }
                    util.notify(callback, { valid: validationResult, rulesCount: asyncRulesCount });
                });
            },

            getValidation: function (validationName, inputField) {
                var that = this;
                var result = null,
                    input = getDOMField(inputField);
                for (var index = 0; (index < that._currentValidations.length) && !result; index++) {
                    var validation = that._currentValidations[index];
                    if (validation.name === validationName && validation.input === input) {
                        result = validation;
                    }
                }
                return result;
            },

            getValidationState: function (validationName, inputField) {
                var validation = this.getValidation(validationName, inputField);
                var result = validation ? validation.state : null;
                return result;
            },

            validationFailed: function (validationName, inputField) {
                var input = getDOMField(inputField);
                var validation = this.getValidation(validationName, input);
                validation.state = enums.validationState.invalid;
                validation.deferred.reject({ validationName: validationName, input: input, result: false });
            },

            validationCompleted: function (validationName, inputField) {
                var input = getDOMField(inputField);
                var validation = this.getValidation(validationName, input);
                validation.state = enums.validationState.valid;
                validation.deferred.resolve({ validationName: validationName, input: input, result: true });
            },

            removeValidation: function (validationName, inputField) {
                var that = this;
                var validationIndex = -1,
                    input = getDOMField(inputField);
                for (var index = 0; (index < that._currentValidations.length) && (validationIndex < 0); index++) {
                    var validation = that._currentValidations[index];
                    if (validation.name === validationName && validation.input === input) {
                        validationIndex = index;
                    }
                }

                if (validationIndex >= 0) {
                    that._currentValidations.splice(validationIndex, 1);
                    that._promises.splice(validationIndex, 1);
                }

            },

            mapStateToBoolean: function (validationName, inputField) {
                var validation = this.getValidation(validationName, inputField);
                var result = validation && validation.state === enums.validationState.valid;
                return result;
            }

        });

        return {
            widgetClass: AsyncValidator
        };

    }
);
