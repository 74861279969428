define('modularis/web/widgets/binders/maxBinder',
   ['jquery', 'kendo', 'util'],
   function ($, kendo, util) {
       'use strict';

       var maxBinderContainer = {

           init: function () {
               /*eslint no-param-reassign: 0*/

               kendo.data.binders.widget.max = kendo.data.Binder.extend({
                   init: function (widget, bindings, options) {
                       kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);
                   },
                   refresh: function () {
                       var that = this;

                       var widget = kendo.widgetInstance($(that.element));

                       if (util.isFunction(widget.max)) {
                           var value = that.bindings.max.get();
                           widget.max(value);
                       }
                   }
               });
           }

       };

       return maxBinderContainer;
   }
);
