define('modularis/entity/dynamic/dynamicEntity',
    ['jquery', 'modularis/entity/common', 'entityBase', 'entityCollectionBase', 'enums'],
    function ($, modelCommon, baseEntity, entityCollectionBase, enums) {
        'use strict';
        var types = enums.propertyTypes;
        var propertyDefs = [
            { name: 'DynamicEntityID', type: types.typeString },
            { name: 'EntityName', type: types.typeString },
            { name: 'SessionID', type: types.typeString },
            { name: 'EntityDefID', type: types.typeString },
            { name: 'Timestamp', type: types.typeTimeStamp }
        ];

        var assemblyName = modelCommon.AssemblyName;
        var defaultEntitySerializationMetadata = {
            AssemblyName: assemblyName,
            Culture: null,
            Name: 'DynamicEntity',
            Type: 'Entity',
            AllowNulls: false,
            Dirty: true,
            EditMode: enums.editModes.insert,
            Valid: true,
            Attributes: [],
            Errors: []
        };

        var EntityBase = baseEntity.EntityBase;
        var EntityCollectionBase = entityCollectionBase.EntityCollectionBase;

        var DynamicEntity = function () {
            EntityBase.call(this, defaultEntitySerializationMetadata, propertyDefs, 'UserID.SystemName.KeyName');

            $.extend(this._propertyValues, {
                DynamicEntityID: null,
                EntityName: null,
                SessionID: null,
                EntityDefID: null,
                Timestamp: null
            });
        };

        //Inheritance
        DynamicEntity.prototype = new EntityBase();
        DynamicEntity.prototype.constructor = DynamicEntity;

        DynamicEntity.prototype._setPropertyValue = function (propertyName, propertyValue, changeFlag) {
            var that = this;

            EntityBase.prototype._setPropertyValue.call(this, propertyName, propertyValue, changeFlag);

            if (!that._serializationMetadata.Dirty) {
                that._serializationMetadata.Dirty = true;
            }
        };

        Object.defineProperties(DynamicEntity.prototype, {

            'DynamicEntityID': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('DynamicEntityID');
                },
                set: function (newKeyName) {
                    this.setPropertyValue('DynamicEntityID', newKeyName);
                }
            },

            'EntityName': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('EntityName');
                },
                set: function (newSystemName) {
                    this.setPropertyValue('EntityName', newSystemName);
                }
            },

            'SessionID': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('SessionID');
                },
                set: function (newUserID) {
                    this.setPropertyValue('SessionID', newUserID);
                }
            },

            'EntityDefID': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('EntityDefID');
                },
                set: function (newValue) {
                    this.setPropertyValue('EntityDefID', newValue);
                }
            },

            'Timestamp': {
                enumerable: true,
                get: function () {
                    return this.getPropertyValue('Timestamp');
                },
                set: function (newTimestamp) {
                    this.setPropertyValue('Timestamp', newTimestamp);
                }
            }
        });

        //<Accelerator.Custom.Start>

        //Object.defineProperty(Department.prototype, 'PropertyName', { value: 'propertyValue' });

        //<Accelerator.Custom.End>

        // collection constructor
        var DynamicEntities = function () {

            EntityCollectionBase.call(this, assemblyName, 'DynamicEntities', 'Modularis.Dynamic.Entity.DynamicEntities');

        };

        //Inheritance
        DynamicEntities.prototype = new EntityCollectionBase();
        DynamicEntities.prototype.constructor = DynamicEntities;

        return {
            DynamicEntity: DynamicEntity,
            DynamicEntities: DynamicEntities
        };

    }
);
