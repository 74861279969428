define('modularis/i18n/translators/valueTranslator',
    [],function () {
        'use strict';

        var ValueTranslator = function () {
            var that = this;
            that.execute = function (options, localization) {
                if (typeof (options.element.val) === 'function') {
                    var translationResult = localization.translate(options.key);

                    if (translationResult == null) {
                        return false;
                    }
                    options.element.val(translationResult);
                }

                return true;
            };
        };

        return ValueTranslator;
    });
