define('RESTActivityWebServiceProxy',
    ['jquery', 'RESTWebServiceProxy', 'objectBuilder', 'clientCache', 'util', 'enums', 'logger'],
    function ($, proxyBase, objectBuilder, clientCache, util, enums, logger) {
        'use strict';
        /*eslint-disable no-param-reassign*/

        var optionsEnum = enums.options;

        var validateHandlerParameters = function (params, defultValue) {

            if (!params) {
                params = defultValue;
            }
            return params;
        };

        var updateParameterName = function (parameterName) {
            return parameterName[0].toUpperCase() + parameterName.substring(1);
        };

        var getRequestParameters = function (params) {
            var newParams = {};

            for (var propertyName in params) {
                if (params.hasOwnProperty(propertyName)) {
                    var newPropertyName = updateParameterName(propertyName);
                    newParams[newPropertyName] = params[propertyName];
                }
            }

            return newParams;
        };

        /**
         * Modularis Activity Service Proxy
         * @namespace activityServiceProxy
         * @memberof modularis.proxy
         */

        var RESTActivityWebServiceProxy = function (serviceName) {
            var that = this;
            proxyBase.RESTWebServiceProxy.call(that, serviceName);

            var convertParameterValues = function (parameterValues, isQueryDefParam) {
                var result = parameterValues;
                if (util.isArray(parameterValues)) {
                    result = '';
                    for (var index in parameterValues) {
                        if (parameterValues.hasOwnProperty(index)) {
                            result += parameterValues[index];
                            if (index < (parameterValues.length - 1)) {
                                result += isQueryDefParam ? '|' : '@@##@@';
                            }
                        }
                    }
                }
                return result;
            };

            that._sendActivityServiceRequest = function (method, params, callback, responseDataType) {
                var cancel = that._invokeBeforeHandlers(method, params);

                if (!cancel) {
                    if (params.parameterValues) {
                        params.parameterValues = convertParameterValues(params.parameterValues, false);
                    }
                    if (params.queryDefParameterValues) {
                        params.queryDefParameterValues = convertParameterValues(params.queryDefParameterValues, true);
                    }
                    params = getRequestParameters(params);
                    that.sendJSONRequest(method, params, that._afterCallback(method, params, callback), responseDataType);
                } else {
                    logger.info('Action canceled:' + method);
                    that._afterCallback(method, params, callback);
                }
            };

            //#region CheckSecurity

            /**
             * @callback modularis.proxy.activityServiceProxy~checkSecurityEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {number} eventData.activityID - The activity to check.
             * @param {modularis.EntityBase} eventData.entity - The entity hierarchy to check security on.
             * @param {boolean} eventData.checkChildren - Checks all the child entities security if true. Otherwise, it only checks the top-level entity.
             * @param {modularis.EntityBase} eventData.responseObject - Entity. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeCheckSecurity event.
             * @name onBeforeCheckSecurity
             * @function
             * @param {modularis.proxy.activityServiceProxy~checkSecurityEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeCheckSecurity = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.CheckSecurity, handler);
            };

            /**
             * Binds to the afterCheckSecurity event.
             * @name onAfterCheckSecurity
             * @function
             * @param {modularis.proxy.activityServiceProxy~checkSecurityEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterCheckSecurity = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.CheckSecurity, handler);
            };

            /**
             * Unbinds from to the beforeCheckSecurity event.
             * @name unbindBeforeCheckSecurity
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeCheckSecurity = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.CheckSecurity);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterCheckSecurity event.
             * @name unbindAfterCheckSecurity
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterCheckSecurity = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.CheckSecurity);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Checks if the user identified by the current session has access to perform the activity on the given entity.
             * @name checkSecurity
             * @function
             * @param {number} activityID - The activity to check.
             * @param {modularis.EntityBase} entity - The entity hierarchy to check security on.
             * @param {boolean} checkChildren - Checks all the child entities security if true. Otherwise, it only checks the top-level entity.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.checkSecurity = function (activityID, entity, checkChildren, callback) {

                var method = enums.methodNames.CheckSecurity;

                var params = {
                    activityID: activityID,
                    checkChildren: checkChildren,
                    customerID: that._getActiveServer().customerID,
                    payload: entity,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region CheckSecurityCollection

            /**
             * @callback modularis.proxy.activityServiceProxy~checkSecurityCollectionEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {number} eventData.activityID - The activity to check.
             * @param {modularis.EntityCollectionBase} eventData.entityCollection - The entity hierarchy to check security on.
             * @param {boolean} eventData.checkChildren - Checks all the child entities security if true. Otherwise, it only checks the top-level entity.
             * @param {modularis.EntityCollectionBase} eventData.responseObject - Entity collection. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeCheckSecurityCollection event.
             * @name onBeforeCheckSecurityCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~checkSecurityCollectionEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeCheckSecurityCollection = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.CheckSecurityCollection, handler);
            };

            /**
             * Binds to the afterCheckSecurityCollection event.
             * @name onAfterCheckSecurityCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~checkSecurityCollectionEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterCheckSecurityCollection = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.CheckSecurityCollection, handler);
            };

            /**
             * Unbinds from to the beforeCheckSecurityCollection event.
             * @name unbindBeforeCheckSecurityCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeCheckSecurityCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.CheckSecurityCollection);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterCheckSecurityCollection event.
             * @name unbindAfterCheckSecurityCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterCheckSecurityCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.CheckSecurityCollection);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Checks if the user identified by the current session has access to perform the activity on the given entity collection.
             * @name checkSecurityCollection
             * @function
             * @param {number} activityID - The activity to check
             * @param {modularis.EntityCollectionBase} entityCollection - The entity hierarchy to check security on.
             * @param {boolean} checkChildren - Checks all the child entities security if true. Otherwise, it only checks the top-level entity.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.checkSecurityCollection = function (activityID, entityCollection, checkChildren, callback) {

                var method = enums.methodNames.CheckSecurityCollection;

                var params = {
                    activityID: activityID,
                    checkChildren: checkChildren,
                    austomerID: that._getActiveServer().customerID,
                    payload: entityCollection,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region DeleteEntity

            /**
             * @callback modularis.proxy.activityServiceProxy~deleteEntityEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {modularis.EntityBase} eventData.entity - The deleted entity instance.
             * @param {boolean} eventData.affectChildren - Indicates whether to process child entities or not.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.Entity} eventData.responseObject - Entity. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeDeleteEntity event.
             * @name onBeforeDeleteEntity
             * @function
             * @param {modularis.proxy.activityServiceProxy~deleteEntityEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeDeleteEntity = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.DeleteEntity, handler);
            };

            /**
             * Binds to the afterDeleteEntity event.
             * @name onAfterDeleteEntity
             * @function
             * @param {modularis.proxy.activityServiceProxy~deleteEntityEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterDeleteEntity = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.DeleteEntity, handler);
            };

            /**
             * Unbinds from to the beforeDeleteEntity event.
             * @name unbindBeforeDeleteEntity
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeDeleteEntity = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.DeleteEntity);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterDeleteEntity event.
             * @name unbindAfterDeleteEntity
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterDeleteEntity = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.DeleteEntity);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * The entity instance to delete.
             * @name deleteEntity
             * @function
             * @param {modularis.EntityBase} entity - The deleted entity instance.
             * @param {boolean} affectChildren - Indicates whether to process child entities or not.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.deleteEntity = function (entity, affectChildren, options, callback) {

                var method = enums.methodNames.DeleteEntity;

                var params = {
                    affectChildren: affectChildren,
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options),
                    payload: entity,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback, 'text');
            };

            //#endregion

            //#region DeleteCollection

            /**
             * @callback modularis.proxy.activityServiceProxy~deleteCollectionEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {modularis.EntityCollectionBase} eventData.entityCollection - The deleted entity collection.
             * @param {boolean} eventData.affectChildren - Indicates whether to process child entities or not.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.EntityCollectionBase} eventData.responseObject - Entity collection. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeDeleteCollection event.
             * @name onBeforeDeleteCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~deleteCollectionEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeDeleteCollection = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.DeleteCollection, handler);
            };

            /**
             * Binds to the afterDeleteCollection event.
             * @name onAfterDeleteCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~deleteCollectionEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterDeleteCollection = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.DeleteCollection, handler);
            };

            /**
             * Unbinds from to the beforeDeleteCollection event.
             * @name unbindBeforeDeleteCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeDeleteCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.DeleteCollection);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterDeleteCollection event.
             * @name unbindAfterDeleteCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterDeleteCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.DeleteCollection);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Deletes every entity of the entity collection from the application database.
             * @name deleteCollection
             * @function
             * @param {modularis.EntityCollectionBase} entityCollection - The deleted entity collection.
             * @param {boolean} affectChildren - Indicates whether to process child entities or not.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.deleteCollection = function (entityCollection, affectChildren, options, callback) {

                var method = enums.methodNames.DeleteCollection;

                var params = {
                    affectChildren: affectChildren,
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options),
                    payload: entityCollection,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback, 'text');
            };

            //#endregion

            //#region Duplicate

            /**
            * @callback modularis.proxy.activityServiceProxy~duplicateEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {modularis.EntityBase} eventData.entity - The deleted entity instance.
            * @param {boolean} eventData.affectChildren - Indicates whether to process child entities or not.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {modularis.Entity} eventData.responseObject - Entity. Not available in the before event.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeDuplicate event.
             * @name onBeforeDuplicate
             * @function
             * @param {modularis.proxy.activityServiceProxy~duplicateEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeDuplicate = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.Duplicate, handler);
            };

            /**
             * Binds to the afterDuplicate event.
             * @name onAfterDuplicate
             * @function
             * @param {modularis.proxy.activityServiceProxy~duplicateEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterDuplicate = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.Duplicate, handler);
            };

            /**
             * Unbinds from to the beforeDuplicate event.
             * @name unbindBeforeDuplicate
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeDuplicate = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.Duplicate);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterDuplicate event.
             * @name unbindAfterDuplicate
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterDuplicate = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.Duplicate);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Creates a duplicate of an entity.
             * @name duplicate
             * @function
             * @param {modularis.EntityBase} entity - The duplicated entity.
             * @param {boolean} affectChildren - Indicates whether or not to duplicate all child entity instances.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.duplicate = function (entity, affectChildren, options, callback) {

                var method = enums.methodNames.Duplicate;

                var params = {
                    affectChildren: affectChildren,
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options, true),
                    payload: entity,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region DuplicateCollection

            /**
             * @callback modularis.proxy.activityServiceProxy~duplicateCollectionEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {modularis.EntityCollectionBase} eventData.entityCollection - The duplicated entity collection.
             * @param {boolean} eventData.affectChildren - Indicates whether or not to duplicate all child entity instances.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.EntityCollectionBase} eventData.responseObject - Entity collection. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeDuplicateCollection event.
             * @name onBeforeDuplicateCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~duplicateCollectionEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeDuplicateCollection = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.DuplicateCollection, handler);
            };

            /**
             * Binds to the afterDuplicateCollection event.
             * @name onAfterDuplicateCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~deleteCollectionEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterDuplicateCollection = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.DuplicateCollection, handler);
            };

            /**
             * Unbinds from to the beforeDuplicateCollection event.
             * @name unbindBeforeDuplicateCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeDuplicateCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.DuplicateCollection);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterDuplicateCollection event.
             * @name unbindAfterDuplicateCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterDuplicateCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.DuplicateCollection);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Creates a duplicate collection of an entity collection.
             * @name duplicateCollection
             * @function
             * @param {modularis.EntityCollectionBase} entityCollection - The duplicated entity collection.
             * @param {boolean} affectChildren - Indicates whether or not to duplicate all child entity instances.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.duplicateCollection = function (entityCollection, affectChildren, options, callback) {

                var method = enums.methodNames.DuplicateCollection;

                var params = {
                    affectChildren: affectChildren,
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options, true),
                    payload: entityCollection,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetAllCount

            /**
            * @callback modularis.proxy.activityServiceProxy~getAllCountEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {string} eventData.entityTypeName - The full class name of the entity type.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {Object} eventData.responseObject - Object with the information available for the response. Not available in the before event.
            * @param {integer} eventData.responseObject.Count - An integer that indicates the number of records.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeGetAllCount event.
             * @name onBeforeGetAllCount
             * @function
             * @param {modularis.proxy.activityServiceProxy~getAllCountEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetAllCount = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetAllCount, handler);
            };

            /**
             * Binds to the afterGetAllCount event.
             * @name onAfterGetAllCount
             * @function
             * @param {modularis.proxy.activityServiceProxy~getAllCountEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetAllCount = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetAllCount, handler);
            };

            /**
             * Unbinds from to the beforeGetAllCount event.
             * @name unbindBeforeGetAllCount
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetAllCount = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetAllCount);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetAllCount event.
             * @name unbindAfterGetAllCount
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetAllCount = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetAllCount);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets the count of the persisted instances based on the given entityTypeName.
             * @name getAllCount
             * @function
             * @param {string} entityTypeName - The full class name of the entity type.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getAllCount = function (entityTypeName, options, callback) {

                var method = enums.methodNames.GetAllCount;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    options: that._updateOptions(options),
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetAuditData

            /**
            * @callback modularis.proxy.activityServiceProxy~getAuditDataEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {string} eventData.entityTypeName - The full class name of the entity type.
            * @param {number} eventData.key - The primary key value of the entity for which the audit data must be retrieved.
            * @param {string} eventData.selectedEntities - Additional selected entities to retrieve.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {modularis.EntityBase} eventData.responseObject - Entity. Not available in the before event.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeGetAuditData event.
             * @name onBeforeGetAuditData
             * @function
             * @param {modularis.proxy.activityServiceProxy~getAllCountEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetAuditData = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetAuditData, handler);
            };

            /**
             * Binds to the afterGetAuditData event.
             * @name onAfterGetAuditData
             * @function
             * @param {modularis.proxy.activityServiceProxy~getAllCountEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetAuditData = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetAuditData, handler);
            };

            /**
             * Unbinds from to the beforeGetAuditData event.
             * @name unbindBeforeGetAuditData
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetAuditData = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetAuditData);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetAuditData event.
             * @name unbindAfterGetAuditData
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetAuditData = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetAuditData);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets the audit data for an entity instance which is identified by an entityKey.
             * @name getAuditData
             * @function
             * @param {string} entityTypeName - The full class name of the entity type.
             * @param {number} key - The primary key value of the entity for which the audit data must be retrieved.
             * @param {string} selectedEntities - Additional selected entities to retrieve.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getAuditData = function (entityTypeName, key, selectedEntities, options, callback) {

                var method = enums.methodNames.GetAuditData;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    key: key,
                    options: that._updateOptions(options),
                    selectedEntities: selectedEntities,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetAll

            /**
             * @callback modularis.proxy.activityServiceProxy~getAllEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {string} eventData.entityTypeName - The full class name of the entity type to retrieve.
             * @param {boolean} eventData.getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.EntityCollectionBase} eventData.responseObject - Entity collection. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeGetAll event.
             * @name onBeforeGetAll
             * @function
             * @param {modularis.proxy.activityServiceProxy~getAllEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetAll = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetAll, handler);
            };

            /**
             * Binds to the afterGetAll event.
             * @name onAfterGetAll
             * @function
             * @param {modularis.proxy.activityServiceProxy~getAllEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetAll = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetAll, handler);
            };

            /**
             * Unbinds from to the beforeGetAll event.
             * @name unbindBeforeGetAll
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetAll = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetAll);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetAll event.
             * @name unbindAfterGetAll
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetAll = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetAll);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Returns a collection of entities based on the given entityTypeName.
             * @name getAll
             * @function
             * @param {string} entityTypeName - The full class name of the entity type to retrieve
             * @param {boolean} getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched
             * @param {Object} options - A plain object representing any custom actions to be performed during execution
             * @param {requestCallback} callback - The function to be executed when the process is completed
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getAll = function (entityTypeName, getChildren, options, callback) {
                var method = enums.methodNames.GetAll;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    getChildren: getChildren,
                    options: that._updateOptions(options, true),
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetByKey

            /**
             * @callback modularis.proxy.activityServiceProxy~getByKeyEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {string} eventData.entityTypeName - The full class name of the entity type to retrieve.
             * @param {string} eventData.key - The primary key of the requested entity.
             * @param {boolean} eventData.getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.EntityBase} eventData.responseObject - Entity. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeGetByKey event.
             * @name onBeforeGetByKey
             * @function
             * @param {modularis.proxy.activityServiceProxy~getByKeyEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetByKey = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetByKey, handler);
            };

            /**
             * Binds to the afterGetByKey event.
             * @name onAfterGetByKey
             * @function
             * @param {modularis.proxy.activityServiceProxy~getByKeyEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetByKey = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetByKey, handler);
            };

            /**
             * Unbinds from to the beforeGetByKey event.
             * @name unbindBeforeGetByKey
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetByKey = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetByKey);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetByKey event.
             * @name unbindAfterGetByKey
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetByKey = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetByKey);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets an entity instance identified by a key.
             * @name getByKey
             * @function
             * @param {string} entityTypeName - The full class name of the entity type to retrieve.
             * @param {string} key - The primary key of the requested entity.
             * @param {boolean} getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getByKey = function (entityTypeName, key, getChildren, options, callback) {
                var method = enums.methodNames.GetByKey;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    getChildren: getChildren,
                    key: key,
                    options: that._updateOptions(options, true),
                    sessionID: clientCache.getSessionID()
                };
                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetCollectionByWhere

            /**
             * @callback modularis.proxy.activityServiceProxy~getCollectionByWhereEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {string} eventData.entityTypeName - The full class name of the entity type to retrieve.
             * @param {string} eventData.whereClause - The Modularis pseudo-SQL where clause used to perform the search.
             * @param {Array|string} eventData.parameterValues - The parameter values for the where clause.
             * @param {boolean} eventData.getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.EntityCollectionBase} eventData.responseObject - Entity collection. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeGetCollectionByWhere event.
             * @name onBeforeGetCollectionByWhere
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCollectionByWhereEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetCollectionByWhere = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetCollectionByWhere, handler);
            };

            /**
             * Binds to the afterGetCollectionByWhere event.
             * @name onAfterGetCollectionByWhere
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCollectionByWhereEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetCollectionByWhere = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetCollectionByWhere, handler);
            };

            /**
             * Unbinds from to the beforeGetCollectionByWhere event.
             * @name unbindBeforeGetCollectionByWhere
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetCollectionByWhere = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByWhere);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetCollectionByWhere event.
             * @name unbindAfterGetCollectionByWhere
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetCollectionByWhere = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByWhere);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets a collection of entities that match the Modularis pseudo-SQL where clause.
             * @name getCollectionByWhere
             * @function
             * @param {string} entityTypeName - The full class name of the entity type to retrieve.
             * @param {string} whereClause - The Modularis pseudo-SQL where clause used to perform the search.
             * @param {Array|string} parameterValues - The parameter values for the where clause.
             * @param {boolean} getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getCollectionByWhere = function (entityTypeName, whereClause, parameterValues, getChildren, options, callback) {
                var method = enums.methodNames.GetCollectionByWhere;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    getChildren: getChildren,
                    options: that._updateOptions(options, true),
                    parameterValues: parameterValues,
                    sessionID: clientCache.getSessionID(),
                    where: whereClause
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetCollectionByQueryDefName

            that.onBeforeGetCollectionByQueryDefName = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetCollectionByQueryDefName, handler);
            };

            that.onAfterGetCollectionByQueryDefName = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetCollectionByQueryDefName, handler);
            };

            that.unbindBeforeGetCollectionByQueryDefName = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByQueryDefName);
                that._unsubscribeBeforeHandlers(params);
            };

            that.unbindAfterGetCollectionByQueryDefName = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByQueryDefName);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets a collection of entity instances that match the search criteria defined in a query.
             * @name getCollectionByQueryDefName
             * @function
             * @param {string} entityTypeName - The full class name of the entity type to retrieve.
             * @param {string} queryDefName - The name of the query definition containing the search criteria.
             * @param {Array|string} queryDefParameterValues - Query definition parameter values.
             * @param {boolean} getChildren - Indicates whether or not to fetch the child entities.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getCollectionByQueryDefName = function (entityTypeName, queryDefName, queryDefParameterValues, getChildren, options, callback) {
                var method = enums.methodNames.GetCollectionByQueryDefName;

                var result = that._invokeBeforeHandlers(method, entityTypeName, getChildren, options);

                if (result === true) {

                    var params = {
                        CustomerID: that._getActiveServer(options).customerID,
                        EntityTypeName: entityTypeName,
                        GetChildren: getChildren,
                        Options: that._updateOptions(options, true),
                        QueryDefName: queryDefName,
                        QueryDefParameterValues: queryDefParameterValues,
                        SessionID: clientCache.getSessionID()
                    };

                    that.sendJSONRequest(method, params, that._afterCallback(method, callback));
                } else {
                    logger.info('Action canceled:' + method);
                }
            };

            //#endregion

            //#region GetCollectionByQueryDefID

            /**
             * @callback modularis.proxy.activityServiceProxy~getCollectionByQueryDefIDEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {string} eventData.entityTypeName - The full class name of the entity type to retrieve.
             * @param {string} eventData.queryDefID - The ID of the query definition containing the search criteria.
             * @param {Array|string} eventData.queryDefParameterValues - Query definition parameter values.
             * @param {boolean} eventData.getChildren - Indicates whether or not to fetch the child entities.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.EntityCollectionBase} eventData.responseObject - Entity collection. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeGetCollectionByQueryDefID event.
             * @name onBeforeGetCollectionByQueryDefID
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCollectionByQueryDefIDEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetCollectionByQueryDefID = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetCollectionByQueryDefID, handler);
            };

            /**
             * Binds to the afterGetCollectionByQueryDefID event.
             * @name onAfterGetCollectionByQueryDefID
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCollectionByQueryDefIDEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetCollectionByQueryDefID = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetCollectionByQueryDefID, handler);
            };

            /**
             * Unbinds from to the beforeGetCollectionByQueryDefID event.
             * @name unbindBeforeGetCollectionByQueryDefID
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetCollectionByQueryDefID = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByQueryDefID);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetCollectionByQueryDefID event.
             * @name unbindAfterGetCollectionByQueryDefID
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetCollectionByQueryDefID = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByQueryDefID);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets a collection of entity instances that match the search criteria defined in a query.
             * @name getCollectionByQueryDefID
             * @function
             * @param {string} entityTypeName - The full class name of the entity type to retrieve.
             * @param {string} queryDefID - The ID of the query definition containing the search criteria.
             * @param {Array|string} queryDefParameterValues - Query definition parameter values.
             * @param {boolean} getChildren - Indicates whether or not to fetch the child entities.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getCollectionByQueryDefID = function (entityTypeName, queryDefID, queryDefParameterValues, getChildren, options, callback) {
                var method = enums.methodNames.GetCollectionByQueryDefID;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    getChildren: getChildren,
                    options: that._updateOptions(options, true),
                    queryDefID: queryDefID,
                    queryDefParameterValues: queryDefParameterValues,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetCollectionByQueryDef

            that.onBeforeGetCollectionByQueryDef = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetCollectionByQueryDef, handler);
            };

            that.onAfterGetCollectionByQueryDef = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetCollectionByQueryDef, handler);
            };

            that.unbindBeforeGetCollectionByQueryDef = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByQueryDef);
                that._unsubscribeBeforeHandlers(params);
            };

            that.unbindAfterGetCollectionByQueryDef = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCollectionByQueryDef);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets a collection of entity instances that match the search criteria defined in a query.
             * @name getCollectionByQueryDef
             * @function
             * @param {modularis.EntityBase} queryDef - The query definition instance used to perform the fetch.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getCollectionByQueryDef = function (queryDef, options, callback) {
                var method = enums.methodNames.GetCollectionByQueryDef;
                var result = that._invokeBeforeHandlers(method, options);

                if (result === true) {
                    var params = {
                        CustomerID: that._getActiveServer(options).customerID,
                        Options: that._updateOptions(options, true),
                        QueryDef: queryDef,
                        SessionID: clientCache.getSessionID()
                    };

                    that.sendJSONRequest(method, params, that._afterCallback(method, callback));
                } else {
                    logger.info('Action canceled:' + method);
                }
            };

            //#endregion

            //#region GetNew

            /**
             * @callback modularis.proxy.activityServiceProxy~getNewEventHandler
             * @param {Object} eventData - Object with the information available for the event.
             * @param {string} eventData.entityTypeName - The full class name of the entity type.
             * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
             * @param {modularis.EntityBase} eventData.responseObject - Entity. Not available in the before event.
             * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
             */

            /**
             * Binds to the beforeGetNew event.
             * @name onBeforeGetNew
             * @function
             * @param {modularis.proxy.activityServiceProxy~getNewEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetNew = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetNew, handler);
            };

            /**
             * Binds to the afterGetNew event.
             * @name onAfterGetNew
             * @function
             * @param {modularis.proxy.activityServiceProxy~getNewEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetNew = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetNew, handler);
            };

            /**
             * Unbinds from to the beforeGetNew event.
             * @name unbindBeforeGetNew
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetNew = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetNew);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetNew event.
             * @name unbindAfterGetNew
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetNew = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetNew);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Creates and initializes a new instance based on the given entityTypeName.
             * @name getNew
             * @function
             * @param {string} entityTypeName - The full class name of the entity type.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getNew = function (entityTypeName, options, callback) {
                var method = enums.methodNames.GetNew;

                //Default options for getNew operation
                var getNewOptions = {
                };
                getNewOptions[optionsEnum.initializeOnServer] = false;
                //getNewOptions[optionsEnum.convertToJavaScriptEntity] = false;

                //Merge options
                $.extend(getNewOptions, options);

                var serverOptions = that._updateOptions(getNewOptions, true);

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    sessionID: clientCache.getSessionID(),
                    options: serverOptions
                };

                if (getNewOptions[optionsEnum.initializeOnServer] === true) {

                    //Copy options object and remove client-side only values
                    delete serverOptions[optionsEnum.initializeOnServer];

                    //Get new instance from the server
                    that._sendActivityServiceRequest(method, params, callback);

                } else {
                    var cancel = that._invokeBeforeHandlers(method, params);

                    if (!cancel) {
                        //Create new instance on client
                        objectBuilder.createEntityObject({
                            entityTypeName: entityTypeName,
                            assignIdentifier: true,
                            entityDef: options.entityDef,
                            callback: that._afterCallback(method, params, callback)
                        });
                    } else {
                        logger.info('Action canceled:' + method);
                        that._afterCallback(method, params, callback);
                    }

                }

            };

            //#endregion

            //#region GetCountByWhere

            /**
            * @callback modularis.proxy.activityServiceProxy~getCountByWhereEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {string} eventData.entityTypeName - The full class name of the entity type.
            * @param {string} eventData.whereClause - The Modularis pseudo-SQL where clause used to perform the search.
            * @param {Object} eventData.parameterValues - The parameter values for the where clause.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {Object} eventData.responseObject - Object with the information available for the response. Not available in the before event.
            * @param {integer} eventData.responseObject.Count - An integer that indicates the number of records.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeGetCountByWhere event.
             * @name onBeforeGetCountByWhere
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCountByWhereEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetCountByWhere = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetCountByWhere, handler);
            };

            /**
             * Binds to the afterGetCountByWhere event.
             * @name onAfterGetCountByWhere
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCountByWhereEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetCountByWhere = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetCountByWhere, handler);
            };

            /**
             * Unbinds from to the beforeGetCountByWhere event.
             * @name unbindBeforeGetCountByWhere
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetCountByWhere = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCountByWhere);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetCountByWhere event.
             * @name unbindAfterGetCountByWhere
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetCountByWhere = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCountByWhere);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets the number of persisted instances based on the given entityTypeName that matches the where clause.
             * @name getCountByWhere
             * @function
             * @param {string} entityTypeName - The full class name of the entity type.
             * @param {string} whereClause - The Modularis pseudo-SQL where clause used to perform the search.
             * @param {Object} parameterValues - The parameter values for the where clause.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getCountByWhere = function (entityTypeName, whereClause, parameterValues, options, callback) {

                var method = enums.methodNames.GetCountByWhere;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    entityTypeName: entityTypeName,
                    options: that._updateOptions(options),
                    parameterValues: parameterValues,
                    sessionID: clientCache.getSessionID(),
                    where: whereClause
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region GetCountByQueryDef

            /**
            * @callback modularis.proxy.activityServiceProxy~getCountByQueryDefEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {modularis.EntityBase} eventData.queryDef - The query definition which contains the search criteria.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {Object} eventData.responseObject - Object with the information available for the response. Not available in the before event.
            * @param {integer} eventData.responseObject.Count - An integer that indicates the number of records.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeGetCountByQueryDef event.
             * @name onBeforeGetCountByQueryDef
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCountByQueryDefEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeGetCountByQueryDef = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.GetCountByQueryDef, handler);
            };

            /**
             * Binds to the afterGetCountByQueryDef event.
             * @name onAfterGetCountByQueryDef
             * @function
             * @param {modularis.proxy.activityServiceProxy~getCountByQueryDefEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterGetCountByQueryDef = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.GetCountByQueryDef, handler);
            };

            /**
             * Unbinds from to the beforeGetCountByQueryDef event.
             * @name unbindBeforeGetCountByQueryDef
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeGetCountByQueryDef = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCountByQueryDef);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterGetCountByQueryDef event.
             * @name unbindAfterGetCountByQueryDef
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterGetCountByQueryDef = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.GetCountByQueryDef);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Gets the number of persisted instances that match the query definition.
             * @name getCountByQueryDef
             * @function
             * @param {modularis.EntityBase} queryDef - The query definition which contains the search criteria.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getCountByQueryDef = function (queryDef, options, callback) {

                var method = enums.methodNames.GetCountByQueryDef;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options),
                    queryDef: queryDef,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region Save

            /**
            * @callback modularis.proxy.activityServiceProxy~saveEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {modularis.EntityBase} eventData.entity - The entity instance to save.
            * @param {boolean} eventData.validateFirst - Validates the entity and its children if true. Otherwise, the validation is bypassed.
            * @param {boolean} eventData.affectChildren - Indicates whether to process child entities or not.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {modularis.EntityBase} eventData.responseObject - Entity. Not available in the before event.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeSave event.
             * @name onBeforeSave
             * @function
             * @param {modularis.proxy.activityServiceProxy~saveEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeSave = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.Save, handler);
            };

            /**
             * Binds to the afterSave event.
             * @name onAfterSave
             * @function
             * @param {modularis.proxy.activityServiceProxy~saveEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterSave = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.Save, handler);
            };

            /**
             * Unbinds from to the beforeSave event.
             * @name unbindBeforeSave
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeSave = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.Save);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterSave event.
             * @name unbindAfterSave
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterSave = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.Save);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Saves an entity and its child entities
             * @name save
             * @function
             * @param {modularis.EntityBase} entity - The entity instance to save.
             * @param {boolean} validateFirst - Validates the entity and its children if true. Otherwise, the validation is bypassed.
             * @param {boolean} affectChildren - Indicates whether to process child entities or not.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.save = function (entity, validateFirst, affectChildren, options, callback) {

                var method = enums.methodNames.Save;

                var params = {
                    affectChildren: affectChildren,
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options, true),
                    payload: entity,
                    sessionID: clientCache.getSessionID(),
                    validateFirst: validateFirst
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region SaveCollection

            /**
            * @callback modularis.proxy.activityServiceProxy~saveCollectionEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {modularis.EntityCollectionBase} eventData.entityCollection - The entity collection to persist.
            * @param {boolean} eventData.validateFirst - Validates every entity in the collection if true. Otherwise, the validation is bypassed.
            * @param {boolean} eventData.affectChildren - Indicates whether to process child entities or not.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {modularis.EntityCollectionBase} eventData.responseObject - EntityCollection. Not available in the before event.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeSaveCollection event.
             * @name onBeforeSaveCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~saveCollectionEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeSaveCollection = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.SaveCollection, handler);
            };

            /**
             * Binds to the afterSaveCollection event.
             * @name onAfterSaveCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~saveCollectionEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterSaveCollection = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.SaveCollection, handler);
            };

            /**
             * Unbinds from to the beforeSaveCollection event.
             * @name unbindBeforeSaveCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeSaveCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.SaveCollection);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterSaveCollection event.
             * @name unbindAfterSaveCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterSaveCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.SaveCollection);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Persists the entity collection.
             * @name saveCollection
             * @function
             * @param {modularis.EntityCollectionBase} entityCollection - The entity collection to persist.
             * @param {boolean} validateFirst - Validates every entity in the collection if true. Otherwise, the validation is bypassed.
             * @param {boolean} affectChildren - Indicates whether to process child entities or not.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.saveCollection = function (entityCollection, validateFirst, affectChildren, options, callback) {

                var method = enums.methodNames.SaveCollection;

                var params = {
                    affectChildren: affectChildren,
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options, true),
                    payload: entityCollection,
                    sessionID: clientCache.getSessionID(),
                    validateFirst: validateFirst
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region Validate

            /**
            * @callback modularis.proxy.activityServiceProxy~validateEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {modularis.EntityBase} eventData.entity - The entity to validate.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {modularis.Entity} eventData.responseObject - Entity. Not available in the before event.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeValidate event.
             * @name onBeforeValidate
             * @function
             * @param {modularis.proxy.activityServiceProxy~validateEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeValidate = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.Validate, handler);
            };

            /**
             * Binds to the afterValidate event.
             * @name onAfterValidate
             * @function
             * @param {modularis.proxy.activityServiceProxy~validateEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterValidate = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.Validate, handler);
            };

            /**
             * Unbinds from to the beforeValidate event.
             * @name unbindBeforeValidate
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeValidate = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.Validate);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterValidate event.
             * @name unbindAfterValidate
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterValidate = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.Validate);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Invokes the business validation class for the entity to validate its data.
             * @name validate
             * @function
             * @param {modularis.EntityBase} entity - The entity to validate.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.validate = function (entity, options, callback) {

                var method = enums.methodNames.Validate;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options, true),
                    payload: entity,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            //#region ValidateCollection

            /**
            * @callback modularis.proxy.activityServiceProxy~validateCollectionEventHandler
            * @param {Object} eventData - Object with the information available for the event.
            * @param {modularis.EntityCollectionBase} eventData.entityCollection - The entity collection to validate.
            * @param {Object} eventData.options - A plain object representing any custom actions to be performed during execution.
            * @param {modularis.EntityCollectionBase} eventData.responseObject - EntityCollection. Not available in the before event.
            * @param {Object|string} eventData.responseError - Error object. Not available in the before event.
            */

            /**
             * Binds to the beforeValidateCollection event.
             * @name onBeforeValidateCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~validateCollectionEventHandler} handler - Event handler
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onBeforeValidateCollection = function (handler) {
                return that._subscribeBeforeHandler(enums.methodNames.ValidateCollection, handler);
            };

            /**
             * Binds to the afterValidateCollection event.
             * @name onAfterValidateCollection
             * @function
             * @param {modularis.proxy.activityServiceProxy~validateCollectionEventHandler} handler - Event handler.
             * @memberof modularis.proxy.activityServiceProxy
             * @returns {string} Event handler ID
             */
            that.onAfterValidateCollection = function (handler) {
                return that._subscribeAfterHandler(enums.methodNames.ValidateCollection, handler);
            };

            /**
             * Unbinds from to the beforeValidateCollection event.
             * @name unbindBeforeValidateCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindBeforeValidateCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.ValidateCollection);
                that._unsubscribeBeforeHandlers(params);
            };

            /**
             * Unbinds to the afterValidateCollection event.
             * @name unbindAfterValidateCollection
             * @function
             * @param {string|string[]} handlerIDs - ID of the handlers(s) to unbind.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.unbindAfterValidateCollection = function (handlerIDs) {
                var params = validateHandlerParameters(handlerIDs, enums.methodNames.ValidateCollection);
                that._unsubscribeAfterHandlers(params);
            };

            /**
             * Invokes the business validation class for the entity collection to validate its data.
             * @name validateCollection
             * @function
             * @param {modularis.EntityCollectionBase} entityCollection - The entity collection to validate.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.validateCollection = function (entityCollection, options, callback) {

                var method = enums.methodNames.ValidateCollection;

                var params = {
                    customerID: that._getActiveServer(options).customerID,
                    options: that._updateOptions(options, true),
                    payload: entityCollection,
                    sessionID: clientCache.getSessionID()
                };

                that._sendActivityServiceRequest(method, params, callback);
            };

            //#endregion

            /**
             * Returns the preferred language an end-user configures in their browser.
             * @name getPreferredCultures
             * @function
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.activityServiceProxy
             */
            that.getPreferredCultures = function (callback) {
                that.sendPlainTextRequest('GetPreferredCultures', {
                }, callback, 'text');
            };

        };

        //Inheritance
        RESTActivityWebServiceProxy.prototype = new proxyBase.RESTWebServiceProxy();
        RESTActivityWebServiceProxy.prototype.constructor = RESTActivityWebServiceProxy;

        return new RESTActivityWebServiceProxy('RESTActivityWebService');

    }
);
