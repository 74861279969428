define('modularis/i18n/translators/tooltipTranslator',
    [],function () {
        'use strict';

        var checkboxMarginTop = 15;

        var TooltipTranslator = function () {
            var that = this;
            that.execute = function (options, localization) {
                var translationResult = localization.translate(options.key);

                if (translationResult == null) {
                    return false;
                }
                if (options.widget) {

                    var widgetType = 'kendo' + options.widget.options.name;

                    options.element.data(widgetType).wrapper.kendoTooltip({
                        content: translationResult
                    });

                } else {

                    var tooltipOptions = { content: translationResult };

                    if (options.element.is(':checkbox') || options.element.is(':radio')) {
                        tooltipOptions.show = function (element) {
                            element.sender.popup.wrapper.css('margin-top', checkboxMarginTop);
                        };
                    }

                    options.element.kendoTooltip(tooltipOptions);
                }

                return true;
            };
        };

        return TooltipTranslator;
    });
