define('modularis/web/widgets/modularisValidator',
    ['jquery', 'kendo', 'localization', 'util'],
    function ($, kendo, localization, util) {
        'use strict';
        /*eslint new-cap:0*/
        var Validator = kendo.ui.Validator;
        var NAME = 'name',
            INVALIDINPUT = 'k-invalid',
            VALIDINPUT = 'k-valid';

        /**
         * @classdesc Modularis Validator widget. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/validator|kendo.ui.Validator}.
         * @constructs modularis.web.widgets.ModularisValidator
         * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
         * 
         * @property {Object} options - Widget options.
         * @property {string} [options.entityTypeName=null] - Full name of the entitiy to use to localize the validation messages.
         * @property {boolean} [options.removeClassesOnHideMessages=true] - If false, the invalid classes will not be removed after the hideMessages method is called.
         */
        var ModularisValidator = Validator.extend({

            init: function (element, options) {
                var that = this;

                //Refresh kendo messages before widget initialization.
                $.extend(that.options.messages, Validator.fn.options.messages);

                Validator.fn.init.call(that, element, options);
            },

            options: {
                name: 'ModularisValidator',
                entityTypeName: null,
                removeClassesOnHideMessages: true
            },
            /**
             * Validates the input and changes the aria-invalid attribute value and the validation classes.
             * @name validateInput
             * @function
             * @memberof modularis.web.widgets.ModularisValidator
             * @param {input} input - Control to validate.
             * @returns {boolean}
             */
            validateInput: function (input) {
                var that = this,
                    controlToChange = null;

                var valid = Validator.fn.validateInput.call(that, input);

                if (util.isFunction(input.attr)) {

                    var dataRole = input.attr('data-role'),
                        alertIcon = input.next('div').children('span.m-alertIcon'),
                        alertTooltip = input.next('div').children('span.m-alertTooltip');

                    switch (dataRole) {
                        case 'datepicker':
                        case 'timepicker':
                        case 'datetimepicker':
                        case 'numerictextbox':
                            controlToChange = input.closest('span');
                            break;
                        case 'dropdownlist':
                        case 'modularispropdropdown':
                            controlToChange = input.siblings();
                            break;
                            //no default
                    }

                    if (controlToChange) {
                        controlToChange.removeAttr('aria-invalid');

                        controlToChange.toggleClass(INVALIDINPUT, !valid);
                        controlToChange.toggleClass(VALIDINPUT, valid);

                        if (!valid) {
                            controlToChange.attr('aria-invalid', true);
                            alertIcon = controlToChange.parent().next('div').children('span.m-alertIcon');
                            alertTooltip = controlToChange.parent().next('div').children('span.m-alertTooltip');
                        }
                    }
                    if (alertIcon) {
                        alertIcon.mouseenter(function () {
                            alertTooltip.show();
                        });
                        alertIcon.mouseleave(function () {
                            alertTooltip.hide();
                        });
                    }
                    
                }

                return valid;
            },
            /**
             * Hide the validation messages and remove the invalid classes if the removeClassesOnHideMessages property is set to true.
             * @name hideMessages
             * @function
             * @memberof modularis.web.widgets.ModularisValidator
             */
            hideMessages: function () {
                var that = this;
                Validator.fn.hideMessages.call(this);

                //remove the invalird css classe and attribute
                if (this.options.removeClassesOnHideMessages) {
                    that.element.find('.k-invalid').removeClass('k-invalid').removeAttr('aria-invalid');
                }

            },
            _extractMessage: function (input, ruleKey) {
                var that = this,
                    customMessage = that.options.messages[ruleKey],
                    fieldName = input.attr(NAME),
                    localizedFieldName = null;

                if (fieldName) {
                    localizedFieldName = localization.translatePropertyName(that.options.entityTypeName, fieldName);
                }

                if (localizedFieldName) {
                    fieldName = localizedFieldName;
                }

                customMessage = kendo.isFunction(customMessage) ? customMessage(input) : customMessage;

                var message = kendo.format(input.attr(kendo.attr(ruleKey + '-msg')) || input.attr('validationMessage') || input.attr('title') || customMessage || '', fieldName, input.attr(ruleKey));

                return message;
            }
        });

        return {
            widgetClass: ModularisValidator
        };
    });
