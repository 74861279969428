define('modularis/web/widgets/binders/itemSelectorBinders',
    ['jquery', 'kendo'],
    function ($, kendo) {
        'use strict';

        var itemSelectorBindersContainer = {

            init: function () {
                /*eslint no-param-reassign: 0*/

                //selectedItems
                kendo.data.binders.widget.selectedItems = kendo.data.Binder.extend({
                    init: function (widget, bindings, options) {
                        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);
                    },
                    refresh: function () {
                        var that = this,
                            value = that.bindings.selectedItems.get();

                        var itemSelector = kendo.widgetInstance($(that.element));
                        itemSelector.setSelectedItems(value);
                    }
                });

                //unselectedItems
                kendo.data.binders.widget.unselectedItems = kendo.data.Binder.extend({
                    init: function (widget, bindings, options) {
                        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);
                    },
                    refresh: function () {
                        var that = this,
                            value = that.bindings.unselectedItems.get();

                        var itemSelector = kendo.widgetInstance($(that.element));
                        itemSelector.setUnselectedItems(value);
                    }
                });

                //beforeSelectedItems
                kendo.data.binders.widget.beforeSelectedItems = kendo.data.Binder.extend({
                    init: function (widget, bindings, options) {
                        var that = this;
                        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);

                        var binding = that.bindings.beforeSelectedItems;
                        var method = binding.source.get(binding.path);

                        if (widget._assignBeforeSelectedItemsHandler) {
                            widget._assignBeforeSelectedItemsHandler($.proxy(method, binding.source));
                        }
                    },
                    refresh: function () { }
                });

                //beforeSelectedItemsAsync
                kendo.data.binders.widget.beforeSelectedItemsAsync = kendo.data.Binder.extend({
                    init: function (widget, bindings, options) {
                        var that = this;
                        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);

                        var binding = that.bindings.beforeSelectedItemsAsync;
                        var method = binding.source.get(binding.path);

                        if (widget._assignBeforeSelectedItemsAsyncHandler) {
                            widget._assignBeforeSelectedItemsAsyncHandler($.proxy(method, binding.source));
                        }
                    },
                    refresh: function () { }
                });

                //beforeUnselectedItems
                kendo.data.binders.widget.beforeUnselectedItems = kendo.data.Binder.extend({
                    init: function (widget, bindings, options) {
                        var that = this;
                        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);

                        var binding = that.bindings.beforeUnselectedItems;
                        var method = binding.source.get(binding.path);

                        if (widget._assignBeforeUnselectedItemsHandler) {
                            widget._assignBeforeUnselectedItemsHandler($.proxy(method, binding.source));
                        }
                    },
                    refresh: function () { }
                });

                //beforeUnselectedItemsAsync
                kendo.data.binders.widget.beforeUnselectedItemsAsync = kendo.data.Binder.extend({
                    init: function (widget, bindings, options) {
                        var that = this;
                        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);

                        var binding = that.bindings.beforeUnselectedItemsAsync;
                        var method = binding.source.get(binding.path);

                        if (widget._assignBeforeUnselectedItemsAsyncHandler) {
                            widget._assignBeforeUnselectedItemsAsyncHandler($.proxy(method, binding.source));
                        }
                    },
                    refresh: function () { }
                });

            }

        };

        return itemSelectorBindersContainer;

    }
);
