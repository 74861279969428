define('modularis/web/widgets/modularisView',
    ['jquery', 'kendo', 'configLoader', 'modularisTemplateLoader', 'objectBuilder', 'util', 'webUtil', 'enums'],
    function ($, kendo, configLoader, templateLoader, objectBuilder, modularisUtil, webUtil, enums) {
        'use strict';

        var ui = kendo.ui, Widget = ui.Widget;

        //#region Widget definition

        var saveCompleted = 'saveCompleted';
        var deleteCompleted = 'deleteCompleted';
        var changeEventName = 'change';

        /**
          * @classdesc ModularisView widget. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/widget|kendo.ui.Widget}.
          * @constructs modularis.web.widgets.ModularisView
          * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
          * 
          * @property {Object} options - Widget options.
          */
        var ModularisView = Widget.extend({

            _viewPath: null,
            _viewInstance: null,
            _viewModelInstance: null,

            _logOffEventHandlerID: null,
            _unauthorizedEventHandlerID: null,

            _entityEditListElement: null,

            //The following object temporarily stores values that should passed to the view-model, but the view-model hasn't been loaded yet.
            _tempViewModelValues: null,
            _tempViewModelEventHandlers: null,
            _propertyChangedEventNames: null,
            _waitingViewModelForChangeBinding: null,
            init: function (element, options) {
                var that = this;
                Widget.fn.init.call(that, element, options);
                that._tempViewModelValues = {};
                that._tempViewModelEventHandlers = {};
                that._propertyChangedEventNames = {};
                that._waitingViewModelForChangeEventBinding = false;
                var path = that._getViewPath();
                templateLoader.loadTemplate(path, function (data, error) {

                    if (!modularisUtil.isSuccessfulResponse(data, error)) {
                        //console.log(error);
                        return;
                    }
                    var templateId = data.templateId;
                    var viewModelModuleName = that._getViewModelModuleName();
                    var viewOptions;
                    if (viewModelModuleName == null) {
                        viewOptions = webUtil.addViewSettings(path, templateId);
                        that._setViewInstance(new kendo.View(templateId, viewOptions));
                        that._render();
                        return;
                    }

                    var requireFunction = modularisUtil.getRequireJSFunction();

                    if (viewModelModuleName.startsWith('modularis')) {
                        requireFunction = require;
                    }

                    requireFunction([viewModelModuleName], function (viewModelModule) {

                        var initializeViewCallback = function (viewModel, initializeViewError) {

                            if (modularisUtil.isSuccessfulResponse(viewModel, initializeViewError)) {
                                that._setViewModelInstance(viewModel);
                                viewOptions = webUtil.addViewSettings(path, templateId, { model: that._viewModelInstance });
                                that._logOffEventHandlerID = webUtil.bindToLogOffEvent(that._viewModelInstance);
                                that._unauthorizedEventHandlerID = webUtil.bindToUnauthorizedEvent(that._viewModelInstance);
                                that._setViewInstance(new kendo.View(data.templateId, viewOptions));
                                that._render();

                                //Assign entity key if needed
                                if (that.options.entityInstanceKey && that.options.entityTypeName) {
                                    that._viewModelInstance.loadEntityByKey({
                                        key: that.options.entityInstanceKey,
                                        getChildren: false,
                                        callback: function () {
                                            webUtil.callAfterViewModelIsDisplayed(that._viewModelInstance);
                                        }
                                    });
                                } else {
                                    webUtil.callAfterViewModelIsDisplayed(that._viewModelInstance);
                                }

                            } else {
                                //console.log(error);
                            }

                        };

                        var initializeOptions = that._createInitializeViewOptions();
                        viewModelModule.initializeForView(initializeViewCallback, initializeOptions);

                    });
                });

                that._bindWidgetViewModelEvents();
            },

            destroy: function () {
                var that = this;

                webUtil.destroyViewModel(that._viewModelInstance);

                if (that._viewInstance) {
                    that._viewInstance.destroy();
                }

                that._viewInstance = null;
                that._viewModelInstance = null;

            },

            options: {
                name: 'ModularisView',
                view: null,
                viewModel: null,

                //#region Settings specific to ModularisView loading generated views and view-models

                entityTypeName: null,
                editMode: enums.editModes.insert,
                //Option to define te key of an entity that needs to be displayed on the view.
                entityInstanceKey: null,
                initializeEntityOnServer: false,

                //#endregion

                //Object with options that must be passed to the viewModel
                additionalOptions: null
            },

            events: [saveCompleted, deleteCompleted, enums.modularisViewEventName.beforeViewModelAssigned, enums.modularisViewEventName.afterViewModelAssigned],
            _bindWidgetViewModelEvents: function () {
                var that = this;
                for (var eventIndex = 0; eventIndex < that.events.length; eventIndex++) {
                    var eventName = that.events[eventIndex];
                    that._bindViewModelEvent(eventName);
                }
            },
            _bindViewModelEvent: function (eventName) {
                var that = this;
                that.bindViewModelEvent(eventName, function (event) {
                    that.trigger(eventName, event);
                });
            },
            setAdditionalOptions: function (additionalOptions) {
                //This function allows to pass additional or different options to the associated view model.
                var that = this;
                if (that._viewModelInstance != null && that._viewModelInstance.setAdditionalOptions instanceof Function) {

                    that._viewModelInstance.setAdditionalOptions(additionalOptions);

                }
            },

            getViewModel: function () {
                return this._viewModelInstance;
            },

            assignEntityEditList: function (listDOMElement) {
                this._entityEditListElement = listDOMElement;
            },

            setViewModelValue: function (key, value) {
                var viewModel = this.getViewModel();
                if (!viewModel) {
                    this._tempViewModelValues[key] = value;
                } else {
                    this._passViewModelValue(key, value);
                }
            },

            setActiveEntity: function (activeEntity) {
                if (activeEntity) {
                    this.setViewModelValue('activeEntity', activeEntity);
                }
            },

            _passViewModelValue: function (key, value) {
                var viewModel = this.getViewModel();
                if (key !== 'activeEntity') {
                    viewModel.setValue(key, value);
                } else if (modularisUtil.isDefined('activeEntity')) {
                    viewModel.activeEntity = value;
                }
            },

            _passStoredTempValues: function () {
                var that = this;
                var tempValues = that._tempViewModelValues;
                for (var key in tempValues) {
                    if (tempValues.hasOwnProperty(key)) {
                        that._passViewModelValue(key, tempValues[key]);
                    }
                }
                that._tempViewModelValues = {};
            },

            _render: function () {
                var that = this;
                that.element.empty();
                that._viewInstance.render(that.element);
                //Avoids a possible memory leak.
                that._viewInstance.element.prevObject = null;
            },

            _getViewPath: function () {
                var that = this;
                if (that._viewPath == null) {
                    that._viewPath = that.options.view;

                    //Try to resolve the view path using the entity type name.
                    if (that._viewPath == null && that.options.entityTypeName != null) {
                        var normalizedEntityTypeName = objectBuilder.getNormalizedEntityTypeName(that.options.entityTypeName);
                        var lastSlashPosition = normalizedEntityTypeName.lastIndexOf('/');
                        var entityName = normalizedEntityTypeName.substr(lastSlashPosition + 1);

                        //All entity views are saved in the 'entity' folder, located under the views folder.
                        that._viewPath = 'entity/' + normalizedEntityTypeName.substr(0, lastSlashPosition + 1) + '_' + entityName;
                    }
                }
                return that._viewPath;
            },

            _getViewModelModuleName: function () {
                var that = this;
                var result = that.options.viewModel;
                var config = configLoader.appConfig;

                //Try to resolve the view model name using the entity type name.
                if (result == null && that.options.entityTypeName != null) {
                    result = objectBuilder.getNormalizedEntityTypeName(that.options.entityTypeName);
                }

                if (result != null && !result.startsWith('modularis') && !result.startsWith(config.viewModelsBasePath)) {
                    result = config.viewModelsBasePath + result;
                }

                return result;
            },

            _createInitializeViewOptions: function () {
                var that = this;

                var initializeOptions = {
                    initializeOnServer: that.options.initializeEntityOnServer
                };

                if (that.options.entityTypeName != null) {
                    //The editMode is only valid when the entityTypeName is provided
                    initializeOptions.editMode = that.options.editMode;
                }

                if (that.options.additionalOptions != null) {
                    initializeOptions.additionalOptions = that.options.additionalOptions;
                }

                return initializeOptions;
            },

            _setViewModelInstance: function (newViewModelInstance) {
                var that = this;
                that.trigger(enums.modularisViewEventName.beforeViewModelAssigned, { previousInstance: that._viewModelInstance });
                that._viewModelInstance = newViewModelInstance;
                that.trigger(enums.modularisViewEventName.afterViewModelAssigned, { newInstance: that._viewModelInstance });
                that._passStoredTempValues();
                that._passStoredViewModelEventHandlers();

                //Bind to event
                that._viewModelInstance.bind(enums.viewModelEventName.saveCompleted, function (event) {

                    if (that._entityEditListElement) {
                        kendo.widgetInstance(that._entityEditListElement).refreshData();
                    }


                    that.trigger(saveCompleted, {
                        entityName: event.entity,
                        data: event.data
                    });
                });

                that._viewModelInstance.bind(enums.viewModelEventName.deleteCompleted, function (event) {

                    if (that._entityEditListElement) {
                        kendo.widgetInstance(that._entityEditListElement).refreshData();
                    }

                    that.trigger(deleteCompleted, {
                        entityName: event.entity
                    });
                });
            },

            _setViewInstance: function (newViewInstance) {
                var that = this;
                that.trigger(enums.modularisViewEventName.beforeViewAssigned, { previousInstance: that._viewInstance });
                that._viewInstance = newViewInstance;
                that.trigger(enums.modularisViewEventName.afterViewAssigned, { newInstance: that._viewInstance });
            },
            bindViewModelEvent: function (eventName, handler) {
                var that = this;
                var viewModel = that.getViewModel();
                if (!viewModel) {

                    if (!(eventName in that._tempViewModelEventHandlers)) {
                        that._tempViewModelEventHandlers[eventName] = [];
                    }

                    that._tempViewModelEventHandlers[eventName].push(handler);

                } else {
                    that._passViewModelEventHandler(eventName, handler);
                }
            },

            _passStoredViewModelEventHandlers: function () {
                var that = this;
                var tempEventHandlers = that._tempViewModelEventHandlers;
                for (var eventName in tempEventHandlers) {
                    if (tempEventHandlers.hasOwnProperty(eventName)) {
                        var handlers = tempEventHandlers[eventName];
                        for (var handlerIndex = 0; handlerIndex < handlers.length; handlerIndex++) {
                            that._passViewModelEventHandler(eventName, handlers[handlerIndex]);
                        }
                    }
                }
            },

            _passViewModelEventHandler: function (eventName, handler) {
                var that = this;
                var viewModel = that.getViewModel();
                viewModel.bind(eventName, handler);
            },

            bindPropertyChanged: function (propertyName, eventHandler) {
                var that = this;

                var viewModel = that.getViewModel();
                if (!viewModel && !that._waitingViewModelForChangeEventBinding) {
                    that._waitingViewModelForChangeEventBinding = true;
                    that.bind(enums.modularisViewEventName.afterViewModelAssigned, function () {
                        viewModel = that.getViewModel();
                        viewModel.bind(changeEventName, $.proxy(that._onViewModelPropertyChange, that));
                    });
                }


                if (!(propertyName in that._propertyChangedEventNames)) {
                    var eventName = String.format('{0}PropertyChanged', propertyName);
                    that._propertyChangedEventNames[propertyName] = eventName;
                }

                that.bind(that._propertyChangedEventNames[propertyName], eventHandler);

            },
            _onViewModelPropertyChange: function (event) {
                var that = this;

                var field = event.field;

                if (!(field in that._propertyChangedEventNames)) {
                    return;
                }

                var propertyChangedEventName = that._propertyChangedEventNames[field];

                var value = event.sender.get(field);

                var changeEvent = { field: field, value: value };

                that.trigger(propertyChangedEventName, changeEvent);
            }
        });

        //#endregion

        return {
            widgetClass: ModularisView
        };
    }
);
