define('modularis/viewModels/theme/themeSettings',
    ['jquery', 'kendo', 'viewModelBase', 'modularisStyleSheetManager', 'util'],
    function ($, kendo, viewModelBase, styleSheetManager, util) {
        'use strict';

        var ThemeSettingsViewModel = function () {

            var that = this;
            viewModelBase.ViewModelBase.call(that);
            that.selectedTheme = styleSheetManager.getCurrentTheme().name;

            /*theme dataSource*/
            that.themes = new kendo.data.DataSource({
                data: styleSheetManager.getThemes()
            });



            /*dummy data for controls*/
            that.users = new kendo.data.DataSource({
                data: [
                    { Year: '2000', EstimatedUsers: '124000000' },
                    { Year: '2001', EstimatedUsers: '114000000' },
                    { Year: '2002', EstimatedUsers: '224000000' },
                    { Year: '2003', EstimatedUsers: '134000000' },
                    { Year: '2004', EstimatedUsers: '154000000' },
                    { Year: '2005', EstimatedUsers: '254000000' }
                ]
            });

            that.items = new kendo.data.DataSource({
                data: [
                    { itemName: 'Alpha', itemID: '001' },
                    { itemName: 'Beta', itemID: '002' },
                    { itemName: 'Gamma', itemID: '003' },
                    { itemName: 'Delta', itemID: '004' },
                    { itemName: 'Epsilon', itemID: '005' },
                    { itemName: 'Zeta', itemID: '006' },
                    { itemName: 'Eta', itemID: '007' },
                    { itemName: 'Theta', itemID: '008' },
                    { itemName: 'Iota', itemID: '009' }
                ]
            });
            that.progressValue = 40;
            that.tasks = new kendo.data.SchedulerDataSource({
                data: [
                    { taskID: 25, title: 'An Event', start: new Date('1988/7/5 03:30 AM'), end: new Date('1988/7/5 12:00 PM'), description: 'Event description' },
                    { taskID: 25, title: 'Another Event', start: new Date('1988/7/7 1:40 AM'), end: new Date('1988/7/7 11:00 AM'), description: 'Event description' }
                ]
            });

        };

        ThemeSettingsViewModel.prototype = new viewModelBase.ViewModelBase();

        ThemeSettingsViewModel.prototype.afterViewIsDisplayed = function () {
            $('#notifications').data('kendoNotification').info('information');
            $('#notifications').data('kendoNotification').success('success');
            $('#notifications').data('kendoNotification').warning('warning');
            $('#notifications').data('kendoNotification').error('error');

        };

        ThemeSettingsViewModel.prototype.onChange = function (event) {
            var dataItem = event.sender.dataItem(event.item);
            styleSheetManager.setTheme(dataItem.name);
        };

        var initializeForView = function (callback/*, options*/) {
            var themeSettingsViewModel = new ThemeSettingsViewModel();
            util.notify(callback, themeSettingsViewModel, null);
        };

        return {
            initializeForView: initializeForView
        };

    }
);
