define('modularis/i18n/translators/titleTranslator',
    [],function () {
        'use strict';

        var TitleTranslator = function () {
            var that = this;
            that.execute = function (options, localization) {
                var translationResult = localization.translate(options.key);

                if (translationResult == null) {
                    return false;
                }
                if (options.widget) {
                    if (typeof (options.widget.title) === 'function') {
                        options.widget.title(translationResult);
                    } else if (typeof (options.element.attr) === 'function') {
                        options.element.attr(options.attribute, translationResult);
                    }
                } else {
                    options.element.attr(options.attribute, translationResult);
                }

                return true;
            };
        };

        return TitleTranslator;
    });
