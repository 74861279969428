define('modularisRoutingManager',
    ['jquery', 'kendo', 'modularisLayoutManager', 'modularisTemplateLoader', 'modularisGeneral/browsingContextHelper', 'configLoader',
        'clientCache', 'clientStorage', 'logger', 'util', 'webUtil'],
    function ($, kendo, layoutManager, templateLoader, browsingContextHelper, configLoader,
        clientCache, clientStorage, logger, util, webUtil) {
        'use strict';

        var currentRoute = null;

        var returnUrlParameterName = 'returnUrl';

        var isAuthorizationRequired = function (route) {
            return ('authorize' in route) && route.authorize;
        };

        var handleRoute = function (router, route) {

            //Get parameters for route
            var routeParameterNames = null;

            router.route(route.routePath, function () {

                var routeArguments = arguments;

                browsingContextHelper.restoreBrowsingContext(route, function () {
                    if (isAuthorizationRequired(route)) {

                        if (!clientCache.isAuthenticated()) {

                            if (routingManager._validateSessionHandler) {

                                var routeParameters = getRouteParameters(route, routeParameterNames, routeArguments);
                                routingManager._validateSessionHandler(route, routeParameters, function (isValid) {
                                    if (isValid) {
                                        loadView(router, route, routeParameterNames, routeArguments);
                                    } else {
                                        navigateToLogin(router, route, routeParameterNames, routeArguments);
                                    }
                                });
                            }
                            else {
                                navigateToLogin(router, route, routeParameterNames, routeArguments);
                            }

                            return;

                        }
                    }

                    loadView(router, route, routeParameterNames, routeArguments);
                });
            });

        };

        var navigateToLogin = function (router, route, routeParameterNames, routeArguments) {
            //Check session cookie
            if (clientCache.sessionCookieEnabled()) {
                var cookieData = clientCache.getSessionCookieData();
                if (cookieData != null) {
                    if (typeof (clientCache.recoverSessionCallback) === 'function') {

                        clientCache.recoverSessionCallback(cookieData.sessionID, cookieData.deviceName, function () {
                            loadView(router, route, routeParameterNames, routeArguments);
                        });
                    }
                } else {
                    navigateToLoginWithReturnUrl(router);
                }

            } else {
                navigateToLoginWithReturnUrl(router);
            }
        };

        var navigateToLoginWithReturnUrl = function (router) {
            var requestedPath = window.location.hash;

            var loginRoute = routingManager.findRouteByName('login');
            //Set current route
            currentRoute = loginRoute;
            router.navigate(String.format('#{0}?{1}={2}', loginRoute.routePath, returnUrlParameterName, requestedPath));
        };

        var getRouteParameters = function (route, routeParameterNames, routeFunctionArguments) {

            var parameterNames = routeParameterNames;

            if (parameterNames == null) {
                parameterNames = getRouteParameterNames(route.routePath);
            }

            var routeParameters = {};
            for (var paramIndex = 0; paramIndex < parameterNames.length; paramIndex++) {
                var paramName = parameterNames[paramIndex];
                routeParameters[paramName] = routeFunctionArguments[paramIndex];
            }

            return routeParameters;

        };

        var loadView = function (router, route, routeParameterNames, routeArguments) {

            //Check if the view should be opened in a new browsing context (such as a window or a tab)
            if (browsingContextHelper.isNewBrowsingContext(route)) {

                var continueViewLoading = browsingContextHelper.prepareForNewBrowsingContext(router, route);
                if (!continueViewLoading) { return; }

            }

            //Establish require function to use.
            var requireFunction = util.getRequireJSFunction();
            if (util.isDefined(route.module) && route.module.indexOf('modularis') === 0) {
                requireFunction = require;
            }

            if (typeof (route.templatePath) !== 'undefined') {
                templateLoader.loadTemplate(route.templatePath, function (data, error) {

                    if (error != null) {
                        //console.log(error);
                        return;
                    }

                    var kendoViewOptions;
                    var templateId = data.templateId;

                    var additionalOptions = {
                        hide: layoutManager._handleViewHiding
                    };

                    if (typeof (route.module) !== 'undefined') {
                        requireFunction([route.module], function (viewModelModule) {

                            var parameters = getRouteParameters(route, routeParameterNames, routeArguments);
                            var initializeOptions = {
                                routeParameters: parameters
                            };

                            //Change current route
                            currentRoute = route;

                            if (!viewModelModule) {
                                logger.error('The module "' + route.module + '" does not return any value.');
                                return;
                            }

                            if (!util.isDefined(viewModelModule.initializeForView)) {
                                logger.error('The module "' + route.module + '" does not expose an initializeForView function');
                                return;
                            }

                            viewModelModule.initializeForView(function (viewModel /*, viewModelCallbackError*/) {
                                additionalOptions.model = viewModel;
                                kendoViewOptions = webUtil.addViewSettings(route.templatePath, templateId, additionalOptions);
                                viewModel._logOffEventHandlerID = webUtil.bindToLogOffEvent(viewModel);
                                viewModel._unauthorizedEventHandlerID = webUtil.bindToUnauthorizedEvent(viewModel);
                                var kendoView = new kendo.View(templateId, kendoViewOptions);
                                displayView(kendoView, route, data, viewModel);
                            }, initializeOptions);

                        });
                    } else {
                        kendoViewOptions = webUtil.addViewSettings(route.templatePath, templateId, additionalOptions);
                        var view = new kendo.View(templateId, kendoViewOptions);
                        //Change current route
                        currentRoute = route;
                        displayView(view, route, data);
                    }

                });
            } else {
                if (typeof (route.module) !== 'undefined') {
                    requireFunction([route.module], function (viewModelModule) {

                        var parameters = getRouteParameters(route, routeParameterNames, routeArguments);
                        var handleRouteOptions = {
                            routeParameters: parameters
                        };

                        //Change current route
                        currentRoute = route;

                        viewModelModule.handleRoute(handleRouteOptions);

                    });
                } else {
                    //console.log('A route must define a module, a templatePath or both.');
                }
            }

        };

        var displayView = function (kendoView, route, data, viewModel) {

            //Initialize layout in case it hasn't been loaded yet.
            var layout = configLoader.appConfig.layout.defaultAuthenticatedUserLayoutPath;
            var transitionClass = route.transitionClass;
            if (browsingContextHelper.isNewBrowsingContext(route)) {

                var newBrowsingContextLayout = route.newBrowsingContextLayout;
                if (!newBrowsingContextLayout) {
                    newBrowsingContextLayout = configLoader.appConfig.layout.defaultNewBrowsingContextLayoutPath;
                }

                if (newBrowsingContextLayout) { layout = newBrowsingContextLayout; }
            }

            if (isAuthorizationRequired(route) && !layoutManager.isLayoutDisplayed(layout)) {
                layoutManager.initializeLayout(layout, function () {
                    layoutManager.showInLayout(kendoView, transitionClass, null, function () {
                        webUtil.callAfterViewModelIsDisplayed(viewModel);
                    });
                });
            } else {
                layoutManager.showInLayout(kendoView, transitionClass, null, function () {
                    webUtil.callAfterViewModelIsDisplayed(viewModel);
                });
            }

            //Change page title if needed
            if (data.title) {
                $('head title').text(data.title);
            }
        };

        var getRouteParameterNames = function (routePath) {

            var boundParameters = [];
            //Split the route path using the special characters * and :. --- * --> route globbing, : --> bound parameter
            var tokens = routePath.split(/[*:]+/);

            //Remove the first element in the array. It will contain 'garbage'
            tokens.shift();

            for (var index = 0; index < tokens.length; index++) {
                var routeToken = tokens[index];

                //If the token contains a slash, we can savely remove what it is after it.
                if (routeToken.indexOf('/') >= 0) {
                    routeToken = routeToken.substr(0, routeToken.indexOf('/'));
                }

                //Remove open paren and close paren chars. They are used for optional params.
                routeToken = routeToken.replace(/[\(\)]+/, '');
                boundParameters.push(routeToken);
            }

            return boundParameters;

        };

        var routingManager = {

            _routes: null,
            router: null,
            _validateSessionHandler: null,

            init: function (routes, validateSessionHandler) {
                var that = this;
                that.router = new kendo.Router();
                if (routes == null) {
                    return;
                }

                that._validateSessionHandler = validateSessionHandler;

                //Load routes
                for (var index = 0; index < routes.length; index++) {
                    var route = routes[index];
                    if (typeof (route.routePath) !== 'undefined') {
                        //The route path cannot start or end with ':' or '*'
                        if (route.routePath.startsWith(':') || route.routePath.startsWith('*') || route.routePath.endsWith(':') || route.routePath.endsWith('*')) {
                            logger.error('A route path cannot start or end with ":" or "*": ' + route.routePath);
                        } else {
                            handleRoute(that.router, route);
                        }
                    }
                }

                //Add a routeMissing event handler
                that.router.bind('routeMissing', function (event) {
                    logger.info('Route missing: ', event.url);
                });
                that._routes = routes;
                that.router.start();
            },

            findRouteByName: function (name) {
                var result = null;
                for (var index = 0; (index < this._routes.length) && (!result); index++) {
                    var route = this._routes[index];
                    if (route.name === name) { result = route; }
                }
                return result;
            },

            getCurrentRoute: function () {
                return currentRoute;
            },

            getReturnUrl: function () {
                return this.getQueryStringParameter(returnUrlParameterName);
            },

            getQueryStringParameter: function (parameterName) {
                var queryString = window.location.hash.split('?');

                if (queryString.length <= 1) {
                    return '';
                }

                var params = queryString[1].split('&');
                for (var index = 0; index < params.length; index++) {
                    var pair = params[index].split('=');
                    if (decodeURIComponent(pair[0]) === parameterName) {
                        return decodeURIComponent(pair[1]);
                    }
                }

                return '';
            }

        };

        return routingManager;
    }
);
