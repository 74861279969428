define('modularis/web/widgets/modularisModuleMenu',
   ['kendo', 'metadataCache', 'util'],
   function (kendo, metadataCache, util) {
       'use strict';
       var ui = kendo.ui, Widget = ui.Widget;

       var horizontal = 'horizontal',
           vertical = 'vertical';

       var forwardSlashesRegex = /[\/]/g;
       var iconFormat = '<span class="icon-{0} m-menu-icon"></span>';
       var defaultIconClassFormat = '{0}-{1}';

       var ModularisModuleMenu = Widget.extend({

           init: function (element, options) {
               var that = this;
               Widget.fn.init.call(that, element, options);

               //Load modules groups and modules
               metadataCache.getModuleGroups(function (moduleGroups, moduleGroupError) {

                   if (util.success(moduleGroups, moduleGroupError)) {

                       var adjustedModuleGroups = [];

                       var currentGroup, currentModule;

                       var moduleGroupsItems = moduleGroups.Items;

                       for (var groupIndex in moduleGroupsItems) {

                           if (moduleGroupsItems.hasOwnProperty(groupIndex)) {

                               currentGroup = moduleGroupsItems[groupIndex];

                               //the default module is used as a container for modules without a parent.
                               var addModuleGroup = currentGroup.Name.toLowerCase() !== 'default' && currentGroup.NavDisplayOrder !== -1;

                               var adjustedGroup = {
                                   text: currentGroup.Caption
                               };

                               if (currentGroup.UIModules) {
                                   adjustedGroup.items = [];
                                   var modules = currentGroup.UIModules.Items;
                                   var moduleUrl;

                                   //The following flag defines if the module will be shown under a parent item.
                                   var willHaveParent = addModuleGroup;

                                   for (var moduleIndex in modules) {

                                       if (modules.hasOwnProperty(moduleIndex)) {

                                           currentModule = modules[moduleIndex];

                                           //Convention to define the module URL with the value of the Name property in case it starts with forward slash.
                                           if (currentModule.Name.startsWith('/')) {
                                               moduleUrl = '#' + currentModule.Name;
                                           } else {
                                               moduleUrl = '#/' + currentModule.Caption.toLowerCase().replace(' ', '-');
                                           }

                                           var iconClass = '';
                                           if (currentModule.Icon) {
                                               iconClass = window.atob(currentModule.Icon);
                                           } else {
                                               var iconClassGroupNamePart = currentGroup.Name.toLowerCase();
                                               var iconClassModuleNamePart = currentModule.Name.replace(forwardSlashesRegex, '');
                                               iconClass = String.format(defaultIconClassFormat, iconClassGroupNamePart, iconClassModuleNamePart);
                                           }

                                           var icon = String.format(iconFormat, iconClass);

                                           var adjustedModule = {
                                               encoded: false,
                                               text: icon + currentModule.Caption,
                                               url: moduleUrl
                                           };

                                           if (willHaveParent) {
                                               adjustedGroup.items.push(adjustedModule);
                                           } else {
                                               adjustedModuleGroups.push(adjustedModule);
                                           }
                                       }

                                   }
                               }

                               if (addModuleGroup) {
                                   adjustedModuleGroups.push(adjustedGroup);
                               }
                           }

                       }

                       if (that.options.orientation === horizontal) {
                           that.element.kendoMenu({
                               dataSource: adjustedModuleGroups
                           });

                       } else {
                           that.element.kendoPanelBar({
                               dataSource: adjustedModuleGroups
                           });
                       }

                       //In some scenearios it's necessary to force a resize event so that the menu is rendered properly.
                       window.dispatchEvent(new Event('resize'));
                   }

               });
           },

           options: {
               name: 'ModularisModuleMenu',
               orientation: vertical
           }

       });

       return {
           widgetClass: ModularisModuleMenu
       };

   }
);
