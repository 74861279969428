define('modularisGeneral/serviceBase',
    ['jquery', 'RESTActivityWebServiceProxy', 'RESTSecurityWebServiceProxy', 'RESTCommandWebServiceProxy',
       'RESTNotificationWebServiceProxy', 'RESTLogicalLockWebServiceProxy', 'modularisBase/debug', 'util', 'enums'],
    function ($, activityServiceProxy, securityServiceProxy, commandServiceProxy,
       notificationServiceProxy, logicalLockServiceProxy, debug, modularisUtil, enums) {
        'use strict';

        var fullEventHandlerIDFormat = '{0}.{1}';

        var ServiceBase = function () {
            var that = this;

            that.activityServiceProxy = activityServiceProxy;
            that.securityServiceProxy = securityServiceProxy;
            that.commandServiceProxy = commandServiceProxy;
            that.notificationServiceProxy = notificationServiceProxy;
            that.logicalLockServiceProxy = logicalLockServiceProxy;

            //Initialize logOff event handler if available

            if (typeof (that.onLogOff) === 'function') {
                that.securityServiceProxy.bind(enums.securityEventName.logOff, that.onLogOff);
            }
        };

        ServiceBase.prototype.bind = function (eventName, eventHandler) {
            var eventHandlerID = modularisUtil.newGuid();
            $(document).on(String.format(fullEventHandlerIDFormat, eventName, eventHandlerID), eventHandler);
            if (debug.isEnabled()) { debug.registerEventHandler(eventName); }
            return eventHandlerID;
        };

        ServiceBase.prototype.unbind = function (eventName, eventHandlerID) {
            if (eventHandlerID == null) {
                //In this case, all event handlers for the given event will be removed.
                $(document).off(eventName);
            } else {
                $(document).off(String.format(fullEventHandlerIDFormat, eventName, eventHandlerID));
            }
            if (debug.isEnabled()) { debug.unregisterEventHandler(eventName); }
        };

        ServiceBase.prototype.trigger = function (eventName, eventData) {
            $.event.trigger({
                type: eventName,
                serviceEventData: eventData
            });
        };

        return {
            ServiceBase: ServiceBase
        };

    }
);
