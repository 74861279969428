define('objectBuilder',
    ['jquery', 'entityHelper', 'configLoader', 'util', 'enums'],
    function ($, entityHelper, configLoader, util, enums) {
        'use strict';

        var createEntityDefaultOptions = {
            entityTypeName: '',
            copyFrom: null,
            assignIdentifier: false,
            callback: null
        };

        var createCollectionDefaultOptions = {
            assemblyName: '',
            entityTypeName: '',
            entityCollectionName: '',
            copyFrom: null,
            callback: null,
            checkDuplicatedKeys: false
        };

        var assignIdentifier = function (entityObject, options) {
            /*eslint-disable no-param-reassign*/

            //Assign a identifier based on the entity key structure.
            if (options.assignIdentifier) {
                var keyStructure = entityObject.getEntityMetadata().getKeyStructure();
                var property = entityObject.getEntityMetadata().getPropertyDef(keyStructure);
                if (property != null && property.type === enums.propertyTypes.typeGuid) {
                    entityObject[keyStructure] = util.newGuid();
                }
            }

            /*eslint-enable no-param-reassign*/
        };

        /* Helper methods for entity names manipulation*/
        var getEntityName = function (entityTypeName) {
            var result = entityTypeName.substr(entityTypeName.lastIndexOf('.') + 1);
            return result;
        };

        /*
        Takes a string, tokenize it and normalize each of those tokens, according to the following standard:
        DepartmentManager --> departmentManager, Person --> person, WIP --> wip, IPAddress --> ipaddress
        */
        var normalizeTokens = function (stringWithTokens, separator) {
            var tokens = separator ? stringWithTokens.split(separator) : [stringWithTokens];
            var normalizedTokens = '';

            for (var tokensIndex = 0; tokensIndex < tokens.length; tokensIndex++) {
                var token = tokens[tokensIndex];

                //Normalize token
                var normalizedToken = '';
                var upperCaseNormalized = false;
                for (var index = 0; index < token.length; index++) {
                    var character = token.charAt(index);
                    if (!upperCaseNormalized) {
                        //Following validation checks for upper case and avoids numeric characters
                        if (character === character.toUpperCase() && character !== character.toLowerCase()) {
                            normalizedToken += character.toLowerCase();
                        } else {
                            normalizedToken += character;
                            upperCaseNormalized = true;
                        }
                    } else {
                        normalizedToken += character;
                    }
                }

                normalizedTokens += normalizedToken;
                if (tokensIndex !== (tokens.length - 1)) {
                    normalizedTokens += separator;
                }
            }

            return normalizedTokens;
        };

        var objectBuilder = {

            getAssemblyName: function (entityTypeName, callback) {
                var result = '';

                var systemName = configLoader.appConfig.systemName;
                if (entityTypeName.startsWith(systemName)) {
                    var format = 'app/{0}/common';
                    var remaining = entityTypeName.replace(systemName + '.', '').replace('Entity.', '');
                    var commonModuleName = String.format(format, configLoader.appConfig.entity.folderName);
                    if (remaining.indexOf('.') >= 0) {
                        var modelName = entityHelper.getModelByEntityTypeName(entityTypeName);
                        if (modelName) {
                            //Remove system name from model name
                            modelName = normalizeTokens(modelName.substr(systemName.length + 1));
                            if (modelName) {
                                commonModuleName = String.format(format, configLoader.appConfig.entity.folderName + '/' + modelName);
                            }
                        }
                    }

                    var requireFunction = util.getRequireJSFunction();
                    requireFunction([commonModuleName], function (commonModule) {
                        util.notify(callback, commonModule.AssemblyName);
                    });
                } else {
                    util.notify(callback, null);

                }

                return result;
            },

            getNormalizedEntityTypeName: function (entityTypeName) {

                //1. Remove system name and "Entity." from namespace
                var normalizedEntityTypeName = entityTypeName.replace(configLoader.appConfig.systemName + '.', '').replace('Entity.', '');

                //2. Create normalized entity type name
                normalizedEntityTypeName = normalizeTokens(normalizedEntityTypeName, '.');

                //3. Replace dots with slashes
                normalizedEntityTypeName = normalizedEntityTypeName.replace(/\./g, '/');

                return normalizedEntityTypeName;
            },

            getEntityModulePath: function (entityTypeName) {

                if (util.isDynamicEntity(entityTypeName)) {
                    return 'modularis/entity/dynamic/dynamicEntity';
                }

                var normalizedEntityTypeName = this.getNormalizedEntityTypeName(entityTypeName);
                var entityModulePath = configLoader.appConfig.entity.folderName + '/' + normalizedEntityTypeName;
                if (entityTypeName.indexOf(configLoader.appConfig.systemName + '.') === 0) {
                    entityModulePath = 'app/' + entityModulePath;
                }
                return entityModulePath;
            },

            createEntityCollection: function (createCollectionOptions) {
                var options = {};
                $.extend(options, createCollectionDefaultOptions, createCollectionOptions);

                var deferred = $.Deferred();
                var promise = deferred.promise();

                var collectionName = options.entityCollectionName;
                var isDynamicEntity = util.isDynamicEntity(collectionName);

                if ((!collectionName || collectionName.length === 0) && options.copyFrom) {
                    collectionName = options.copyFrom._EntityCollection.CollectionName;

                    if (collectionName == null) {
                        isDynamicEntity = util.isDynamicEntity(options.copyFrom.EntityDefID);
                    }
                }

                var entityTypeName = options.entityTypeName;
                if ((!entityTypeName || entityTypeName.length === 0) && collectionName) {
                    entityTypeName = entityHelper.getEntityTypeByCollectionName(collectionName);
                }

                if (!entityTypeName) {
                    deferred.resolve(options.copyFrom);
                    util.notify(options.callback, options.copyFrom);
                    return promise;
                }

                var requireFunction = util.getRequireJSFunction();

                if (isDynamicEntity) {
                    requireFunction = require;
                    collectionName = 'Modularis.Dynamic.Entity.DynamicEntities';
                    entityTypeName = 'Modularis.Dynamic.Entity.DynamicEntity';
                }

                var entityModulePath = this.getEntityModulePath(entityTypeName);

                var loadCollectionModule = function () {
                    //Load module where collection is contained.
                    requireFunction([entityModulePath], function (entityModule) {

                        var entityCollection = new entityModule[collectionName](options.assemblyName, collectionName, entityTypeName);

                        entityCollection.checkDuplicatedKeys = options.checkDuplicatedKeys;

                        if (options.copyFrom) {
                            entityCollection.copyFrom(options.copyFrom, function (updatedCollection, copyCollectionError) {

                                var responseObject = null;
                                var responseError = copyCollectionError;
                                if (util.success(updatedCollection, copyCollectionError)) {
                                    deferred.resolve(updatedCollection);
                                    responseObject = updatedCollection;
                                } else {
                                    deferred.reject(responseError);
                                }

                                util.notify(options.callback, responseObject, responseError);

                            });
                        } else {
                            deferred.resolve(entityCollection);
                            util.notify(options.callback, entityCollection, null);
                        }

                    });
                };

                if (!options.assemblyName || options.assemblyName.length === 0) {
                    //Try to resolve assembly name based on the entityTypeName
                    this.getAssemblyName(entityTypeName, function (assemblyName) {
                        options.assemblyName = assemblyName;
                        loadCollectionModule();
                    });
                } else {
                    loadCollectionModule();
                }

                return promise;
            },

            createEntityObject: function (createEntityOptions) {

                var options = {};
                $.extend(options, createEntityDefaultOptions, createEntityOptions);

                var entityTypeName = options.entityTypeName;
                var isDynamicEntity = util.isDynamicEntity(entityTypeName);

                if ((!entityTypeName || entityTypeName.length === 0) && options.copyFrom) {
                    entityTypeName = entityHelper.getEntityTypeByEntityName(options.copyFrom._Entity.Name);

                    if (entityTypeName == null) {
                        isDynamicEntity = util.isDynamicEntity(options.copyFrom.EntityDefID);
                    }
                }

                var requireFunction = util.getRequireJSFunction();

                if (isDynamicEntity) {
                    requireFunction = require;
                    entityTypeName = 'Modularis.Dynamic.Entity.DynamicEntity';
                }

                var entityModulePath = this.getEntityModulePath(entityTypeName);

                var deferred = $.Deferred();
                var promise = deferred.promise();

                requireFunction([entityModulePath], function (entityModule) {
                    var entityName = getEntityName(entityTypeName);
                    var entityObject = new entityModule[entityName]();
                    if (options.copyFrom != null) {
                        entityObject.copyFrom(options.copyFrom, function (updatedEntityObject, copyEntityError) {

                            var responseObject = null;
                            var responseError = copyEntityError;
                            if (util.success(updatedEntityObject, copyEntityError)) {

                                assignIdentifier(updatedEntityObject, options);
                                deferred.resolve(updatedEntityObject);
                                responseObject = updatedEntityObject;
                            } else {
                                deferred.reject(copyEntityError);
                            }

                            util.notify(options.callback, responseObject, responseError);

                        });
                    } else {
                        if (util.isDynamicEntity(createEntityOptions.entityTypeName)) {
                            entityHelper.initializeDynamicEntity(entityObject, createEntityOptions);
                        }

                        assignIdentifier(entityObject, options);
                        deferred.resolve(entityObject);
                        util.notify(options.callback, entityObject, null);
                    }

                });

                return promise;
            }
        };

        return objectBuilder;
    }
);
